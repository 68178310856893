<template>
  <div>
    <div class="header-container">
      <div class="header-content">
        <h1>{{ headerTitle }}</h1>
        <div v-if="permissions === 1" :key="permissions">
          <Button
            :label="buttonLabel"
            @click="handleButtonClick"
            icon="las la-plus"
          />
        </div>
      </div>
    </div>
    <JobsDetail></JobsDetail>
  </div>
</template>

<script>
import JobsDetail from "./JobsDetail.vue";

export default {
  components: { JobsDetail },

  computed: {
    showTemplates() {
      return this.$store.getters.showTemplates;
    },
    headerTitle() {
      return this.showTemplates ? "Job Templates" : "Jobs";
    },
    buttonLabel() {
      return this.showTemplates ? "New Template" : "New Job";
    },
    permissions() {
      return this.$store.getters.permissions;
    },
  },

  methods: {
    handleButtonClick() {
      this.$store.dispatch("setSelectedObject", null);
      this.$store.dispatch(
        "setSliderView",
        this.showTemplates ? "JobTemplateSlider" : "JobSlider"
      );
    },
  },
};
</script>

<style scoped>
.header-container {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-content {
  padding: 0.75rem;
  margin-right: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

h1 {
  width: 200px; /* This can be adjusted or removed depending on the design requirements */
}
</style>

<template>
  <div class="inline-flex p-2 align-items-center mb-3" style="z-index: 3">
    <!-- <div
      :class="
        'mr-4 p-1 ' +
        (filters.indexOf('invoiced') >= 0
          ? 'bottom-border-warning'
          : 'bottom-border-invisible')
      "
      @click="filter('invoiced')"
    >
      <p class="font-light cursor-pointer">
        Invoiced<Badge
          style="background-color: #ffab2b"
          class="ml-1"
          :value="invoiced"
        />
      </p>
    </div> -->
    <div
      :class="
        'mr-4 p-1  ' +
        (filters.indexOf('is_complete') >= 0
          ? 'bottom-border-grey'
          : 'bottom-border-invisible')
      "
      @click="filter('is_complete')"
    >
      <p class="font-light cursor-pointer">
        Completed
        <Badge
          style="background-color: #e8ecef"
          class="ml-1"
          :value="is_complete"
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    invoiced: {
      type: Number,
      default: 0,
    },
    is_complete: {
      type: Number,
      default: 0,
    },
    filters: {
      type: Array,
    },
  },
  methods: {
    filter(f) {
      this.$emit("filter", f);
    },
  },
};
</script>

<style>
</style>
<template>
  <div
    class="flex flex-column align-items-center justify-content-center"
    style="height: 100vh"
  >
    <div class="flex justify-content-center">
      <div class="w-30rem">
        <h1 style="font-size: 32px">Let’s personalise your experience…</h1>

        <form @submit="submit" @keyup.enter="submit" style="margin-top: 2rem">
          <div class="field">
            <label for="companyName" class="label">Company Name</label>
            <InputText
              id="companyName"
              name="companyName"
              v-model="companyName"
              class="w-full"
              placeholder="Company Name"
              style="padding: 1rem"
            />
          </div>

          <div class="field">
            <label for="industry" class="label">Industry</label>
            <InputText
              id="industry"
              name="industry"
              v-model="industry"
              class="w-full"
              placeholder="Industry"
              style="padding: 1rem"
            />
          </div>
          <div class="field">
            <label for="currency" class="label">Currency</label>
            <Dropdown
              id="currency"
              name="currency"
              v-model="currency"
              class="w-full"
              placeholder="Currency"
              style="padding: 0.25rem"
            />
          </div>
          <div class="field">
            <label for="taxRate" class="label">Tax Rate (%)</label>
            <InputText
              id="taxRate"
              name="taxRate"
              v-model="taxRate"
              class="w-full"
              placeholder="Tax Rate (%)"
              style="padding: 1rem"
            />
          </div>
          <div style="margin-top: 2rem">
            <Button
              type="submit"
              class="button is-primary"
              label="Next"
              style="width: 125px"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      companyName: "",
      industry: "",
      currency: null,
      taxRate: "",
    };
  },
  metaInfo: {
    title: "Welcome",
  },
  methods: {
    submit() {
      this.$router.push("/onboard/team");
    },
  },
};
</script>

<style>
</style>
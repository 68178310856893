<template>
  <div>
    <!-- <div
      class="flex flex-wrap sm:justify-content-end justify-content-center align-items-center pb-2"
    >
      <div>
        <Button
          label="New Tag"
          class="p-button-text p-button-secondary"
          icon="las la-plus"
          @click="
            tag = null;
            display = true;
          "
        />
      </div>
      <div>
        <FilterSearchButton v-model="filters['global'].value" />
      </div>
    </div> -->
    <div>
      <DataTable
        responsiveLayout="scroll"
        :rowClass="rowClass"
        :value="taskTags"
        :filters.sync="filters"
        dataKey="uid"
      >
        <template #empty> Nothing yet... </template>
        <Column
          field="name"
          header="Global Tags"
          headerClass="w-7"
          filterField="name"
        >
          <template #body="slotProps">
            <Skeleton
              style="padding: '5px 10px'"
              width="4rem"
              height="1.5rem"
              borderRadius="16px"
              v-if="isLoading"
            ></Skeleton>
            <template v-else>
              <span
                class="cursor-pointer"
                :style="{
                  backgroundColor: `#${slotProps.data.colour}`,
                  color: '#FFFFFF',
                  padding: '5px 10px',
                  borderRadius: '500px',
                }"
                @click="$store.dispatch('setSelectedObject', slotProps.data)"
              >
                {{ slotProps.data.name }}
              </span>
            </template>
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by name"
            />
          </template>
          <template #editor="slotProps">
            <template>
              <span
                class="cursor-pointer"
                :style="{
                  backgroundColor: `#${slotProps.data.colour}`,
                  color: '#FFFFFF',
                  padding: '5px 10px',
                  borderRadius: '500px',
                }"
                @click="$store.dispatch('setSelectedObject', slotProps.data)"
              >
                {{ slotProps.data.name }}
              </span>
            </template>
          </template>
        </Column>
        <Column bodyStyle="text-align:right" :styles="{ width: '1rem' }">
          <template #body="slotProps">
            <div
              class="flex justify-content-end align-items-center invisible cursor-pointer"
            >
              <template>
                <i
                  @click="editRow(slotProps.data, taskTags)"
                  class="las la-pen i-20 mr-3 grey"
                ></i>
                <i
                  class="las la-trash i-20 cursor-pointer mr-5 grey"
                  @click.stop="
                    deletePrompt(
                      slotProps.data.name,
                      '/v1/tasktag/' + slotProps.data.uid
                    )
                  "
                ></i>
              </template>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog :visible.sync="display"
      ><GlobalTagPopup
        @dismiss="display = false"
        :tagProp="tag"
      ></GlobalTagPopup
    ></Dialog>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api";
import Dialog from "primevue/dialog";
import GlobalTagPopup from "../components/GlobalTagPopup";
import { state, fetchTaskTags } from "../../../services/data_service.js";
export default {
  components: { Dialog, GlobalTagPopup },
  data() {
    return {
      tag: {
        title: null,
        color: null,
        tagName: null,
      },
      isLoading: true,
      display: false,
      isEdit: false,

      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
  },
  watch: {
    $route(to, from) {
      if(to.query.action == "tag"){
        this.tag = null;
        this.display = true;
        this.$router.push({ query: null });
        return 
      }
      this.filters['global'].value = to.query.term
    }
  },
  methods: {
    async loadData() {
      await fetchTaskTags();
    },

    rowClass() {
      return "row-accessories";
    },
    editRow(data) {
      this.tag = data;
      this.display = true;
    },
  },
  computed: {
    taskTags() {
      return state.taskTags;
    },
  },
  async mounted() {
    this.isLoading = true;
    if (!this.taskTags) {
      state.taskTags = [{}, {}, {}, {}, {}, {}];
    }
    await this.loadData();
    this.task_type = this.obj;
    this.isLoading = false;
  },
  name: "Home",

  metaInfo: {
    title: "Settings | Global Tags",

    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: "" },
      { name: "keywords", content: "" },
    ],
  },
};
</script>


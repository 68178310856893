<template>
  <div
    class="flex align-items-center text-center justify-content-center"
    style="height: 100vh"
  >
    <div class="flex justify-content-center text-center">
      <div class="w-30rem">
        <h1 class="mb-2" style="font-size: 32px">Welcome to AgencyDesk</h1>
        <div style="margin-bottom: 5rem">
          Get started. No credit card needed.
        </div>

        <form
          style="margin-bottom: 6rem"
          @submit="submit"
          @keyup.enter="submit"
        >
          <div class="field">
            <InputText
              id="email"
              name="email"
              autocomplete="email"
              v-model="email"
              type="email"
              class="w-full"
              placeholder="name@company.com"
              style="padding: 1rem"
            />
          </div>

          <div>
            <Button
              type="submit"
              class="button is-primary w-full"
              label="Continue"
            />
          </div>
          <br />
          <div align="center">
            By proceeding, you agree to the<br />
            <a href="/">Terms of Service</a> and
            <a href="/">Privacy Policy</a>
          </div>
        </form>
        <div align="center">
          Already have an account? <a href="/login">Log in</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: "",
    };
  },
  metaInfo: {
    title: "Welcome",
  },
  methods: {
    submit() {
      this.$router.push("/onboard/information");
    },
  },
};
</script>

<style>
</style>
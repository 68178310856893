<template>
  <div class="flex flex-column">
    <LoginLogo></LoginLogo>
    <div class="flex justify-content-center text-center">
      <div class="card p-6 w-30rem" style="height: 400px">
        <h2 class="mb-5">Setup your password</h2>

        <form
          v-if="!isLoading"
          align="left"
          @submit.prevent="submitHandler"
          v-form-enter
        >
          <div class="p-fluid formgrid grid">
            <div class="field col-12 sm:col-12">
              <label class="label">Create a password</label>
              <div class="p-inputgroup">
                <Password
                  v-model="resetPassword.password1"
                  placeholder="Enter a password"
                  toggleMask
                  mediumRegex="^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
                  :feedback="true"
                >
                  <template #footer>
                    <Divider />
                    <p class="mt-2">Suggestions</p>
                    <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                      <li>At least one lowercase</li>
                      <li>At least one uppercase or number</li>
                      <li>Minimum 6 characters</li>
                    </ul>
                  </template></Password
                >
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.resetPassword.password1.required"
              >
                New password is required
              </div>
            </div>
            <div class="field col-12 sm:col-12">
              <label class="label">Confirm your password</label>
              <div class="p-inputgroup">
                <Password
                  v-model="resetPassword.password2"
                  placeholder="Re-enter your password"
                  toggleMask
                  :feedback="false"
                ></Password>
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.resetPassword.password2.required"
              >
                Confirm your new password
              </div>
            </div>
          </div>
          <br />
          <div class="flex justify-content-between pt-1">
            <div>
              <Button type="submit"> Reset </Button>
            </div>
            <Button
              class="p-button-text p-button-secondary"
              @click="
                $router.push('login');
                $store.dispatch('setSelectedObject', null);
              "
            >
              CANCEL
            </Button>
          </div>
        </form>
        <div v-else class="pt-5">
          <RocketLoad />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      submitted: false,
      isLoading: false,
      resetPassword: {
        password1: null,
        password2: null,
      },
    };
  },
  validations: {
    resetPassword: {
      password1: {
        required,
      },
      password2: {
        required,
      },
    },
  },
  methods: {
    submitHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the required fields",
          life: 3000,
        });
      } else {
        this.isLoading = true;
        this.$axios
          .post(
            process.env.VUE_APP_ROOT_API + "/auth/reset/" + this.userUid,
            JSON.stringify(this.resetPassword),
            {
              headers: { "Content-type": "application/json" },
            }
          )
          .then((response) => {
            this.isLoading = false;
            this.$toast.add({
              severity: "success",
              summary: "Your password has been reset",
              life: 3000,
            });
            this.$router.push("login");
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response && error.response.status === 401) {
              console.error("NOOOOOOOOO!");
            } else {
              // Handle error however you want
            }
            console.error(error);
          });
      }
    },
  },
  mounted() {},
  computed: {
    userUid() {
      var userUrl = window.location.href;
      var userUidFromUrl = userUrl.split("=", 3);
      return userUidFromUrl[1];
    },
  },

  metaInfo: {
    title: "Reset Password",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Reset Password Page",
      },
    ],
  },
};
</script>

<style>
</style>


<script>
import Vue from "vue";
import debounce from "lodash/debounce";
export default {
  methods: {
    showError(msg) {
      Vue.prototype.$toast.add({
        severity: "error",
        summary: "Error",
        detail: msg,
        life: 3000,
      });
    },
    debouncedError: debounce((msg) => {
      this.showError(msg);
    }, 1000),
  },
};
</script>

<style>
</style>
<template>
  <div
    v-if="job && job.is_template === 0"
    :class="
      'grid flex flex-wrap pt-3' +
      (job && job.is_template !== 0
        ? 'justify-content-start'
        : 'justify-content-between')
    "
  >
    <template v-if="$route.name === 'job--tasks'">
      <div class="col-6 xl:col-3">
        <div
          class="card mb-0 flex p-3 align-items-center h-full"
          v-if="job && job.managed_by"
        >
          <Avatar
            :image="job.managed_by.profile_picture"
            :label="
              !job.managed_by.profile_picture
                ? getIntials(job.managed_by)
                : null
            "
            :style="{
              'background-color': !job.managed_by.profile_picture
                ? getColorFromName(job.managed_by.profile_picture)
                : 'none',
              color: '#ffffff',
            }"
            size="large"
            shape="circle"
            class="mr-3"
          />

          <div class="ml-3">
            <h2 style="margin: 0px">
              {{ job.managed_by.name }}
            </h2>
            <div class="font-light">Account Executive</div>
          </div>
        </div>
      </div>
      <div class="col-6 xl:col-3">
        <div class="card mb-0 p-3">
          <div class="flex justify-content-between mb-1">
            <div>
              <span class="block text-500 font-light mb-1">Time Recon</span>
              <div class="text-900 text-xl">{{ calcRecon() }}%</div>
            </div>
            <div
              class="flex align-items-center justify-content-center border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="la la-business-time text-orange-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"
            >{{ friendlyTime(job.metrics.time.tracked) }}
          </span>
          <span class="text-500 font-light"
            >used / est. {{ friendlyTime(job.metrics.time.estimated) }}</span
          >
        </div>
      </div>
      <div class="col-6 xl:col-3">
        <div class="card mb-0 p-3">
          <div class="flex justify-content-between mb-1">
            <div>
              <span class="block text-500 font-light mb-1">Task Recon</span>
              <div class="text-900 text-xl">
                {{ job.metrics.completed }}/{{ job.metrics.tasks }}
              </div>
            </div>
            <div
              class="flex align-items-center justify-content-center border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="las la-list-alt text-blue-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"
            >{{
              job.metrics.completed > 0
                ? Math.round((job.metrics.completed / job.metrics.tasks) * 100)
                : 0
            }}%</span
          >
          <span class="text-500 font-light"> of tasks completed</span>
        </div>
      </div>
      <div class="col-6 xl:col-3" v-can="'admin'">
        <div class="card mb-0 p-3">
          <div class="flex justify-content-between mb-1">
            <div>
              <span class="block text-500 font-light mb-1">Billable</span>
              <div class="text-900 text-xl" v-if="job && job.budget">
                {{
                  formatCurrency(
                    job.budget.spend,
                    job?.client?.currency?.description
                  )
                }}
              </div>
            </div>
            <div
              class="flex align-items-center justify-content-center border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="la la-money-bill-wave text-green-500 text-xl"></i>
            </div>
          </div>

          <div class="font-light">
            <template v-if="job && job.metrics && !job.metrics.time.estimated"
              >No billable hours</template
            >
            <template v-else-if="job && job.budget">
              <span
                v-if="job.budget.amount > 0 || job.budget.estimated > 0"
                class="text-green-500 font-medium"
              >
                {{
                  job.budget.amount > 0
                    ? ((job.budget.spend / job.budget.amount) * 100).toFixed(2)
                    : ((job.budget.spend / job.budget.estimated) * 100).toFixed(
                        2
                      )
                }}
                %
              </span>
              <span v-else class="text-green-500 font-medium"> {{ 0 }}% </span>
              <span v-if="job.budget.amount > 0" class="text-500 font-light"
                >of
                {{
                  formatCurrency(
                    billableAmount,
                    job?.client?.currency?.description
                  )
                }}</span
              >
              <span v-else class="text-500 font-light"
                >of Est.
                {{
                  formatCurrency(
                    billableAmount,
                    job?.client?.currency?.description
                  )
                }}</span
              >
            </template>
          </div>
        </div>
      </div></template
    >
    <JobTimeline :job="job"></JobTimeline>
  </div>
</template>

<script>
import { state } from "../../../services/data_service.js";
import JobTimeline from "./JobTimeline.vue";
export default {
  components: {
    JobTimeline,
  },
  props: {
    job: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showTimeline: true,
      customEvents: [],
    };
  },
  methods: {
    calcRecon() {
      if (this.job.metrics.time.tracked === null) {
        return 0;
      }
      var est = this.timeToSeconds(this.job.metrics.time.estimated);

      var used = this.timeToSeconds(this.job.metrics.time.tracked);
      return Math.round((used / est) * 100);
    },
    friendlyTime(time) {
      if (!time) return "0h";
      var arr = time.split(":");
      var hour = arr[0] + ":";
      hour += arr[1];
      return hour;
    },
  },
  computed: {
    clients() {
      return state.clients;
    },
    billableAmount() {
      if (this.job.budget.amount == 0) {
        return this.job.budget.estimated;
      }
      return this.job.budget.amount;
    },
  },
  beforeDestroy() {
    state.tasksJob = null;
    state.jobCosts = null;
  },
};
</script>

<style>
.custom-marker {
  border-radius: 50%;
  border-color: rgb(77, 124, 254);
  border-width: 2px;
  border-style: solid;
  width: 25px;
  height: 24px;
  padding-top: 2px;
  text-align: center;
}

.connect-50-size {
  width: 24px !important;
}
</style>
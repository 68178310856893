import store from "@/store";
import Home from "./../views/Home.vue";
import Jobs from "./../application/Job/views/Jobs";
import Job from "./../application/Job/views/Job";
import JobCostings from "./../application/JobCosting/views/JobCostings.vue";
import JobTasks from "./../application/Job/components/JobTasks";
import JobMembers from "./../application/Job/components/JobMembers";
import JobMeetings from "./../application/Job/components/JobMeetings";
import Welcome from "./../application/Onboard/views/Welcome";
import Industry from "./../application/Onboard/views/Industry";
import Information from "./../application/Onboard/views/Information";
import Team from "./../application/Onboard/views/Team";
import Complete from "./../application/Onboard/views/Complete";
import OnboardTasks from "./../application/Onboard/components/Tasks";
import Settings from "./../application/Settings/views/Settings";
import SettingsDetails from "./../application/Settings/views/SettingsDetails";
import SettingsTaskTypes from "./../application/Settings/views/SettingsTaskTypes";
import SettingsFinance from "./../application/Settings/views/SettingsFinance";
import SettingsBillingTier from "./../application/Settings/views/SettingsBillingTier";
import SettingsAccountStructure from "./../application/Settings/views/SettingsAccountStructure";
import SettingsCategory from "./../application/Settings/views/SettingsCategory";
import SettingsJobTemplates from "./../application/Settings/views/JobTemplates";
import SettingsEstimateTemplates from "./../application/Settings/views/EstimateTemplates";
import SettingsUsers from "./../application/Settings/views/SettingsUsers";
import SettingsStructure from "./../application/Settings/views/SettingsStructure";
import SettingsRoles from "./../application/Settings/views/SettingsRoles";
import SettingsPermissions from "./../application/Settings/views/SettingsPermissions";
import SettingsCosts from "./../application/Settings/views/SettingsCosts";
import SettingsTemplates from "./../application/Settings/views/SettingsTemplates";
import SettingsRecurring from "./../application/Settings/views/SettingsRecurring";
import SettingsGeneral from "./../application/Settings/views/SettingsGeneral";
import SettingsGlobalTags from "./../application/Settings/views/SettingsGlobalTags";
import Tasks from "./../application/Tasks/views/Tasks";
import Desk from "./../application/Desk/views/Desk";
import Login from "./../application/Auth/views/Login";
import ResetPassword from "./../application/Auth/views/ResetPassword";
import ForgotPassword from "./../application/Auth/views/ForgotPassword";
import Theme from "./../application/Theme/Theme";
import Calendar from "./../application/Calendar/Calendar";
import Finance from "./../application/Finance/views/Finance";
import Estimates from "./../application/Finance/views/Estimates";
import Invoices from "./../application/Finance/views/Invoices";
import PurchaseOrders from "./../application/Finance/views/PurchaseOrders";
import QuoteRequests from "./../application/Finance/views/QuoteRequests";
import Estimate from "./../application/Finance/views/Estimate";
import Costs from "./../application/Finance/views/Costs";
import { state } from "./../services/data_service.js";
import TaskSchedule from "./../application/TaskSchedule/views/TaskSchedule";
import Suppliers from "./../application/Supplier/views/Suppliers.vue";
import Supplier from "./../application/Supplier/views/Supplier.vue";
import SupplierDetails from "./../application/Supplier/views/SupplierDetails.vue";
import Clients from "./../application/Client/views/Clients.vue";
import Client from "./../application/Client/views/Client.vue";
import ClientDetails from "./../application/Client/views/ClientDetails.vue";
import SwitchAccount from "./../application/Auth/views/SwitchAccount.vue";

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/LayoutApp.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
        redirect: '/jobs/active'
      },
      {
        path: "task-schedule",
        name: "Task Schedule",
        component: TaskSchedule,
      },
      {
        path: "calendar",
        name: "Calendar",
        component: Calendar,
      },
      {
        path: "finance",
        name: "Finance",
        component: Finance,
        children: [
          {
            path: "estimate",
            redirect: to => {
              return { path: '/finance/estimates' }
            },
          },
          {
            path: "estimate/:uid",
            alias: "estimate",
            name: "finance--estimate",
            component: Estimate,
            props: true,
          },
          {
            path: "estimates",
            name: "finance--estimates",
            component: Estimates,
          },
          {
            path: "invoices",
            name: "finance--invoices",
            component: Invoices,
          },
          {
            path: "purchase-orders",
            name: "finance--purchase-orders",
            component: PurchaseOrders,
          },
          {
            path: "quote-requests",
            name: "finance--quote-requests",
            component: QuoteRequests,
          },
        ],
      },
      {
        path: "finance/estimates",
        name: "Estimate",
        component: Estimates,
      },
      {
        path: "desk",
        name: "Desk",
        component: Desk,
      },
      {
        path: "jobs",
        name: "Jobs",
        redirect: to => {
          return { path: '/jobs/active' }
        },
        component: Jobs,
        props: {
          metaTitle: "Jobs",
          metaDescription: "Jobs and things",
        },
        children: [
          {
            path: "active",
            alias: "active-jobs",
            name: "jobs--active",
            component: Jobs,
          },
          {
            path: "planned",
            alias: "planned-jobs",
            name: "jobs--planned",
            component: Jobs,
          }
          ,
          {
            path: "closed",
            alias: "closed-jobs",
            name: "jobs--closed",
            component: Jobs,
          }
        ]
      },
      {
        path: "costing/:uid",
        name: "Cost",
        component: Job
      },
      {
        path: "job/:uid",
        name: 'job',
        component: Job,
        children: [
          {
            path: "tasks",
            name: "job--tasks",
          },
          {
            path: "costs",
            name: "job--costs",
          },
          {
            path: "costing",
            name: "job--costing",
          },
          {
            path: "estimates",
            name: "job--estimates",
          },
          {
            path: "estimates/:estimate_uid",
            name: "job--estimate",
          },
          {
            path: "financial",
            name: "job--financials",
          },
          {
            path: "financial/:invoice_uid",
            name: "job--financial",
          },
          {
            path: "documents",
            name: "job--documents",
          }
        ],
      },
      {
        path: "settings",
        component: Settings,
        children: [
          {
            path: "",
            component: SettingsGeneral,
            children: [
              {
                path: "",
                alias: "details",
                name: "settings--details",
                component: SettingsDetails,
              },
              {
                path: "finance",
                name: "settings--finance",
                component: SettingsFinance,
              },
              {
                path: "billing_tier",
                name: "settings--billing_tier",
                component: SettingsBillingTier,
              },
              {
                path: "account_structure",
                name: "settings--account_structure",
                component: SettingsAccountStructure,
              },
              {
                path: "global_tags",
                name: "settings--global_tags",
                component: SettingsGlobalTags,
              },
              {
                path: "category",
                name: "settings--category",
                component: SettingsCategory,
              },
            ],
          },
          {
            path: "users",
            name: "settings--users",
            component: SettingsUsers,
          },
          {
            path: "structure",
            name: "settings--structure",
            component: SettingsStructure,
          },
          {
            path: "tasks",
            name: "settings--tasks",
            component: SettingsTaskTypes,
          },
          {
            path: "costs",
            name: "settings--costs",
            component: SettingsCosts,
          },
          {
            path: "templates",
            component: SettingsTemplates,
            children: [
              {
                path: "",
                name: "job--templates",
                component: SettingsJobTemplates,
              },
              {
                path: "estimate_templates",
                name: "estimate--templates",
                component: SettingsEstimateTemplates,
              },
            ],
          },
          {
            path: "notifications",
            name: "settings--notifications",
            component: SettingsDetails,
          },
          {
            path: "recurring",
            name: "settings--recurring",
            component: SettingsRecurring,
          },
          {
            path: "roles",
            name: "settings--roles",
            component: SettingsRoles,
          },
          {
            path: "permissions",
            name: "settings--permissoins",
            component: SettingsPermissions,
          },
        ],
      },
      {
        path: "suppliers",
        name: "suppliers",
        component: Suppliers,
      },
      {
        path: "supplier",
        component: Supplier,
        children: [
          {
            path: ":uid",
            alias: "details",
            name: "supplier--details",
            component: SupplierDetails,
          },
          {
            path: "",
            alias: "details",
            name: "supplier--new",
            component: SupplierDetails,
          },
        ],
      },
      {
        path: "costings",
        name: "Costings",
        component: JobCostings,
      },
      {
        path: "clients",
        component: Clients,
      },
      {
        path: "client",
        component: Client,
        children: [
          {
            path: ":uid",
            name: "client--details",
            component: ClientDetails,
          },
          {
            path: "",
            alias: "details",
            name: "client--new",
            component: ClientDetails,
          },
        ],
      },
    ],
  },
  {
    path: '/onboard',
    component: () => import("@/layouts/LayoutOnboard.vue"),
    children: [
      {
        // JobTasks will be rendered inside Job's <router-view>
        // when /job/:uid/tasks is matched
        path: "",
        alias: "welcome",
        name: "onboard--welcome",
        component: Welcome,
      },
      {
        path: "information",
        name: "onboard--information",
        component: Information,
      },
      {
        path: "industry",
        name: "onboard--industry",
        component: Industry,
      },
      {
        path: "team",
        name: "onboard--team",
        component: Team,
      },
      {
        path: "complete",
        name: "onboard--complete",
        component: Complete,
      },
    ],
  },
  
  {
    path:'/switch-account/:uid',
    name: 'switch-account',
    component: SwitchAccount
  },
  {
    path: "/",
    component: () => import("@/layouts/LayoutLogin.vue"),
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
        beforeEnter(to, from, next) {
          if (store.getters.isAuthenticated) {
            next("/jobs");
          } else {
            next();
          }
        },
      },
      {
        path: "resetPassword",
        name: "reset--password",
        component: ResetPassword,
      },
      {
        path: "forgot-password",
        name: "forgot-password",
        component: ForgotPassword,
      },
    ],
  },

];

// Always leave this as last one
if (process.env.MODE !== "ssr") {
  routes.push({
    path: "*",
    component: () => import("./../application/Error404.vue"),
  });
}

export default routes;

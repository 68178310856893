<template>
  <div align="left">
    <div class="flex flex-row justify-content-center sm:justify-content-start">
      <div class="pb-2 pt-3 sm:pt-0">
        <h1>{{ getHeader() }}</h1>
      </div>
    </div>
    <div class="hidden sm:flex overflow-x-scroll">
      <TabView
        class="pb-2"
        @tab-click="nav"
        :activeIndex="index"
        :scrollable="false"
      >
        <TabPanel
          @click="nav(r)"
          :key="r.path"
          :header="r.name"
          v-for="r in path"
        />
      </TabView>
    </div>

    <div class="flex sm:hidden pr-2 pl-2">
      <Dropdown
        @change="navDD()"
        v-model="selectedPath"
        :options="path"
        optionLabel="name"
        class="mb-3 w-full"
      />
    </div>
    <div>
      <transition name="slide-fade">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 0,
      selectedPath: null,
      path: [
        {
          route: "/settings",
          name: "General",
        },
        {
          route: "/settings/users",
          name: "Users",
        },
        // {
        //   route: "/settings/permissions",
        //   name: "Permissions",
        // },
        {
          route: "/settings/structure",
          name: "Departments",
        },
        // {
        //   route: "/settings/roles",
        //   name: "Roles",
        // },
        {
          route: "/settings/tasks",
          name: "Tasks",
        },
        {
          route: "/settings/costs",
          name: "Costs",
        },
        {
          route: "/settings/templates",
          name: "Templates",
        },
        {
          route: "/settings/recurring",
          name: "Recurring",
        },
      ],
    };
  },

  methods: {
    getHeader() {
      return "Company Settings";
    },
    nav(ev) {
      this.$router.push(this.path[ev.index].route);
      this.selectedPath = this.path[ev.index];
    },
    navDD() {
      this.$router.push(this.selectedPath.route);
    },
  },

  computed: {},
  mounted() {
    for (let index = 0; index < this.path.length; index++) {
      const element = this.path[index];
      if (element.route === this.$route.path) {
        this.selectedPath = this.path[index];
        this.index = index;
      }
    }
  },
  metaInfo: {
    title: "Settings",
    meta: [
      { vmid: "description", name: "description", content: "Settings Page" },
    ],
  },
};
</script>
<style>
.p-tabview-panels {
  display: none !important;
}
</style>

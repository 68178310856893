<template>
  <div>
    <div
      class="w-3rem h-3rem border-circle text-white flex justify-content-center align-items-center"
      :style="'background: ' + color"
    >
      <span>{{ getIntials(user) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      Type: Object,
    },
  },
  computed: {
    color() {
      var hash = 0;
      var str =
        this.user.name +
        this.user.surname +
        this.user.username +
        this.user.email;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      var h = hash % 6;
      if (h < 0) {
        h = h * -1;
      }

      var cols = [
        "#5E00CC",
        "#00BAFF",
        "#F5D900",
        "#00B978",
        "#E0005E",
        "#ED5200",
        "#CCB244",
      ];

      return cols[h];
    },
  },
  methods: {},
};
</script>

<style>
</style>
<template>
  <div class="flex">
    <div class="col-6 p-0">
      <Industry></Industry>
    </div>
    <div class="col-6 p-0">
      <IndustryLanding></IndustryLanding>
    </div>
  </div>
</template>

<script>
import Industry from "../components/IndustryForm.vue";
import IndustryLanding from "../components/IndustryLanding.vue";
export default {
  components: { Industry, IndustryLanding },
};
</script>
<template>
  <DataTable
    responsiveLayout="scroll"
    class="mb-6"
    :value="bottomTable"
    tableClass="invisible-header-table"
  >
    <Column
      field="space"
      header=""
      :styles="{ width: '81%', borderBottom: 'unset' }"
      :headerStyle="{ background: 'transparent' }"
    ></Column>
    <Column
      field="key"
      :headerStyle="{ background: 'transparent' }"
      headerClass="subTotalHeader"
      :styles="{
        width: '10%',
        flexFlow: 'row-reverse',
        verticalAlign: 'top !important',
      }"
    >
      <template #header> Sub Total</template
      ><template #body="slotProps">
        <div class="text-end pr-0 -mr-3">
          {{ slotProps.data.key }}
        </div></template
      >
    </Column>
    <Column
      :headerStyle="{ background: 'transparent' }"
      field="value"
      bodyStyle="text-align:right"
      headerClass="subTotalSecondHeader"
      :styles="{ width: '10%', verticalAlign: 'top !important' }"
    >
      <template #header>
        {{ formatCurrency(subTotal, currencySymbol) }}</template
      >
      <template #body="slotProps">
        <div class="flex justify-content-end p-0">
          {{ slotProps.data.value }}
        </div></template
      >
    </Column>

    <Column
      :headerStyle="{ background: 'transparent' }"
      field="spacing"
      :styles="{ width: '4%' }"
      bodyStyle="text-align:right"
      ><template #body>
        <div class="flex justify-content-end align-items-center">
          <div
            v-if="status == 'Draft'"
            style="width: 45px"
          ></div></div></template
    ></Column>
  </DataTable>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
    },
    subTotal: {
      type: Number,
    },
    profitMargin: {
      type: Number,
    },
    currencySymbol: {
      type: String,
    },
    taxRate: {
      type: Number,
    },
    total: {
      type: Number,
    },
  },
  computed: {
    bottomTable() {
      const taxItem = {
        key: `Tax (${this.taxRate}%)`,
        value: this.formatCurrency(
          this.subTotal * (this.taxRate / 100),
          this.currencySymbol
        ),
      };

      const totalItem = {
        key: "Total",
        value: this.formatCurrency(
          this.total ? this.total : this.subTotal + this.calculateTotal,
          this.currencySymbol
        ),
      };

      const profitMarginItem = this.profitMargin
        ? {
            key: "Profit Margin",
            value: this.formatCurrency(this.profitMargin, this.currencySymbol),
          }
        : null;

      return [taxItem, totalItem, profitMarginItem].filter(
        (item) => item !== null
      );
    },
    calculateTotal() {
      let total = 0;
      if (this.taxRate < 1) {
        total = 0;
      } else {
        total = this.subTotal * (this.taxRate / 100);
      }
      return total;
    },
  },
};
</script>

<style>
</style>
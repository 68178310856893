<template>
  <div>
    <i
      class="la la-ellipsis-v task-del"
      style="color: #aaa;"
      @click="toggle"
    />
    <Menu
      ref="menu"
      :popup="true"
      :model="items"
      appendTo="body"
    />
  </div>
</template>
<script>
export default {
  name: 'GroupMenu',
  props: {
    group: String,
  },
  data() {
    return {
      items: [
        {
          label: "Edit",
					command: () => {
						this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Not available yet', life: 3000});
					},
        },
        {
          label: "Allocate to CE",
					command: () => {
						this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Not available yet', life: 3000});
					},
        },
        {
          label: "Duplicate",
					command: () => {
						this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Not available yet', life: 3000});
					},
        },
        {
          label: "Delete",
					command: () => {
						this.$parent.$parent.deleteGroup(this.group);
					},
        },
        {
          label: "Move group and time",
					command: () => {
						this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Not available yet', life: 3000});
					},
        },
        {
          label: "Save as template",
					command: () => {
						this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Not available yet', life: 3000});
					},
        },
      ],
    };
  },
  methods: {    
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
  }
}
</script>
<style scoped>
  .task-del {
    position: absolute; 
    right: 10px; 
    top: 15px;
    color: #111; 
    font-size: 20px; 
    cursor: pointer; 
    color: #778ca2;
    transition: 220ms;
  }
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:('min-width: 300px; min-height: 100px; ')},[(!_vm.hideSelectAll)?_c('div',{staticClass:"field-checkbox absolute top-0 right-0 pr-4 mt-5",staticStyle:{"margin-right":"1%"}},[_c('label',{staticClass:"mr-3",attrs:{"for":"binary"}},[_vm._v("Select All")]),_c('Checkbox',{attrs:{"id":"binary","binary":true},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})],1):_c('div',{staticClass:"flex align-items-center justify-content-between"},[_c('div',{staticClass:"ml-3 mb-3 text-18"},[_vm._v("Select Items")]),_c('div',{staticClass:"field-checkbox mb-0 mb-3",staticStyle:{"margin-right":"17px"}},[_c('label',{staticClass:"mr-2",attrs:{"for":"binary"}},[_vm._v("Select All")]),_c('Checkbox',{attrs:{"id":"binary","binary":true},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})],1)]),_c('div',_vm._l((_vm.groups),function(group){return _c('div',{key:group.id},[([...(group.tasks || []), ...(group.costs || [])]?.length > 0)?_c('div',{staticStyle:{"padding-bottom":"26px"}},[_c('DataTable',{staticClass:"dt-class hide-empty-dt",attrs:{"value":[...(group.tasks || []), ...(group.costs || [])],"rowClass":_vm.rowClass,"responsiveLayout":"scroll","editMode":"row","dataKey":"uid"}},[_c('Column',{attrs:{"styles":{
              width: '60%',
              verticalAlign: 'top !important',
            },"field":"description","footerStyle":{
              backgroundColor: 'white',
            }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"font-light"},[_vm._v(" "+_vm._s(group.title)+" ")])]},proxy:true},{key:"body",fn:function(slotProps){return [_c('div',[_c('div',{staticClass:"p-1"},[(slotProps.data.task_type_name)?_c('span',[_vm._v(_vm._s(slotProps.data.task_type_name)+" ")]):_vm._e(),(slotProps.data.type_name)?_c('span',[_vm._v(_vm._s(slotProps.data.type_name)+" ")]):_vm._e(),(
                      slotProps.data.title && slotProps.data.title !== 'null'
                    )?_c('span',[_vm._v(" - "+_vm._s(slotProps.data.title)+" ")]):_vm._e()]),(slotProps.data && slotProps.data.description)?_c('div',{staticClass:"p-1 flex flex-wrap font-light"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.markDown(slotProps.data.description))}})]):_vm._e()])]}}],null,true)}),_c('Column',{attrs:{"field":"option","styles":{
              width: '1%',
              whiteSpace: 'nowrap',
              verticalAlign: 'top !important',
            },"footerStyle":{
              backgroundColor: 'white',
            }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(slotProps.data.item && slotProps.data.item.options)?_c('div',{staticClass:"flex flex-column"},_vm._l((slotProps.data.item.options),function(o,index){return _c('div',{key:'q' + o.uid,staticClass:"col pr-0 ce-co"},[(index > 0)?_c('span',[_vm._v("option")]):_c('span',{staticStyle:{"width":"100%","display":"inline-block"}})])}),0):(slotProps.data.item)?_c('div',{staticClass:"col pr-0"}):_vm._e()]}}],null,true)}),_c('Column',{attrs:{"header":"Qty","styles":{
              width: '6%',
              verticalAlign: 'top !important',
              whiteSpace: 'nowrap',
            },"footerStyle":{
              backgroundColor: 'white',
            }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('div',{staticClass:"col pr-0"},[_vm._v(" "+_vm._s(slotProps.data.qty ? _vm.handleQty(slotProps.data.qty) : slotProps.data.qty_full ? slotProps.data.qty_full : slotProps.data.estimated_hours ? slotProps.data.estimated_hours : slotProps.data.quantity ? slotProps.data.quantity : 0)+" ")])]}}],null,true)}),_c('Column',{attrs:{"header":"Price/Unit","field":"rate","styles":{
              width: '10%',
              verticalAlign: 'top !important',
              whiteSpace: 'nowrap',
            },"headerClass":"header-right","footerStyle":{
              backgroundColor: 'white',
            }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('div',{staticClass:"col text-end pr-0"},[_vm._v(" "+_vm._s(!!slotProps.data.rate ? _vm.formatCurrency( slotProps.data.rate, _vm.job?.client?.currency?.description ) + (slotProps.data.type === "cost" ? "" : "/hr") : _vm.formatCurrency( slotProps.data.price, _vm.job?.client?.currency?.description ))+" ")])]}}],null,true)}),_c('Column',{attrs:{"headerClass":"header-right","header":!_vm.canSelect ? 'Billed Amount' : 'Total Billable',"field":"active","styles":{
              width: '10%',
              verticalAlign: 'top !important',
              textAlign: 'right',
              whiteSpace: 'nowrap',
            },"footerStyle":{
              backgroundColor: 'white',
            }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('div',{staticClass:"col text-end pr-0"},[_vm._v(" "+_vm._s(slotProps.data.total ? _vm.formatCurrency( slotProps.data.total, _vm.job?.client?.currency?.description ) : slotProps.data.total_billable ? _vm.formatCurrency( slotProps.data.total_billable, _vm.job?.client?.currency?.description ) : slotProps.data.total_price ? _vm.formatCurrency( slotProps.data.total_price, _vm.job?.client?.currency?.description ) : slotProps.data.price || slotProps.data.price === 0 ? _vm.formatCurrency( slotProps.data.price, _vm.job?.client?.currency?.description ) : _vm.formatCurrency( (slotProps.data.seconds / 3600) * (slotProps.data.type == "cost_type" ? slotProps.data.resell_amount : slotProps.data.rate), _vm.job?.client?.currency?.description ))+" ")])]}}],null,true)}),(_vm.canSelect)?_c('Column',{attrs:{"field":"isSelect","styles":{
              width: '5% !important',
              verticalAlign: 'top !important',
            }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('Checkbox',{staticStyle:{"width":"25px"},attrs:{"name":"id","id":'g-cb' + group.id,"value":group.id},model:{value:(_vm.selectGroups),callback:function ($$v) {_vm.selectGroups=$$v},expression:"selectGroups"}})]},proxy:true},{key:"body",fn:function(slotProps){return [_c('Checkbox',{staticStyle:{"width":"25px"},attrs:{"name":"id","id":'cb' + slotProps.data.uid,"value":slotProps.data.uid},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]}}],null,true)}):_vm._e(),(!_vm.canSelect)?_c('Column',{attrs:{"styles":{ width: '5%', verticalAlign: 'top !important' }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('div',{staticClass:"flex justify-content-end align-items-center invisible cursor-pointer"},[_c('i',{staticClass:"las la-trash i-20 grey",on:{"click":function($event){$event.stopPropagation();return _vm.deletePrompt(
                      slotProps.data.type_name,
                      '/v1/finance/invoice/remove/' + slotProps.data.uid,
                      'get'
                    )}}})])]}}],null,true)}):_vm._e()],1)],1):_vm._e()])}),0),(_vm.items === 0)?_c('div',[_vm._v("No billable items")]):_vm._e(),(_vm.canSelect)?_c('div',{staticClass:"flex justify-content-between absolute pb-3 ml-1 pt-1 bg-white bottom-0 left-0 pl-3",staticStyle:{"width":"99%"}},[_c('Button',{attrs:{"type":"submit","label":_vm.isEstimate ? 'Generate Estimate' : 'Add Items',"input-class":['is-primary'],"disabled":_vm.selected.length === 0 || _vm.isLoading,"loading":_vm.isLoading},on:{"click":_vm.add}}),_c('div',{staticClass:"flex align-items-center"},[(_vm.items > 0)?_c('div',{staticClass:"font-bold mr-2"},[_vm._v(" Total: "+_vm._s(_vm.formatCurrency(_vm.total, _vm.job?.client?.currency?.description))+" ")]):_vm._e(),_c('Button',{staticClass:"p-button-text p-button-secondary",attrs:{"loading":_vm.isLoading,"disabled":_vm.isLoading},on:{"click":function($event){return _vm.$emit('dismiss')}}},[_vm._v(" CANCEL ")])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
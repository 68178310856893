<template>
  <div class="flex flex-column">
    <LoginLogo></LoginLogo>
    <div class="flex justify-content-center text-center">
      <div class="card p-6 w-30rem" style="height: 270px">
        <h2 class="mb-5">Forgot Password</h2>

        <form
          v-if="!isLoading"
          align="left"
          @submit="submitHandler"
          v-form-enter
        >
          <div class="field">
            <label class="label"
              >Enter your registered email to reset your password</label
            >
            <div class="p-inputgroup">
              <InputText placeholder="Email" v-model="email"></InputText>
            </div>
          </div>
          <br />
          <div class="flex justify-content-between pt-1">
            <div>
              <Button type="submit">Reset</Button>
            </div>
            <Button
              class="p-button-text p-button-secondary"
              @click="
                $router.push('login');
                $store.dispatch('setSelectedObject', null);
              "
            >
              CANCEL
            </Button>
          </div>
        </form>
        <div v-else class="pt-5">
          <RocketLoad />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      isLoading: false,
      email: null,
    };
  },

  methods: {
    submitHandler() {
      this.submitted = true;

      this.isLoading = true;

      var form = new FormData();
      form.append("email", this.email);
      this.$axios
        .post(process.env.VUE_APP_ROOT_API + "/auth/reset", form)
        .then((response) => {
          this.isLoading = false;
          this.$toast.add({
            severity: "success",
            summary: "Please check your email for the reset link",
            life: 3000,
          });
          this.$router.push("login");
        })
        .catch((error) => {
          this.isLoading = false;

          console.error(error);
        });
    },
  },

  metaInfo: {
    title: "Forgot Password",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Forgot Password",
      },
    ],
  },
};
</script>

<style scoped>
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}
</style>
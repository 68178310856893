<template>
  <div class="flex">
    <div class="col-6 p-0">
      <WelcomeLanding></WelcomeLanding>
    </div>
    <div class="col-6 p-0">
      <Welcome></Welcome>
    </div>
  </div>
</template>

<script>
import Welcome from "../components/WelcomeForm.vue";
import WelcomeLanding from "../components/WelcomeLanding.vue";
export default {
  components: { Welcome, WelcomeLanding },
};
</script>
<template>
  <div class="card">
    <div
      class="flex sm:justify-content-start justify-content-around align-items-center pl-2"
    >
      <router-link v-for="r in path" :key="r.path" :to="{ path: r.route }">
        <h6 class="sm:pr-5" :style="'color: grey;' + getHeaderStyle(r.route)">
          {{ r.header }}
        </h6>
      </router-link>
    </div>

    <div>
      <transition name="slide-fade">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      path: [
        {
          route: "/settings/templates",
          header: "Jobs",
        },
        // {
        //   route: "/settings/templates/estimate_templates",
        //   header: "Estimates",
        // },
      ],
    };
  },

  methods: {
    getHeaderStyle(route) {
      if (this.$route.path == route) {
        return "font-weight: bold; color: #4D7CFE";
      }
    },
  },

  computed: {},
  name: "Templates",
};
</script>

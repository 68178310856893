<template>
  <form style="width: 250px" @submit.prevent="submitHandler" v-form-enter>
    <RocketLoad v-if="isLoading" />
    <template v-else>
      <div class="w-full">
        <div class="flex justify-content-center">
          <Chip :style="getStyle(tag.colour)" class="mr-3 mb-2 tag-chip"
            ><div class="flex p-2">{{ tag.name }}</div>
          </Chip>
        </div>
        <span class="w-full p-input-icon-right">
          <div>
            <InputText
              class="w-full px-0 border-x-none border-top-none border-noround"
              placeHolder="Name tag"
              v-model="tag.name"
            />
          </div>
          <i class="pi pi-times cursor-pointer" @click="clearInput" />
        </span>
      </div>
      <div
        class="error-validation-text pt-1"
        v-if="submitted && !$v.tag.name.required"
      >
        Tag name is required
      </div>
      <div
        class="error-validation-text pt-1"
        v-if="submitted && !$v.tag.colour.required"
      >
        Tag colour is required
      </div>
      <div class="container">
        <div
          v-for="colour in presetColors"
          :key="colour.id"
          class="cursor-pointer"
          style="width: fit-content; block-size: fit-content; padding: 10px 0"
          @click="tag.colour = colour.colour"
        >
          <div
            :style="{
              backgroundColor: `#${colour.colour}`,
              height: '24px',
              width: '24px',
              borderRadius: '50%',
              opacity: '0.75',
            }"
          ></div>
        </div>
      </div>
      <div class="flex justify-content-between">
        <Button type="submit">Save </Button>
        <Button class="p-button-text p-button-secondary" @click="dismiss">
          CANCEL
        </Button>
      </div></template
    >
  </form>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  props: {
    tagProp: {
      type: Object,
    },
  },
  data() {
    return {
      submitted: false,
      isLoading: false,
      tag: {
        name: null,
        colour: null,
      },
      presetColors: [
        { id: 1, colour: "F86BD2" },
        { id: 2, colour: "00BAFF" },
        { id: 3, colour: "F5D900" },
        { id: 4, colour: "00B978" },
        { id: 5, colour: "FF7F3B" },
        { id: 6, colour: "C12CDD" },
        { id: 7, colour: "00A1E0" },
        { id: 8, colour: "E0C62C" },
        { id: 9, colour: "60A320" },
        { id: 10, colour: "FA0218" },
        { id: 11, colour: "5E00CC" },
        { id: 12, colour: "0263D1" },
        { id: 13, colour: "CCB244" },
        { id: 14, colour: "00733B" },
        { id: 15, colour: "D10058" },
      ],
    };
  },
  validations: {
    tag: {
      name: {
        required,
      },
      colour: {
        required,
      },
    },
  },
  mounted() {
    if (this.tagProp) {
      this.tag = JSON.parse(JSON.stringify(this.tagProp));
    }
  },
  methods: {
    clearInput() {
      this.tag.name = "";
    },
    getStyle(c) {
      if (c && c.charAt(0) !== "#") {
        c = "#" + c;
      }
      return "background-color: " + c + ";color:white;opacity:0.75";
    },
    dismiss() {
      this.$emit("dismiss");
    },
    submitHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the required fields",
          life: 3000,
        });
      } else {
        this.isLoading = true;
        var form = new FormData();
        form.append("name", this.tag.name);
        form.append("colour", this.tag.colour);
        this.$axios
          .post(
            process.env.VUE_APP_ROOT_API +
              "/v1/tasktag" +
              (this.tag.uid ? "/" + this.tag.uid : ""),
            form,
            {
              headers: { "Content-type": "application/x-www-form-urlencoded" },
            }
          )
          .then((response) => {
            this.dismiss();
            this.$toast.add({
              severity: "success",
              summary: "Global Tag " + (this.tag.uid ? "Updated" : "Created"),
              life: 3000,
            });
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response && error.response.status === 401) {
              console.error("NOOOOOOOOO!");
            } else {
              // Handle error however you want
            }
            console.error(error);
          });
      }
    },
  },
};
</script>

<style  scoped>
.container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  place-items: center;
  margin: 1.3rem 0;
}
.p-dialog-header {
  display: none !important;
}
.p-inputtext:enabled:focus {
  box-shadow: none !important;
}
</style>
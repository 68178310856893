<template>
  <div
    class="flex col-12 justify-content-center has-text-white has-text-weight-bold is-size-4 is-nunito"
  >
    <div
      class="col-7 text-center"
      style="
        background-color: black;
        border-radius: 16px;
        margin-bottom: -24px;
        z-index: 2;
      "
    >
      <img src="@/assets/logo_new.svg" style="height: 36px; margin-top: 4px" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
<template>
  <div class="container" align="center">
    <br /><br />
    <h2>Dashboard</h2>
    <br /><br />
    <div v-if="!posts[0]">
      <div class="task-entry">
        <div class="this-title">
          <div class="loading-slot" />
        </div>
        <div class="this-description">
          <div class="loading-slot" />
        </div>
        <div class="this-email">
          <div class="loading-slot" />
        </div>
        <div class="this-duedate">
          <div class="loading-slot" />
        </div>
      </div>
      <div class="task-entry">
        <div class="this-title">
          <div class="loading-slot" />
        </div>
        <div class="this-description">
          <div class="loading-slot" />
        </div>
        <div class="this-email">
          <div class="loading-slot" />
        </div>
        <div class="this-duedate">
          <div class="loading-slot" />
        </div>
      </div>
      <div class="task-entry">
        <div class="this-title">
          <div class="loading-slot" />
        </div>
        <div class="this-description">
          <div class="loading-slot" />
        </div>
        <div class="this-email">
          <div class="loading-slot" />
        </div>
        <div class="this-duedate">
          <div class="loading-slot" />
        </div>
      </div>
    </div>
    <UserEntry v-for="(task, idx) in posts" :key="idx" :task="task" />
  </div>
</template>

<script>
import UserEntry from "../application/Users/components/UserEntry.vue";

export default {
  name: "Home",
  components: {
    UserEntry,
  },
  data() {
    return {
      posts: [],
      errors: [],
    };
  },

  mounted() {
    this.$axios
      .get("http://agencydesk.localhost/v1/users")
      .then((response) => {
        this.posts = response.data.data;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },

  metaInfo: {
    title: "Login",

    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: "" },
      { name: "keywords", content: "" },
    ],
  },
};
</script>
<template>
  <div
    class="flex flex-column align-items-center justify-content-center"
    style="height: 100vh"
  >
    <div class="flex justify-content-center">
      <div class="w-30rem">
        <h5 class="font-bold text-primary mb-3">Almost there...</h5>
        <h1 style="font-size: 32px">Invite some team members...</h1>

        <form @submit="submit" @keyup.enter="submit" style="margin-top: 2rem">
          <div class="field" v-for="(t, index) in team" :key="index">
            <InputText
              v-model="team[index]"
              class="w-full"
              placeholder="name@company.com"
              style="padding: 1rem"
            />
          </div>

          <div class="field">
            <a
              label="Add Task"
              @click="team.push('')"
              class="pl-3 cursor-pointer mt-1"
              style="color: #778ca2; font-size: 14px"
            >
              + Add more
            </a>
          </div>

          <div style="margin-top: 2rem">
            <Button
              type="submit"
              class="button is-primary"
              label="Next"
              style="width: 125px"
            />
          </div>
          <div class="field mt-4">
            <a label="Add Task" @click="team.push('')" class="cursor-pointer">
              Skip for now
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      team: ["", "", ""],
    };
  },
  metaInfo: {
    title: "Welcome",
  },
  methods: {
    submit() {
      this.$router.push("/onboard/complete");
    },
  },
};
</script>
  
  <style>
</style>
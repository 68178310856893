<template>
  <div class="body-container">
    <Toast />

    <router-view />
  </div>
</template>

<script>
export default {
  methods: {},
  name: "LayoutLogin",
  data() {
    return {
      user: null,
      showMobileMenu: 0,
    };
  },

  metaInfo: {
    title: "",
    titleTemplate: "%s | Agencydesk",
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { vmid: "description", name: "description", content: "AgencyDesk" },
    ],
    link: [{ rel: "icon", type: "image/x-icon", href: "/favicon.ico" }],
  },
};
</script>

<style lang="scss" scoped>
.body-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  width: 100%;
  background: #98a9bc;
  top: 0px;
}
</style>

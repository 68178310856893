<template>
  <div>
    <!-- <div
      class="flex flex-wrap sm:justify-content-end justify-content-center align-items-center pb-2"
    >
      <div>
        <Button
          label="New Category"
          class="p-button-text p-button-secondary"
          @click="$store.dispatch('setSliderView', 'CategoriesSlider')"
          icon="las la-plus"
        />
      </div>
      <div>
        <FilterSearchButton v-model="filters['global'].value" />
      </div>
    </div> -->
    <DataTable
      responsiveLayout="scroll"
      :rowClass="rowClass"
      :value="categories"
      :filters.sync="filters"
      filterDisplay="menu"
      editMode="row"
      dataKey="uid"
      :editingRows.sync="editingRows"
      @row-edit-cancel="onRowEditCancel"
      @row-edit-save="saveRow"
    >
      <template #empty>
        <Button
          v-if="!filters['global'].value"
          label="New Category"
          class="p-button-text p-button-secondary"
          @click="$store.dispatch('setSliderView', 'CategoriesSlider')"
          icon="las la-plus"
        />
        <div v-else>No Results</div>
      </template>
      <Column
        field="description"
        header="Description"
        filterField="description"
        :styles="{ width: '70%' }"
      >
        <template #body="slotProps">
          <Skeleton width="20%" v-if="isLoading"></Skeleton>
          <template v-else>
            <span
              class="cursor-pointer"
              @click="
                $store.dispatch('setSelectedObject', slotProps.data);
                $store.dispatch('setSliderView', 'CategoriesSlider');
              "
            >
              {{ slotProps.data.description }}
            </span>
          </template>
        </template>
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Search by Description"
          />
        </template>
        <template #editor="slotProps">
          <InputText
            placeHolder="Description"
            v-model="slotProps.data['description']"
          />
        </template>
      </Column>
      <Column
        sortable
        field="active"
        header="Active"
        bodyStyle="translateY(7px)"
      >
        <template #body="slotProps">
          <Skeleton
            width="4rem"
            height="2rem"
            borderRadius="16px"
            v-if="isLoading"
          ></Skeleton>
          <template v-else>
            <AActiveSwitch
              @change="saveRow(slotProps.data)"
              v-model="slotProps.data[slotProps.column.field]"
            />
          </template>
        </template>
      </Column>
      <Column bodyStyle="text-align:right" :styles="{ width: '1rem' }">
        <template #body="slotProps">
          <div
            class="flex justify-content-end align-items-center invisible cursor-pointer"
          >
            <template v-if="editingRow !== slotProps.data.uid">
              <i
                @click="editRow(slotProps.data, categories)"
                :class="getEditClass(rowInEdit)"
              ></i>
              <i
                :class="getDeleteClass(rowInEdit)"
                @click="
                  deletePrompt(
                    slotProps.data.description,
                    '/v1/jobs/category/delete/' + slotProps.data.uid
                  )
                "
              />
            </template>
            <template v-else>
              <i
                class="las la-check i-20 mr-3 grey"
                @click="saveRow(slotProps.data)"
              />
              <i
                class="las la-times i-20 grey"
                @click="onRowEditCancel(slotProps.data, categories)"
              />
            </template>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script>
import { fetchCategories, state } from "../../../services/data_service";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Vue from "vue";
export default {
  data() {
    return {
      isLoading: true,
      loadingText: "Loading...",
      originalRows: [],
      editingRows: [],
      editingRow: null,
      rowInEdit: false,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
  },
  watch: {
    $route(to, from) {
      this.filters['global'].value = to.query.term
    }
  },
  methods: {
    async loadData() {
      await fetchCategories();
    },
    getEditClass(t) {
      return t === true ? "hidden" : "las la-pen i-20 mr-3 grey";
    },
    getDeleteClass(t) {
      return t === true ? "hidden" : "las la-trash i-20 grey";
    },
    rowClass() {
      return "row-accessories";
    },
    editRow(r, arr) {
      this.rowInEdit = true;
      this.editingRow = r.uid;
      this.editingRows.push(r);
      var index = arr.indexOf(r);
      this.originalRows[index] = {
        ...arr[index],
      };
    },
    onRowEditCancel(r, arr) {
      this.rowInEdit = false;
      var index = arr.indexOf(r);
      Vue.set(arr, index, this.originalRows[index]);
      this.editingRow = null;
      var i2 = this.editingRows.indexOf(r);
      this.editingRows.splice(i2, 1);
    },
    saveRow(r) {
      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API + "/v1/jobs/category/update/" + r.uid,
          JSON.stringify(r),
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          this.rowInEdit = false;
          this.editingRow = null;
          var i2 = this.editingRows.indexOf(r);
          this.editingRows.splice(i2, 1);
          this.$toast.add({
            severity: "success",
            summary: "Category Updated",
            life: 3000,
          });

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },
  },
  async mounted() {
    if (!this.categories) {
      state.categories = [{}, {}, {}, {}, {}, {}, {}];
    }
    await this.loadData();
    this.isLoading = false;
  },

  computed: {
    categories() {
      return state.categories;
    },
    user() {
      return this.$store.getters.user;
    },
    obj() {
      return this.$store.getters.selectedObject;
    },
  },

  name: "Home",
  components: {},

  metaInfo: {
    title: "Settings | Category",

    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: "" },
      { name: "keywords", content: "" },
    ],
  },
};
</script>

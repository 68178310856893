<template>
  <div class="flex p-2 align-items-center mb-3 justify-content-between">
    <div class="flex">
      <h1 class="mr-6 mb-unset">
        {{ isTimesheetView == 1 ? "Timesheets" : "Task Schedule " }}
      </h1>

      <div class="hidden sm:flex task-schedule">
        <div
          v-if="isTimesheetView != 1"
          :class="
            'mr-6 p-1  ' +
            (filters.indexOf('overdue') >= 0
              ? 'bottom-border-pink'
              : 'bottom-border-invisible')
          "
          @click="filter('overdue')"
        >
          <p class="font-light cursor-pointer">
            Overdue
            <Badge
              style="background-color: #fe4d97"
              class="ml-2"
              :value="overdue"
            />
          </p>
        </div>
        <div
          v-if="isTimesheetView != 1"
          :class="
            'mr-6 p-1 ' +
            (filters.indexOf('overrun') >= 0
              ? 'bottom-border-warning'
              : 'bottom-border-invisible')
          "
          @click="filter('overrun')"
        >
          <p class="font-light cursor-pointer">
            Overrun<Badge
              style="background-color: #ffab2b"
              class="ml-2"
              :value="overrun"
            />
          </p>
        </div>
        <div
          v-if="isTimesheetView != 1"
          :class="
            'mr-6 p-1 ' +
            (filters.indexOf('unplanned') >= 0
              ? 'bottom-border-positive'
              : 'bottom-border-invisible')
          "
          @click="filter('unplanned')"
        >
          <p class="font-light cursor-pointer">
            Unplanned Time<Badge
              style="background-color: #6dd230"
              class="ml-2"
              :value="unplanned"
            />
          </p>
        </div>
      </div>
    </div>
    <div class="flex">
      <div class="flex align-items-center">
        <Button
          :disabled="isLoading"
          :label="isTimesheetView == 1 ? 'Task Schedule' : 'Timesheets'"
          @click="
            isTimesheetView == 1
              ? (isTimesheetView = 0)
              : (isTimesheetView = 1);
            $emit('switchView', isTimesheetView);
          "
        />
      </div>
      <Button
        class="p-button-text p-button-secondary"
        :class="{ 'spin-animation': isRefreshing }"
        icon="las la-sync"
        @click="$emit('refresh')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    overdue: {
      type: Number,
    },
    overrun: {
      type: Number,
      default: 0,
    },
    unplanned: {
      type: Number,
      default: 0,
    },
    filters: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
    },
    isRefreshing: {
      type: Boolean,
    },
  },
  methods: {
    filter(f) {
      this.$emit("filter", f);
    },
  },
  data() {
    return {
      isTimesheetView: 0,
    };
  },
};
</script>

<style>
.task-schedule {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

@media screen and (max-width: 680px) {
  .task-schedule {
    display: grid;
    /* gap: 10px; */
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('DataTable',{attrs:{"responsiveLayout":"scroll","rowClass":_vm.rowClass,"value":_vm.taskTags,"filters":_vm.filters,"dataKey":"uid"},on:{"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" Nothing yet... ")]},proxy:true}])},[_c('Column',{attrs:{"field":"name","header":"Global Tags","headerClass":"w-7","filterField":"name"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(_vm.isLoading)?_c('Skeleton',{staticStyle:{"padding":"'5px 10px'"},attrs:{"width":"4rem","height":"1.5rem","borderRadius":"16px"}}):[_c('span',{staticClass:"cursor-pointer",style:({
                backgroundColor: `#${slotProps.data.colour}`,
                color: '#FFFFFF',
                padding: '5px 10px',
                borderRadius: '500px',
              }),on:{"click":function($event){return _vm.$store.dispatch('setSelectedObject', slotProps.data)}}},[_vm._v(" "+_vm._s(slotProps.data.name)+" ")])]]}},{key:"filter",fn:function({ filterModel }){return [_c('InputText',{staticClass:"p-column-filter",attrs:{"type":"text","placeholder":"Search by name"},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}},{key:"editor",fn:function(slotProps){return [[_c('span',{staticClass:"cursor-pointer",style:({
                backgroundColor: `#${slotProps.data.colour}`,
                color: '#FFFFFF',
                padding: '5px 10px',
                borderRadius: '500px',
              }),on:{"click":function($event){return _vm.$store.dispatch('setSelectedObject', slotProps.data)}}},[_vm._v(" "+_vm._s(slotProps.data.name)+" ")])]]}}])}),_c('Column',{attrs:{"bodyStyle":"text-align:right","styles":{ width: '1rem' }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('div',{staticClass:"flex justify-content-end align-items-center invisible cursor-pointer"},[[_c('i',{staticClass:"las la-pen i-20 mr-3 grey",on:{"click":function($event){return _vm.editRow(slotProps.data, _vm.taskTags)}}}),_c('i',{staticClass:"las la-trash i-20 cursor-pointer mr-5 grey",on:{"click":function($event){$event.stopPropagation();return _vm.deletePrompt(
                    slotProps.data.name,
                    '/v1/tasktag/' + slotProps.data.uid
                  )}}})]],2)]}}])})],1)],1),_c('Dialog',{attrs:{"visible":_vm.display},on:{"update:visible":function($event){_vm.display=$event}}},[_c('GlobalTagPopup',{attrs:{"tagProp":_vm.tag},on:{"dismiss":function($event){_vm.display = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>Members!</div>
</template>
<script>
export default {
  name: 'JobTasks',
  props: {
    task: Object,
  }
}
</script>
<template>
  <div class="ppp">
    <div
      class="progress-container"
      :style="[cssVars, isActiveCSS]"
    >
      <div class="progress-ring">
        <div class="piechart" />
        <img
          :src="src"
          style="width: 100%; height: 100%; border-radius: 50%"
          v-if="src"
          v-show="logoLoad"
          @load="this.logoLoaded"
        >
        <div
          :style="'background: ' + color"
          style="
            color: #fff;
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 8;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: 500;
          "
          v-if="!src || !logoLoad"
        >
          {{ userInitials }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    src: String,
    user: Object,
    initials: String,
    progress: Number,
    active: String,
  },
  data() {
    return {
      logoLoad: false,
    };
  },

  methods: {
    logoLoaded() {
      this.logoLoad = true;
    },
  },
  computed: {
    color() {
      var hash = 0;
      var str =
        this.user.name +
        this.user.surname +
        this.user.username +
        this.user.email;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      var h = hash % 6;
      if (h < 0) {
        h = h * -1;
      }

      var cols = [
        "#5E00CC",
        "#00BAFF",
        "#F5D900",
        "#00B978",
        "#E0005E",
        "#ED5200",
        "#CCB244",
      ];

      return cols[h];
    },
    cssVars() {
      if (this.progress > 100) {
        return {
          "--progressColor": "#FE4D97",
          "--progressDegrees": 360 / (100 / this.progress) + "deg",
        };
      } else {
        return {
          "--progressColor": "#41D363",
          "--progressDegrees": 360 / (100 / this.progress) + "deg",
        };
      }
    },
    isActiveCSS() {
      if (this.user.active != 1 && !this.active) {
        return {
          opacity: "0.3",
        };
      }
      return {
        opacity: "1",
      };
    },
    userInitials: function () {
      var names = this.initials.split(" ");
      var initials = names[0].substring(0, 1).toUpperCase();
      if (names[1]) {
        initials = initials + names[1].substring(0, 1).toUpperCase();
      } else {
        initials = initials + names[0].substring(1, 2).toUpperCase();
      }
      return initials;
    },
  },
  mounted() {},
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.ppp {
  position: relative;
  display: inline-block;
}
.progress-container {
  display: block;
  position: absolute;
  width: 44px;
  height: 44px;
  top: -22px;
  z-index: 9;
  transition: 220ms;
}
.progress-ring {
  top: 0px;
  width: 44px;
  height: 44px;
  position: absolute;
  img {
    position: relative;
    z-index: 8;
    background: #fff;
    border-radius: 50%;
    opacity: 1;
    border: 2px solid #fff;
  }
}
.piechart {
  z-index: 7;
  display: block;
  position: absolute;
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  top: -3px;
  left: -3px;
  border-radius: 50%;
  background-image: conic-gradient(
    var(--progressColor) var(--progressDegrees),
    transparent 0
  );
  transform: scaleX(-1);
}
</style>

<template>
  <div class="pt-3">
    <div v-if="isLoading">
      <RocketLoad />
    </div>
    <form
      v-else-if="account_details"
      @submit.prevent="submitHandler"
      v-form-enter
    >
      <div class="agencydesk-spacer" />

      <div class="p-fluid formgrid grid">
        <div class="col-12 md:col-6">
          <div class="p-fluid formgrid grid justify-content-around">
            <div class="field col-12 sm:col-6">
              <label class="font-light" for="Company"
                >Company Display Name</label
              >
              <InputText
                id="Company"
                @input="isDirty = true"
                placeHolder="Company Display Name"
                v-model="account_details.display_name"
                type="text"
              />
            </div>
            <div class="field col-12 sm:col-6">
              <label class="font-light" for="CompanyReg"
                >Company Registration</label
              >
              <InputText
                id="CompanyReg"
                placeHolder="Company Registration No"
                @input="isDirty = true"
                v-model="account_details.company_registration"
                type="text"
              />
            </div>

            <div class="field col-12 sm:col-6">
              <label class="font-light" for="Industry">Industry</label>

              <Dropdown
                inputId="Industry"
                @input="isDirty = true"
                v-model="account_details.industry"
                :options="account_industries"
                dataKey="uid"
                optionLabel="name"
              />
            </div>

            <div class="field col-12 sm:col-6">
              <label class="font-light" for="Telephone">Telephone</label>
              <InputText
                id="Telephone"
                @input="isDirty = true"
                v-model="account_details.tel_number"
                placeHolder="Telephone Number"
                type="text"
              />
            </div>

            <div class="field col-12 sm:col-6">
              <label class="font-light" for="Email">Email</label>
              <InputText
                id="Email"
                @input="isDirty = true"
                v-model="account_details.email"
                placeHolder="Email"
                type="text"
              />
            </div>
            <div class="field col-12 sm:col-6">
              <label class="font-light" for="Website">Website</label>
              <InputText
                id="Website"
                @input="isDirty = true"
                v-model="account_details.website"
                placeHolder="Website"
                type="text"
              />
            </div>
          </div>
        </div>
        <div class="col-12 md:col-4">
          <AProfilePictureUploader
            v-if="account_details"
            @uploaded="submitHandler(false)"
            v-model="account_details.logo_url"
          />
        </div>

        <div class="col-12 md:col-6 lg:col-3">
          <div class="font-light mb-3">Physical Address</div>
          <div style="padding: 0rem" class="field col-12">
            <Textarea
              id="Physical"
              :autoResize="true"
              placeHolder="Physical Address"
              rows="5"
              @input="
                syncAddress();
                isDirty = true;
              "
              v-model="account_details.address_physical"
            />
          </div>
        </div>

        <div class="col-12 md:col-6 lg:col-3">
          <div
            class="font-light mb-3 flex align-items-center justify-content-between"
          >
            Postal Address
            <div class="flex align-items-center">
              <Checkbox id="same" v-model="sameAddress" :binary="true" />
              <label for="same" class="pl-2 text-sm">Same as Physical</label>
            </div>
          </div>
          <div style="padding: 0rem" class="field col-12">
            <Textarea
              :disabled="sameAddress === true"
              id="Postal"
              placeholder="Postal Address"
              :autoResize="true"
              rows="5"
              @input="isDirty = true"
              v-model="account_details.address_postal"
            />
          </div>
        </div>
      </div>
      <div class="flex justify-content-between mt-5">
        <Button type="submit" label="SAVE" />
        <Button label="CANCEL" class="p-button-text p-button-secondary" />
      </div>
    </form>
  </div>
</template>
<script>
import {
  state,
  loadData,
  fetchAccounts,
  fetchAccountIndustries,
} from "../../../services/data_service";
import isEqual from "lodash/isEqual";
export default {
  data() {
    return {
      isDirty: false,
      sameAddress: false,
      isLoading: false,
      loadingText: "Loading...",
      isXeroLoading: false,
    };
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
  },
  methods: {
    submitHandler(loading = true) {
      this.isLoading = loading;

      var form = new FormData();

      form.append("account_details", JSON.stringify(this.account_details));
      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API +
            "/v1/accounts/details/" +
            this.account_details.uid,
          JSON.stringify(this.account_details),
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          this.$store.dispatch("setRecentlySavedProfile");
          this.$toast.add({
            severity: "success",
            summary: "Details Saved",
            life: 3000,
          });
          this.isDirty = false;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.isDirty = true;
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },
    async loadData() {
      fetchAccounts();
      fetchAccountIndustries();
    },
    getHeaderStyle(route) {
      if (this.$route.path == route) {
        return "font-weight: bold; color: #4D7CFE";
      }
    },
    syncAddress() {
      if (this.sameAddress === true && this.account_details?.address_postal) {
        this.account_details.address_postal = JSON.parse(
          JSON.stringify(this.account_details?.address_physical)
        );
      }
    },
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    account_industries() {
      return state.accountIndustries;
    },
    account_details() {
      return state.accounts;
    },
  },
  watch: {
    sameAddress(n) {
      if (n === true) {
        this.syncAddress();
      }
    },
    account_details(n) {
      if (isEqual(n?.address_physical, n?.postalAddress)) {
        this.sameAddress = true;
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.loadData();

    this.isLoading = false;
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty) {
      this.$confirm.require({
        message: "Continue without saving?",
        header: "Warning",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          next();
        },
        reject: () => {
          next(false);
        },
      });
    } else {
      next();
    }
  },
  name: "Home",

  metaInfo: {
    title: "Settings | Account Details",
    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: "" },
      { name: "keywords", content: "" },
    ],
  },
};
</script>


<template>
  <div
    class="flex align-items-center text-center justify-content-center bg-black"
    style="height: 100vh"
  >
    <div class="flex justify-content-center text-center text-white">
      <div class="w-30rem">
        <div style="margin-top: 50%">
          <img
            alt="Logo"
            class="mb-3"
            src="../../../assets/icons/document_history_light.svg"
            style="width: 100px"
          />
        </div>
        <div class="text-24">
          Easily switch between tasks<br />
          while
          <span class="text-primary"> tracking time</span>
        </div>

        <div style="margin-top: 50%">
          <img
            alt="Logo"
            class="mb-3"
            src="../../../assets/logo_new.svg"
            style="width: 250px"
          />

          <div>Plan • Schedule • Work • Profit</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style>
</style>
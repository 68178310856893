import axios from "axios";
import store from "@/store";
import Vue from 'vue';
import router from '@/router';

function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

const debounceErrors = debounce((msg) =>
    Vue.prototype.$toast.add({
        severity: "error",
        summary: "Error",
        detail: msg,
        life: 3000,
    })
);

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = store.state.token;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);

axiosInstance.interceptors.response.use(
    async (response) => {
        if (response.data.refresh_token) {
            store.dispatch('handleRefreshToken', response.data.refresh_token);
        }




        if (response.status === 200 || response.status === 201) {
            if (response.config?.method === 'post') {
                Vue.prototype.$bus.emit('refreshData')
            }
            return Promise.resolve(response);
        } else {

            return Promise.reject(response);
        }
    },
    async (error) => {
        if (error.response) {
            const status = error.response.status;
            let errorMsg = '';

            switch (status) {
                case 401:
                    errorMsg = error.response.data.description;
                    localStorage.setItem('redirectUrl', router.currentRoute.fullPath);
                    store.dispatch("logout");
                    if (errorMsg === 'Expired token' || errorMsg?.toLowerCase() === 'token not found.' || errorMsg === 'Signature verification failed') {
                        errorMsg = 'You have been logged out because your session has expired. Please log in again.';
                    }
                    debounceErrors(errorMsg);
                    router.push('/login');
                    break;
                case 404:
                    router.push('/')
                    break;
                default:
                    errorMsg = error.response.data.error.description;
                    Vue.prototype.$toast.add({
                        severity: "error",
                        summary: "Error",
                        detail: errorMsg,
                        life: 3000,
                    });
                    break;
            }

            return Promise.reject(error.response);
        }
    }
);

export { axiosInstance };

<template>
  <span
    class="relative"
    @click="addTag(tag)"
    @mouseover="removable = true"
    @mouseleave="removable = false"
  >
    <Chip
      :style="getStyle(tag.colour)"
      :class="'mb-3 tag-chip ' + (!canRemove ? 'cursor-pointer' : '')"
      ><div class="flex p-1 font-bold" style="margin-top: 1px">
        {{ tag.name }}
      </div>
      <i
        @click="removeTag(tag)"
        v-if="removable && canRemove"
        class="las la-times absolute cursor-pointer"
        style="
          top: -10px;
          right: 2px;
          color: black;
          background: #dee2e6;
          border-radius: 50%;
          padding: 2px;
        "
      ></i>
    </Chip>
  </span>
</template>

<script>
import {
  state,
  fetchTasksJob,
  fetchMyTasks,
} from "../services/data_service.js";
export default {
  data() {
    return {
      removable: false,
    };
  },
  props: {
    tag: {
      type: Object,
    },
    canRemove: {
      type: Boolean,
      default: true,
    },
    canAdd: {
      type: Boolean,
      default: true,
    },
    task: {
      type: Object,
    },
  },
  computed: {
    taskTags() {
      return state.taskTags;
    },
  },
  methods: {
    removeTag(tag) {
      this.$confirm.require({
        message:
          "Are you sure you want to remove the '" +
          tag.name +
          "' tag from this task ?",
        header: "Confirm Delete",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.task.tags.splice(this.task.tags.indexOf(tag), 1);

          this.fetch(tag, "0");
          this.$toast.add({
            severity: "success",
            summary: tag.name + " removed",
            detail: tag.name + " has been removed",
            life: 3000,
          });
        },
      });
    },
    addTag(tag) {
      for (let index = 0; index < this.task.tags.length; index++) {
        const element = this.task.tags[index];
        if (element.uid == tag.uid) {
          return;
        }
      }

      this.task.tags.push(tag);
      this.fetch(tag, "1");
    },
    fetch(tag, isActive) {
      this.$axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "/v1/tasktag/toggle?task=" +
            this.task.uid +
            "&tag=" +
            tag.uid +
            "&active=" +
            isActive,
          {}
        )
        .then((response) => {
          if (this.$route.name === "job--tasks") {
            fetchTasksJob();
          }
          if (this.$route.name === "Desk") {
            fetchMyTasks();
          }
          this.isLoading = false;
        });
    },
    getStyle(c) {
      if (c && c.charAt(0) !== "#") {
        c = "#" + c;
      }
      return "background-color: " + c + ";color:white;margin-right:6px;";
    },

    getContrastYIQ(hexcolor) {
      if (!hexcolor) return;
      hexcolor = hexcolor.replace("#", "");
      var r = parseInt(hexcolor.substr(0, 2), 16);
      var g = parseInt(hexcolor.substr(2, 2), 16);
      var b = parseInt(hexcolor.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? "#252631" : "#ffffff";
    },
  },
};
</script>

<style>
</style>
<template>
  <div class="card pt-2">
    <div
      class="flex flex-wrap sm:justify-content-between justify-content-center align-items-center pb-2"
    >
      <div class="mr-3">
        <Dropdown
          :showClear="true"
          :filter="true"
          class="filterDD"
          placeholder="All Departments"
          :options="departments"
          v-model="departmentFilter"
        >
          <template #indicator>
            <i class="la la-filter" />
          </template>
        </Dropdown>
      </div>
      <div class="flex">
        <div class="mr-3">
          <FilterSearchButton v-model="filters" />
        </div>
        <div class="mr-3">
          <Button
            label="New Role"
            @click="$store.dispatch('setSliderView', 'DepartmentRoleSlider')"
            icon="las la-plus"
          />
        </div>
      </div>
    </div>
    <div v-if="noSearch" class="flex">
      <div class="col font-light">No results for '{{ filters }}'</div>
    </div>
    <div
      :class="i != roles_department.length - 1 ? ' mb-7' : ''"
      v-for="(r, i) in filtered(roles_department)"
      :key="r.uid"
    >
      <DataTable
        v-if="
          (filters &&
            r.roles.filter(
              (a) =>
                a.role.title.toLowerCase().indexOf(filters.toLowerCase()) > -1
            ).length > 0) ||
          !filters
        "
        responsiveLayout="scroll"
        :rowClass="rowClass"
        :value="
          filters
            ? r.roles.filter(
                (a) =>
                  a.role.title.toLowerCase().indexOf(filters.toLowerCase()) > -1
              )
            : r.roles
        "
        editMode="row"
        filterDisplay="menu"
        dataKey="uid"
        :editingRows.sync="editingRows"
      >
        <template #empty>
          <div>Nothing yet...</div>
        </template>

        <Column
          :headerStyle="{ width: '30%' }"
          field="role.title"
          filterField="role.title"
          sortable
        >
          <template #header>
            <div class="p-column-title">{{ r.department_name }}</div>
          </template>
          <template #body="slotProps">
            <Skeleton width="30%" v-if="isLoading"></Skeleton>
            <template v-else>
              <span
                class="cursor-pointer"
                @click="
                  slotProps.data.department_uid = r.uid;
                  $store.dispatch('setSelectedObject', slotProps.data);
                  $store.dispatch('setSliderView', 'DepartmentRoleSlider');
                "
              >
                <i class="las la-user-tag mr-1"></i>
                {{ slotProps.data.role.title }}
              </span>
            </template>
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by role title"
            />
          </template>
          <template #editor="slotProps">
            <InputText
              placeHolder="Name"
              v-model="slotProps.data['role']['title']"
            />
          </template>
        </Column>
        <Column
          field="assigned"
          v-if="r.roles.length > 0"
          header="Assigned"
          sortable
        >
          <template #body="slotProps">
            <Skeleton width="15%" v-if="isLoading"></Skeleton>
            <template v-else>
              {{ slotProps.data.assigned }}
            </template>
          </template>
        </Column>
        <Column
          v-if="r.roles.length > 0"
          sortable
          field="active"
          header="Status"
          bodyStyle="transform: translateY(6px);"
        >
          <template #body="slotProps">
            <Skeleton
              width="4rem"
              height="2rem"
              borderRadius="16px"
              v-if="isLoading"
            ></Skeleton>
            <template v-else>
              <AActiveSwitch
                :ref="slotProps.data.role.uid"
                @change="saveRow(slotProps.data)"
                v-model="slotProps.data.role.active"
              />
            </template>
          </template>
        </Column>
        <Column bodyStyle="text-align:right" :styles="{ width: '1rem' }">
          <template #body="slotProps">
            <div
              class="flex justify-content-end align-items-center invisible cursor-pointer"
            >
              <template v-if="editingRow !== slotProps.data.uid">
                <i
                  @click="editRow(slotProps.data, r.roles)"
                  :class="getEditClass(rowInEdit)"
                ></i>
                <i
                  @click="
                    deletePrompt(
                      slotProps.data.role.title,
                      '/v1/roles/' + slotProps.data.uid
                    )
                  "
                  :class="getDeleteClass(rowInEdit)"
                />
              </template>
              <template v-else>
                <i
                  class="las la-check i-20 mr-3 grey"
                  @click="saveRow(slotProps.data)"
                />
                <i
                  class="las la-times i-20 grey"
                  @click="onRowEditCancel(slotProps.data, r.roles)"
                />
              </template>
            </div>
          </template>
        </Column>
      </DataTable>
      <Button
        v-if="!filters"
        label="New Role"
        class="p-button-text p-button-secondary"
        @click="
          $store.dispatch('setSliderView', 'DepartmentRoleSlider');
          $store.dispatch('setSelectedData', r);
        "
        icon="las la-plus"
      />
    </div>
  </div>
</template>
<script>
import {
  state,
  fetchIndustryRoles,
  fetchAccounts,
  fetchRolesDepartment,
} from "../../../services/data_service.js";
import { FilterMatchMode } from "primevue/api";
import Vue from "vue";
export default {
  data() {
    return {
      isLoading: true,
      originalRows: [],
      departmentFilter: null,
      departments: [],
      editingRows: [],
      editingRow: null,
      rowInEdit: false,
      filters: null,
    };
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
  },
  computed: {
    roles_department() {
      return state.rolesDepartment;
    },
    accounts() {
      return state.accounts;
    },
    noSearch() {
      if (this.filters) {
        var c = 0;

        this.roles_department.forEach((rd) => {
          rd.roles.forEach((role) => {
            if (
              role.role.title
                .toLowerCase()
                .indexOf(this.filters.toLowerCase()) > -1
            ) {
              c++;
            }
          });
        });

        if (c === 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  async mounted() {
    await this.loadData();
    this.isLoading = false;
  },
  methods: {
    getEditClass(t) {
      return t === true ? "hidden" : "las la-pen i-20 mr-3 grey";
    },
    getDeleteClass(t) {
      return t === true ? "hidden" : "las la-trash i-20 grey";
    },
    filtered(roles_department) {
      if (this.departmentFilter != null) {
        var arr = [];
        roles_department.forEach((a) => {
          if (a.department_name === this.departmentFilter) {
            arr.push(a);
          }
        });
        return arr;
      } else {
        return roles_department;
      }
    },
    rowClass() {
      return "row-accessories";
    },
    async loadData() {
      await fetchAccounts();
      await Promise.all([
        fetchIndustryRoles(this.accounts.industry.uid),
        fetchRolesDepartment(),
      ]);
      this.departments = this.roles_department.map((a) => a.department_name);
    },
    editRow(r, arr) {
      this.rowInEdit = true;
      this.editingRow = r.uid;
      this.editingRows.push(r);
      var index = arr.indexOf(r);
      var obj = JSON.parse(JSON.stringify(arr[index]));
      this.originalRows[index] = obj;
    },
    saveRow(r) {
      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API +
            "/v1/roles/departmentrole" +
            "/" +
            r.uid,
          JSON.stringify(r.role),
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          this.rowInEdit = false;
          this.editingRow = null;
          var i2 = this.editingRows.indexOf(r);
          this.editingRows.splice(i2, 1);
          this.$toast.add({
            severity: "success",
            summary: "Permission Updated",
            life: 3000,
          });

          this.isLoading = false;
        })
        .catch((error) => {
          this.$refs[r.role.uid][0].inputVal = this.updateInputSwitch(
            this.$refs[r.role.uid][0].value === 0 ? 1 : 0
          );

          this.isLoading = false;
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },

    onRowEditCancel(r, arr) {
      this.rowInEdit = false;
      var index = arr.indexOf(r);
      Vue.set(arr, index, this.originalRows[index]);
      this.editingRow = null;
      var i2 = this.editingRows.indexOf(r);
      this.editingRows.splice(i2, 1);
    },
  },
  metaInfo: {
    title: "Settings | Roles",

    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: "" },
      { name: "keywords", content: "" },
    ],
  },
};
</script>


<template>
  <div class="grid">
    <div
      class="col-12 flex sm:justify-content-start justify-content-center align-items-center pb-0"
    >
      <div
        class="p-3 mr-1 w-full flex sm:justify-content-start justify-content-center align-items-center"
      >
        <h1>Costings</h1>
      </div>
    </div>

    <div class="card col-12 pt-3">
      <div
        class="col-12 pb-3 flex sm:inline-flex flex-row align-items-center sm:justify-content-end justify-content-center"
      >
        <div class="mr-3">
          <FilterSearchButton />
        </div>
        <div class="mr-3">
          <Button
            label="Add Costing"
            icon="las la-plus"
            @click="
              $store.dispatch('setSelectedObject', null);
              $store.dispatch('setSliderView', 'JobCostingSlider');
            "
          />
        </div>
      </div>

      <DataTable
        responsiveLayout="scroll"
        :value="jobs"
        :rowClass="rowClass"
        editMode="row"
        dataKey="uid"
        @row-click="navJob"
        :paginator="true"
        :rows="30"
        :rowsPerPageOptions="[30, 50, 100]"
      >
        <template #empty> </template>
        <Column
          field="managed_by"
          style="min-width: 2.5rem"
          header="AE"
          filterField="managed_by.name"
        >
          <template #body="slotProps">
            <div v-if="isLoading">
              <Skeleton shape="circle" width="3rem" height="3rem" />
            </div>
            <Avatar
              v-else
              :image="slotProps.data[slotProps.column.field].profile_picture"
              :label="
                !slotProps.data[slotProps.column.field].profile_picture
                  ? getIntials(slotProps.data[slotProps.column.field])
                  : null
              "
              :style="{
                'background-color': !slotProps.data[slotProps.column.field]
                  .profile_picture
                  ? getColorFromName(slotProps.data[slotProps.column.field])
                  : 'none',
                color: '#ffffff',
              }"
              size="large"
              shape="circle"
            />
          </template>
        </Column>

        <Column field="title">
          <template #body="slotProps">
            <Skeleton v-if="isLoading"></Skeleton>
            <Skeleton class="mt-1" width="75%" v-if="isLoading"></Skeleton>
            <template v-else>
              <div class="cursor-pointer">
                {{ slotProps.data[slotProps.column.field] }}
              </div>
              <div class="font-light text-sm cursor-pointer">
                {{ slotProps.data.client.name }}
              </div>
            </template>
          </template>
        </Column>

        <Column field="job_number">
          <template #body="slotProps">
            <Skeleton v-if="isLoading"></Skeleton>
            <Skeleton class="mt-1" width="75%" v-if="isLoading"></Skeleton>
            <template v-else>
              <div class="font-light text-sm text-link-hover">
                {{ slotProps.data[slotProps.column.field] }}
              </div>
            </template>
          </template>
        </Column>
        <Column field="category" header="Category">
          <template #body="slotProps">
            <Skeleton v-if="isLoading"></Skeleton
            ><span v-else> {{ slotProps.data[slotProps.column.field] }}</span>
          </template>
        </Column>
        <Column field="created_date" header="Created">
          <template #body="slotProps">
            <Skeleton v-if="isLoading"></Skeleton>
            <Skeleton class="mt-1" width="75%" v-if="isLoading"></Skeleton>
            <template v-else>
              <div class="font-light text-sm text-link-hover">
                {{ slotProps.data[slotProps.column.field].nice }}
              </div>
            </template>
          </template>
        </Column>
        <Column field="budget" header="Amount">
          <template #body="slotProps">
            <template v-if="isLoading">
              <Skeleton class="mt-1"></Skeleton>
            </template>
            <template v-else>
              <div class="text-md">
                {{
                  formatCurrency(
                    slotProps.data[slotProps.column.field].amount,
                    setCurrencySymbol(slotProps.data)
                  )
                }}
              </div>
            </template>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import {
  state,
  fetchJobs,
  fetchClients,
} from "../../../services/data_service.js";

export default {
  data() {
    return {
      jobsLoading: [],
      isLoading: true,
    };
  },
  beforeDestroy() {
    this.$pusher.unsubscribe(this.account_uid);
    this.$bus.off("refreshData", this.loadData);
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    account_uid() {
      return this.user?.account_uid;
    },
    jobs() {
      return state.jobs.filter((job) => job.is_template === 0);
    },
    clients() {
      return state.clients;
    },
  },
  async mounted() {
    for (let index = 0; index < 5; index++) {
      this.jobsLoading.push({});
    }

    await this.loadData();

    this.subscribeChannel();
    // this.jobs.forEach(
    //   (job) => (job.date_due.date = new Date(job.date_due.date))
    // );
    this.isLoading = false;
  },
  methods: {
    async loadData() {
      await Promise.all([fetchJobs(0), fetchClients()]);
    },
    setCurrencySymbol(job) {
      let currency = null;
      if (this.clients) {
        for (let i = 0; i < this.clients.length; i++) {
          if (job.client.uid === this.clients[i].uid) {
            currency = this.clients[i].currency.description;
          }
        }
        return currency;
      }
      return null;
    },
    rowClass() {
      return "row-accessories row-accessories2";
    },
    subscribeChannel() {
      var channel = this.$pusher.subscribe(this.account_uid);
      channel.bind("job-costings", (data) => {
        fetchJobs(0);
      });
    },
    navJob(ev) {
      ev.data.job_from_costings = 1;
      this.$store.dispatch("setSelectedObject", ev.data);
      this.$router.push("/costing/" + ev.data.uid);
    },
  },
  metaInfo: {
    title: "Costings",
    meta: [{ vmid: "description", name: "description", content: "Jobs Page" }],
  },
};
</script>
<style scoped>
.text-link-hover:hover {
  color: #4d7cfe !important;
}
</style>
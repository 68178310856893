<template>
  <Sidebar
    :showCloseIcon="false"
    @hide="clear"
    :visible.sync="isVisible"
    position="right"
    :autoZIndex="false"
    style="z-index: 999"
  >
    <component v-if="componentName !== ''" :is="componentFile" />
  </Sidebar>
</template>
  
  <script>
export default {
  name: "EstimateCostTypeSlider",
  props: {
    componentName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    componentFile() {
      if (this.componentName == "") {
        return "";
      }
      return () => import("../components/" + this.componentName);
    },
  },
  mounted() {
    if (this.componentFile) {
      this.isVisible = true;
    }
  },
  watch: {
    componentName(n) {
      this.isVisible = n != "";
    },
  },
  methods: {
    logStepComponentData() {
      this.componentFile().then((a) => {});
    },
    clear() {
      this.$store.dispatch("setEstimateCostTypeSliderView", "");
    },
  },
};
</script>
<style scoped>
.p-sidebar-right {
  max-width: 677px !important;
}
</style>
  
  
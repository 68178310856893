<template>
  <div class="card pt-2">
    <div
      class="flex flex-wrap sm:justify-content-end justify-content-center align-items-center pb-2"
    >
      <div class="mr-3">
        <FilterSearchButton v-model="filters['global'].value" />
      </div>
      <div class="mr-3">
        <Button
          label="New Profile"
          @click="$store.dispatch('setSliderView', 'PermissionsSlider')"
          icon="las la-plus"
        />
      </div>
    </div>

    <div
      :class="i != profiles.length - 1 ? ' mb-7' : ''"
      v-for="(p, i) in profiles"
      :key="p.header"
    >
      <DataTable
        responsiveLayout="scroll"
        :rowClass="rowClass"
        :value="p.data"
        editMode="row"
        :filters.sync="filters"
        filterDisplay="menu"
        dataKey="uid"
        :editingRows.sync="editingRows"
      >
        <template #empty>
          <Button
            v-if="!filters['global'].value"
            label="New Profile"
            class="p-button-text p-button-secondary"
            @click="$store.dispatch('setSliderView', 'PermissionsSlider')"
            icon="las la-plus"
          />
          <div v-else>No Results</div></template
        >

        <Column
          field="name"
          filterField="name"
          sortable
          :styles="{ width: '33%' }"
        >
          <template #header>
            <div class="p-column-title">{{ p.header }}</div>
          </template>
          <template #body="slotProps">
            <Skeleton width="30%" v-if="isLoading"></Skeleton>
            <template v-else>
              <span
                class="cursor-pointer"
                @click="
                  $store.dispatch('setSelectedObject', slotProps.data);
                  $store.dispatch('setSliderView', 'PermissionsSlider');
                "
              >
                {{ slotProps.data.name }}
              </span>
            </template>
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by name"
            />
          </template>
        </Column>

        <Column field="assigned" header="Assigned" sortable>
          <template #body="slotProps">
            <Skeleton width="15%" v-if="isLoading"></Skeleton>
            <template v-else>
              <span
                class="cursor-pointer"
                @click="
                  $store.dispatch('setSelectedObject', slotProps.data);
                  $store.dispatch('setSliderView', 'PermissionsSlider');
                "
              >
                {{ slotProps.data.assigned }}
              </span>
            </template>
          </template>
        </Column>
        <Column
          bodyStyle="transform: translateY(7px)"
          field="active"
          header="Active"
        >
          <template #body="slotProps">
            <Skeleton
              width="4rem"
              height="2rem"
              borderRadius="16px"
              v-if="isLoading"
            ></Skeleton>
            <template v-else>
              <AActiveSwitch
                @change="saveRow(slotProps.data)"
                v-model="slotProps.data[slotProps.column.field]"
              />
            </template>
          </template>
        </Column>
        <Column bodyStyle="text-align:right" :styles="{ width: '1rem' }">
          <template #body="slotProps">
            <div
              class="flex justify-content-end align-items-center invisible cursor-pointer"
            >
              <template v-if="editingRow !== slotProps.data.uid">
                <i
                  @click="
                    $store.dispatch('setSelectedObject', slotProps.data);
                    $store.dispatch('setSliderView', 'PermissionsSlider');
                  "
                  class="las la-pen i-20 mr-3 grey"
                ></i>
                <i
                  v-tooltip.bottom="{
                    value: 'This is a default permission and cannot be deleted',
                    disabled: slotProps.data.default_permission === '0',
                  }"
                  :class="
                    'las la-trash i-20 ' +
                    (slotProps.data.default_permission === '1'
                      ? 'disable-grey cursor-disable'
                      : 'grey')
                  "
                  @click.stop="
                    slotProps.data.default_permission === '1'
                      ? () => {}
                      : deletePrompt(
                          slotProps.data.name,
                          '/v1/users/permissions/' + slotProps.data.uid
                        )
                  "
                />
              </template>
              <template v-else>
                <i
                  class="las la-check i-20 mr-3 grey"
                  @click="saveRow(slotProps.data)"
                />
                <i
                  class="las la-times i-20 grey"
                  @click="onRowEditCancel(slotProps.data, p.data)"
                />
              </template>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>

    <Button
      label="New Profile"
      class="p-button-text p-button-secondary mt-5"
      @click="$store.dispatch('setSliderView', 'PermissionsSlider')"
      icon="las la-plus"
    />
  </div>
</template>

<script>
import {
  state,
  fetchPermissionProfiles,
} from "../../../services/data_service.js";
import { FilterMatchMode } from "primevue/api";
import Vue from "vue";
export default {
  data() {
    return {
      isLoading: true,
      originalRows: [],
      editingRows: [],
      editingRow: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  computed: {
    permissions_profiles() {
      return state.permissionsProfiles;
    },
    profiles() {
      var users = [];

      var default_profiles = this.permissions_profiles.filter(
        (a) => a.default_permission == "1"
      );
      var custom_profiles = this.permissions_profiles.filter(
        (a) => a.default_permission == "0"
      );

      users.push({
        header: "Default",
        data: default_profiles,
      });
      users.push({
        header: "Custom",
        data: custom_profiles,
      });
      return users;
    },
  },
  created() {
    this.$bus.on("refreshData", fetchPermissionProfiles);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", fetchPermissionProfiles);
  },
  async mounted() {
    await fetchPermissionProfiles();
    this.isLoading = false;
  },

  methods: {
    rowClass() {
      return "row-accessories";
    },
    editRow(r, arr) {
      this.editingRow = r.uid;
      this.editingRows.push(r);
      var index = arr.indexOf(r);
      this.originalRows[index] = {
        ...arr[index],
      };
    },
    saveRow(r) {
      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API + "/v1/users/permissions" + "/" + r.uid,
          JSON.stringify(r),
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          this.editingRow = null;
          var i2 = this.editingRows.indexOf(r);
          this.editingRows.splice(i2, 1);
          this.$toast.add({
            severity: "success",
            summary: "Permission Updated",
            life: 3000,
          });

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },

    onRowEditCancel(r, arr) {
      var index = arr.indexOf(r);
      Vue.set(arr, index, this.originalRows[index]);
      this.editingRow = null;
      var i2 = this.editingRows.indexOf(r);
      this.editingRows.splice(i2, 1);
    },
  },
  metaInfo: {
    title: "Settings | Permissions",

    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: "" },
      { name: "keywords", content: "" },
    ],
  },
};
</script>

<style lang="scss" >
// table th {
//   background: none !important;
// }
</style>
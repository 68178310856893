var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex grid"},[_c('div',{staticClass:"col-12 lg:col-8",attrs:{"align":"left"}},[_c('div',{staticClass:"flex justify-content-between align-items-center"},[_vm._m(0),_c('div',{staticClass:"col flex justify-content-end"},[_c('div',{staticClass:"mr-3"},[_c('FilterSearchButton',{on:{"input":_vm.handleNotesSearch},model:{value:(_vm.noteSearchTerm),callback:function ($$v) {_vm.noteSearchTerm=$$v},expression:"noteSearchTerm"}})],1)])]),_c('div',{staticClass:"card"},[(_vm.notes && _vm.notes.length === 0)?_c('div',[_c('p',[_vm._v("No Notes Yet")])]):_c('div',_vm._l((_vm.notes),function(note){return _c('div',{key:note.uid},[_c('div',{staticClass:"flex align-items-center p-3 pt-1"},[_c('div',{staticClass:"flex-grow-0"},[(_vm.isLoading)?_c('div',[_c('Skeleton',{attrs:{"shape":"circle","width":"3rem","height":"3rem"}})],1):_c('Avatar',{style:({
                  'background-color': _vm.getColorFromName(note.user.name),
                  color: '#ffffff',
                }),attrs:{"image":note.user.profile_picture,"label":!note.user.profile_picture ? _vm.getIntials(note.user) : null,"size":"large","shape":"circle"}})],1),_c('div',{staticClass:"flex flex-column flex-grow-1 pl-4"},[_c('div',{staticClass:"flex flex-row justify-content-between"},[(_vm.isLoading)?_c('Skeleton',{attrs:{"width":"40%"}}):_c('div',[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(note.user.name))]),_vm._v(" commented on "),(note.task.title != '')?_c('span',{staticClass:"text-link cursor-pointer",on:{"click":function($event){return _vm.nav(note.task)}}},[_vm._v(_vm._s(note.task.task_type_name)+" - "+_vm._s(note.task.title))]):_c('span',{staticClass:"text-link cursor-pointer",on:{"click":function($event){return _vm.nav(note.task)}}},[_vm._v(_vm._s(note.task.task_type_name))])]),(_vm.isLoading)?_c('Skeleton',{attrs:{"width":"10%"}}):_c('div',{staticClass:"font-light text-sm"},[_vm._v(" "+_vm._s(_vm.formatDate(note.created_date.nice))+" ")])],1),_c('div',{staticClass:"pt-2"},[(_vm.isLoading)?_c('Skeleton',{attrs:{"width":"20%"}}):_c('span',{staticClass:"note-text"},[_vm._v(" "+_vm._s(note.note_text))])],1)])]),_vm._l((note.comments),function(c){return _c('div',{key:c.uid},[_c('div',{staticClass:"flex align-items-center p-3 ml-4 border-left"},[_c('div',{staticClass:"flex-grow-0"},[(_vm.isLoading)?_c('div',[_c('Skeleton',{attrs:{"shape":"circle","width":"3rem","height":"3rem"}})],1):_c('Avatar',{style:({
                    'background-color': _vm.getColorFromName(c.user.name),
                    color: '#ffffff',
                  }),attrs:{"image":c.user.profile_picture,"label":!c.user.profile_picture ? _vm.getIntials(c.user) : null,"size":"large","shape":"circle"}})],1),_c('div',{staticClass:"flex flex-column flex-grow-1 pl-4"},[_c('div',{staticClass:"flex flex-row justify-content-between"},[(_vm.isLoading)?_c('Skeleton',{attrs:{"width":"40%"}}):_c('div',[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(c.user.name))]),_vm._v(" replied ")]),(_vm.isLoading)?_c('Skeleton',{attrs:{"width":"10%"}}):_c('div',{staticClass:"font-light text-sm"},[_vm._v(" "+_vm._s(_vm.formatDate(c.created_date.nice))+" ")])],1),_c('div',{staticClass:"pt-2"},[(_vm.isLoading)?_c('Skeleton',{attrs:{"width":"20%"}}):_c('span',[_vm._v(" "+_vm._s(c.note_text))])],1)])])])})],2)}),0)])]),_c('div',{staticClass:"col-12 lg:col-4",attrs:{"align":"left"}},[_c('div',{staticClass:"flex justify-content-between align-items-center"},[_vm._m(1),_c('div',{staticClass:"col flex justify-content-end"},[_c('div',{staticClass:"mr-3"},[_c('FilterSearchButton',{on:{"input":_vm.onTaskDocumentSearchChange},model:{value:(_vm.documentSearchTerm),callback:function ($$v) {_vm.documentSearchTerm=$$v},expression:"documentSearchTerm"}})],1)])]),_c('div',{staticClass:"card"},[(_vm.documents == undefined)?_c('div',[_c('p',[_vm._v("No Documents Yet")])]):_c('div',_vm._l((_vm.documents),function(document){return _c('div',{key:document.uid},[_c('div',{staticClass:"align-items-center p-3 pt-1"},[(_vm.isLoading)?_c('Skeleton',{attrs:{"height":"50px"}}):_c('div',{staticClass:"flex-grow-0"},[_c('Attachment',{attrs:{"file":document}})],1)],1)])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('div',{staticClass:"cursor-pointer headerHover font-light"},[_c('span',[_vm._v("Job Notes")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('div',{staticClass:"cursor-pointer headerHover font-light"},[_c('span',[_vm._v("Task Documents")])])])
}]

export { render, staticRenderFns }
<template>
  <div class="card col-12 pt-1 md:pt-3">
    <div class="flex flex-wrap overflow-x-scroll justify-content-center sm:justify-content-between">
      <div class="hidden md:flex text-center">
        <div
          v-for="category in categories"
          :key="category.type"
          :class="getCategoryClass(category)"
          @click="filter(category.type)"
        >
          {{ category.label }}
          <Badge
            v-if="isLoading === false"
            :style="getBadgeStyle(category)"
            class="ml-2 hidden md:block"
            :value="getBadgeValue(category)"
          />
          <Badge
            v-else
            style="background-color: #e8ecef"
            class="ml-2"
          />
        </div>
      </div>

      <div class="text-center hidden md:flex">
        <div class="mr-3">
          <FilterSearchButton v-model="tableFilters['global'].value" />
        </div>
      </div>
    </div>
    <Dropdown
      class="w-full mb-2 md:hidden"
      :options="categories"
      v-model="selectedCategory"
      optionLabel="label"
      optionValue="type"
      placeholder="Filter by category"
    />

    <div>
      <DataTable
        responsiveLayout="scroll"
        :value="filterJobs()"
        :rowClass="rowClass"
        editMode="row"
        dataKey="uid"
        filterDisplay="menu"
        :filters.sync="tableFilters"
        @row-click="navJob"
        :globalFilterFields="[
          'client.name',
          'title',
          'job_number',
          'managed_by.name',
        ]"
        :paginator="true"
        :rows="30"
        :rowsPerPageOptions="[30, 50, 100]"
      >
        <template #empty>
          <Button
            :label="!showTemplates ? 'New Job' : 'New Template'"
            @click="
              $store.dispatch('setSelectedObject', null);
              $store.dispatch(
                'setSliderView',
                !showTemplates ? 'JobSlider' : 'JobTemplateSlider'
              );
            "
            class="p-button-text p-button-secondary"
            icon="las la-plus"
          />
        </template>

        <Column
          field="job_number"
          sortable
          header="No"
          filterField="job_number"
          :styles="{ whiteSpace: 'nowrap' }"
        >
          <template #body="slotProps">
            <Skeleton
              v-if="isLoading"
              width="75%"
            ></Skeleton>
            <template v-else>
              <div class="font-light-hover cursor-pointer">
                {{ slotProps.data?.job_number }}
              </div>
            </template>
          </template>
        </Column>

        <Column
          :styles="{
            width: '10px',
          }"
          field="client.name"
          filterField="client.uid"
          sortable
          :header="showTemplates ? 'Template Name' : 'Client'"
          filter
        >
          <template #filter="{ filterModel }">
            <Dropdown
              v-model="filterModel.value"
              :options="clientOptions"
              optionLabel="name"
              optionValue="uid"
              placeholder="Select Client"
            />
          </template>
          <template #body="slotProps">
            <Skeleton
              v-if="isLoading"
              width="75%"
            ></Skeleton>
            <Skeleton
              class="mt-1"
              width="75%"
              v-if="isLoading"
            ></Skeleton>
            <template v-else>
              <div
                class="cursor-pointer"
                v-if="!showTemplates"
                :title="slotProps.data?.client?.name"
                style="
                  max-width: 300px;
                  overflow-x: clip;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
                {{ slotProps.data?.client?.name }}
              </div>
            </template>
          </template>
        </Column>

        <Column
          field="title"
          sortable
          :styles="{
            width: '10px',
          }"
          :header="showTemplates ? 'Template Name' : 'Job'"
        >
          <template #body="slotProps">
            <Skeleton
              v-if="isLoading"
              width="75%"
            ></Skeleton>
            <Skeleton
              class="mt-1"
              width="75%"
              v-if="isLoading"
            ></Skeleton>
            <template v-else>
              <div
                class="cursor-pointer vertical-middle"
                style="
                  max-width: 300px;
                  overflow-x: clip;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
                <span
                  v-if="hasAnyFilters"
                  class="text-10 mr-2 p-1"
                  :style="
                    slotProps.data.status == 'active'
                      ? 'background: rgb(244, 251, 239) !important; color: rgb(109, 210, 48) !important; border-radius: 6px;'
                      : 'background: #E8ECEF !important;borderRadius: 6px;'
                  "
                >
                  {{ toTitleCase(slotProps.data.status) }}</span> {{ slotProps.data[slotProps.column.field] }}

              </div>
            </template>
          </template>
        </Column>

        <Column
          v-if="!showTemplates"
          field="managed_by.name"
          filterField="managed_by.name"
          sortable
          :styles="{ whiteSpace: 'nowrap' }"
          header="AE"
          filter
        >
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by name"
            />
          </template>
          <template #body="slotProps">
            <div class="flex">
              <Skeleton
                v-if="isLoading"
                width="75%"
              ></Skeleton>

              <template v-else>
                <div class="cursor-pointer">
                  {{ slotProps.data.managed_by.name }}
                </div>
              </template>
            </div>
          </template>
        </Column>
        <Column
          sortable
          :styles="{ whiteSpace: 'nowrap' }"
          field="metrics.time.tracked"
          v-if="!showTemplates"
          header="Progress"
        >
          <template #body="slotProps">
            <template v-if="isLoading">
              <Skeleton width="15%"></Skeleton>
              <Skeleton
                class="mt-1"
                width="30%"
              ></Skeleton>
              <Skeleton class="mt-1"></Skeleton>
            </template>
            <template v-else>
              <div class="text-md">
                {{
                  slotProps.data?.metrics.time.tracked
                    ? slotProps.data?.metrics.time.tracked
                    : "00:00"
                }}
                /
                {{
                  slotProps.data?.metrics.time.estimated
                    ? slotProps.data?.metrics.time.estimated
                    : "00:00"
                }}
              </div>
              <div class="flex align-items-center mt-1">
                <div
                  class="surface-300 border-round overflow-hidden w-full"
                  style="height: 6px"
                >
                  <div
                    class="h-full"
                    :style="getProgressStyle(slotProps.data)"
                  />
                </div>
              </div>
            </template>
          </template>
        </Column>
        <Column
          v-if="!showTemplates"
          sortable
          field="dueDate"
          header="Due"
          filterField="dueDate"
          dataType="date"
          :filterMenuStyle="{ width: '10rem' }"
          :styles="{ whiteSpace: 'nowrap' }"
          filter
        >
          <template #filter="{ filterModel }">
            <Calendar
              v-model="filterModel.value"
              dateFormat="yy-mm-dd"
              placeholder="yyyy-mm-dd"
            />
          </template>
          <template #body="slotProps">
            <template v-if="isLoading">
              <Skeleton width="35%"></Skeleton>
              <Skeleton
                class="mt-1"
                width="20%"
              ></Skeleton>
            </template>
            <template v-else>
              <div>
                {{ formatDate(slotProps.data.date_due?.date) }}
              </div>
              <div class="flex align-items-center">
                <div :class="
                    'date-range-dot ' +
                    (slotProps.data?.date_due?.tag_class === 'green'
                      ? 'green-active'
                      : slotProps.data?.date_due?.tag_class)
                  "></div>
                <span class="font-light pl-1 text-sm">
                  {{ slotProps.data?.date_due?.time_until }}
                </span>
              </div>
            </template>
          </template>
        </Column>
        <Column
          field="metrics"
          v-if="!showTemplates"
        >
          <template #body="slotProps">
            <div
              v-if="slotProps.data.notifications > 0"
              class="flex justify-content-end align-items-center cursor-pointer"
            >
              <i
                :class="'las la-comments'"
                v-if="slotProps.data.notifications > 0"
                @click="navMessages(slotProps.data)"
                style="
                  font-size: 14px;
                  background-color: #6dd230;
                  color: white;
                  border-radius: 50%;
                  padding: 3px;
                "
              ></i>
            </div>
          </template>
        </Column>
        <Column bodyStyle="text-align:right">
          <template #body="slotProps">
            <div class="flex justify-content-end align-items-center invisible cursor-pointer">
              <i
                v-if="showTemplates"
                @click.stop="navEditTemplateMode(slotProps.data)"
                class="las la-suitcase i-20 grey pr-1"
              />
              <i
                @click.stop="navEditMode(slotProps.data)"
                class="las la-pen i-20 grey pr-1"
              />
              <i
                @click.stop="
                  deletePrompt(
                    slotProps.data?.title,
                    '/v1/jobs/' + slotProps.data?.uid
                  )
                "
                class="las la-trash i-20 grey"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { state, fetchJobs } from "../../../services/data_service.js";
import { FilterMatchMode } from "primevue/api";

export default {
  data() {
    return {
      jobsLoading: [],
      categories: [
        { type: "active", label: "Active", color: "#6dd230" },
        { type: "draft", label: "Draft", color: "#e8ecef" },
        // { type: "hasActive", label: "Has Active Tasks", color: "#ffab2b" },
        { type: "closed", label: "Closed", color: "#e8ecef" },
        // { type: "archived", label: "Archived", color: "#e8ecef" },
      ],
      isLoading: true,
      showTemplate: false,
      showTemplates: false,
      selectedCategory: null,
      statusOptions: [
        { label: "Active", value: "active" },
        { label: "Draft", value: "draft" },
        { label: "Closed", value: "closed" },
        { label: "Archived", value: "archived" },
        { label: "Accepted", value: "accepted" },
      ],
      tableFilters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        category: { value: null, matchMode: FilterMatchMode.CONTAINS },
        title: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "managed_by.name": { value: null, matchMode: FilterMatchMode.CONTAINS },
        "client.uid": { value: null, matchMode: FilterMatchMode.EQUALS },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        dueDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
      },
      filters: [],
    };
  },
  beforeDestroy() {
    this.$pusher.unsubscribe(this.account_uid);
    this.$bus.off("refreshData", this.loadData);
  },
  computed: {
    clientOptions() {
      return this.jobs
        .map((job) => job.client)
        .filter(
          (value, index, self) =>
            self.findIndex((t) => t.uid === value.uid) === index
        );
    },
    user() {
      return this.$store.getters.user;
    },
    account_uid() {
      return this.user?.account_uid;
    },
    jobs() {
      if (this.showTemplates !== true) {
        return state.jobs.filter((job) => job.is_template === 0);
      }
      return state.jobs.filter((job) => job.is_template === 1);
    },
    filteredJobs() {
      return this.filterJobs();
    },
    jobCounts() {
      const counts = {
        draft: 0,
        closed: 0,
        archived: 0,
        active: 0,
        accepted: 0,
      };

      this.jobs.forEach((job) => {
        if (job.status === "draft") counts.draft++;
        if (job.status === "closed") counts.closed++;
        if (job.status === "archived") counts.archived++;
        if (job.status === "active") counts.active++;
        if (job.accepted === 1) counts.accepted++;
      });

      return counts;
    },

    draft() {
      return this.jobCounts.draft;
    },

    closed() {
      return this.jobCounts.closed;
    },

    archived() {
      return this.jobCounts.archived;
    },

    active() {
      return this.jobCounts.active;
    },

    accepted() {
      return this.jobCounts.accepted;
    },
    hasAnyFilters() {
      return Object.values(this.tableFilters).some(
        (filter) => filter.value !== null
      );
    },
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },

  async mounted() {
    this.filter("active");
    for (let index = 0; index < 5; index++) {
      this.jobsLoading.push({});
    }

    this.subscribeChannel();

    if (this.$store.getters.jobsFilters) {
      this.tableFilters = JSON.parse(this.$store.getters.jobsFilters);
    }

    await this.loadData();
    this.isLoading = false;
  },
  methods: {
    navMessages(ev) {
      this.$store.dispatch("setSelectedObject", ev);
      this.$router.push("/job/" + ev.uid + "/documents");
    },
    getCategoryClass(category) {
      return `flex align-items-center p-3 mr-3 font-light cursor-pointer ${this.getBottomBorderStyle(
        category
      )}`;
    },
    getBottomBorderStyle(category) {
      return this.filters.indexOf(category.type) >= 0
        ? this.getBottomBorderPositiveClass(category)
        : "bottom-border-invisible";
    },
    getBottomBorderPositiveClass(category) {
      return category.type === "active"
        ? "bottom-border-positive"
        : category.type === "accepted"
        ? "bottom-border-positive"
        : "bottom-border-gray";
    },
    getBadgeStyle(category) {
      return `background-color: ${category.color}`;
    },
    getBadgeValue(category) {
      const value = this[category.type]; // Assuming your data properties match the category types
      return value !== null ? value : 0;
    },
    async loadData() {
      await fetchJobs();
      this.jobs.forEach((job) => {
        job.date_due.date = new Date(job.date_due.date);
        job.dueDate = new Date(job.date_due.date);
      });
      if (this.showTemplates) {
        this.showTemplate = this.showTemplates;
      }
    },
    setCurrencySymbol(job) {
      let currency = null;
      if (this.clients) {
        for (let i = 0; i < this.clients.length; i++) {
          if (job.client.uid === this.clients[i].uid) {
            currency = this.clients[i].currency.description;
          }
        }
        return currency;
      }
      return null;
    },
    show() {
      this.$store.dispatch("setShowTemplates", this.showTemplate);
    },
    getProgressStyle(val) {
      if (val.metrics.time.tracked === null) {
        return;
      }

      var estimated =
        val.metrics.time.estimated !== null
          ? val.metrics.time.estimated
          : "00:00";

      var progress =
        (this.timeToSeconds(val.metrics.time.tracked) /
          this.timeToSeconds(estimated)) *
        100;

      if (progress >= 100) {
        return "background: #fe4d97;width:100%";
      } else if (progress >= 60) {
        return "background: #ffab2b;width:" + progress + "%";
      } else {
        return "background: #6dd230;width:" + progress + "%";
      }
    },
    filterJobs() {
      let arr = this.jobs;

      if (this.isLoading && this.jobs.length === 0) {
        return this.jobsLoading;
      }

      // Apply category filters
      if (this.filters.length > 0 || this.selectedCategory) {
        const activeFilters =
          this.filters.length > 0 ? this.filters : [this.selectedCategory];
        arr = arr.filter(
          (job) =>
            (activeFilters.includes("active") && job.status === "active") ||
            (activeFilters.includes("draft") && job.status === "draft") ||
            (activeFilters.includes("closed") && job.status === "closed") ||
            (activeFilters.includes("archived") && job.status === "archived") ||
            (activeFilters.includes("accepted") && job.accepted === 1)
        );
      }

      // Apply client filter
      if (this.tableFilters["client.uid"].value) {
        arr = arr.filter(
          (job) => job.client.uid === this.tableFilters["client.uid"].value
        );
      }

      // Apply managed_by filter
      if (this.tableFilters["managed_by.name"].value) {
        arr = arr.filter((job) =>
          job.managed_by.name
            .toLowerCase()
            .includes(this.tableFilters["managed_by.name"].value.toLowerCase())
        );
      }

      // Apply due date filter
      if (this.tableFilters["dueDate"].value) {
        arr = arr.filter(
          (job) =>
            new Date(job.dueDate).toDateString() ===
            new Date(this.tableFilters["dueDate"].value).toDateString()
        );
      }

      // Apply global search filter
      if (this.tableFilters.global.value) {
        const searchValue = this.tableFilters.global.value.toLowerCase();
        arr = arr.filter(
          (job) =>
            job.client.name.toLowerCase().includes(searchValue) ||
            job.title.toLowerCase().includes(searchValue) ||
            job.job_number.toLowerCase().includes(searchValue) ||
            job.managed_by?.name.toLowerCase().includes(searchValue)
        );
      }

      return arr;
    },
    rowClass() {
      return "row-accessories";
    },
    subscribeChannel() {
      var channel = this.$pusher.subscribe(this.account_uid);
      channel.bind("job", (data) => {
        this.loadData();
      });
    },
    navJob(ev) {
      const route = "/job/" + ev.data?.uid;
      if (ev.originalEvent.ctrlKey || ev.originalEvent.metaKey) {
        // Ctrl key (or Cmd key on Mac) is pressed, open in new tab
        const url = this.$router.resolve(route).href;
        window.open(url, "_blank");
      } else {
        // Normal click, navigate in the same tab
        this.$store.dispatch("setSelectedObject", ev.data);
        this.$router.push(route);
      }
    },
    formatDate(date) {
      if (date instanceof Date) {
        let month = date.getMonth() + 1;
        let day = date.getDate();

        if (month < 10) {
          month = "0" + month;
        }

        if (day < 10) {
          day = "0" + day;
        }

        return date.getFullYear() + "-" + month + "-" + day;
      }
      return date;
    },
    filter(f) {
      if (this.filters.indexOf(f) >= 0) {
        this.filters = this.filters.filter((item) => item !== f);
      } else {
        this.filters = [f];
      }
    },
    calcSpend(obj) {
      if (!obj.budget.amount || !obj.budget.spend) {
        return 0;
      }

      return ((obj.budget.spend / obj.budget.amount) * 100).toFixed(0);
    },
    navEditMode(ev) {
      this.$store.dispatch("setSelectedObject", ev);
      this.$store.dispatch("setSliderView", "JobSlider");
    },
    navEditTemplateMode(ev) {
      ev.job_from_template = 1;
      this.$store.dispatch("setSelectedObject", ev);
      this.$store.dispatch("setSliderView", "JobSlider");
    },
  },
  metaInfo: {
    title: "Jobs",
    meta: [{ vmid: "description", name: "description", content: "Jobs Page" }],
  },
  watch: {
    "tableFilters.global": {
      handler(newFilters, oldFilters) {
        if (newFilters.value) {
          this.filters = [];
        }

        if (!newFilters.value && !oldFilters.value) {
          this.filter("active");
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.text-link-hover:hover {
  color: #4d7cfe !important;
}
</style>

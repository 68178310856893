<template>
  <div
    class="flex flex-column justify-content-center align-items-center relative"
  >
    <div class="flex mt-5">
      <label class="font-light absolute top-0">Logo</label>
      <img
        v-if="inputVal"
        :src="inputVal"
        @error="inputVal = user.profile_picture"
        class="profile-preview"
      />
    </div>

    <div class="flex" style="margin-top: -3%">
      <ProgressSpinner
        v-if="spinnerLoading"
        style="width: 40px; height: 40px"
        animationDuration=".75s"
        strokeWidth="4"
        class="z-index:5"
        id="spinner"
      />
      <Button
        v-else
        @click="uploadProfile"
        icon=" las la-pen   i-20"
        class="p-button-lg p-button-rounded grey-button"
      />
    </div>

    <file-pond
      style="display: none"
      name="profile"
      ref="pond"
      label-idle="Upload Profile picture"
      :allow-multiple="false"
      accepted-file-types="image/jpeg, image/png"
      :files="myFiles"
      @init="handleFilePondInit"
      @processfile="handleProcessFile"
      @processfilestart="processfilestart"
    />
  </div>
</template>

<script>
import store from "@/store";
import vueFilePond, { setOptions } from "vue-filepond";
setOptions({
  server: {
    url: process.env.VUE_APP_UPLOAD_URL,
    timeout: 7000,
    process: {
      url: "./v1/users/upload",
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.token,
      },
    },
  },
});
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType
  //FilePondPluginImagePreview
);
export default {
  data() {
    return {
      spinnerLoading: false,
      myFiles: [],
    };
  },
  props: {
    value: {
      default: null,
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    user() {
      return this.$store.getters.user;
    },
  },
  components: { FilePond },
  methods: {
    processfilestart() {
      this.spinnerLoading = true;
    },
    uploadProfile() {
      this.$refs.pond.browse();
    },
    handleProcessFile(error, file) {
      if (error) {
        this.spinnerLoading = false;
        return;
      }
      const response = JSON.parse(file.serverId);
      this.profilePicture = response.data;
      this.inputVal = response.data.url;
      this.$emit("uploaded");
      this.spinnerLoading = false;
    },
    handleFilePondInit() {
      this.$refs.pond.getFiles();
    },
  },
};
</script>
<style lang="scss" scoped>
.profile-preview {
  height: 180px;
  width: 180px;
  border-radius: 100%;
  object-fit: contain !important;
  border: 1px solid #ced4da;
}
/* error state color */
[data-filepond-item-state*="error"] .filepond--item-panel,
[data-filepond-item-state*="invalid"] .filepond--item-panel {
  background-color: #fe4d97;
}

[data-filepond-item-state="processing-complete"] .filepond--item-panel {
  background-color: #6dd230;
}

.floating-profile-edit {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 168px;
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{directives:[{name:"form-enter",rawName:"v-form-enter"}],staticStyle:{"width":"250px"},on:{"submit":function($event){$event.preventDefault();return _vm.submitHandler.apply(null, arguments)}}},[(_vm.isLoading)?_c('RocketLoad'):[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"flex justify-content-center"},[_c('Chip',{staticClass:"mr-3 mb-2 tag-chip",style:(_vm.getStyle(_vm.tag.colour))},[_c('div',{staticClass:"flex p-2"},[_vm._v(_vm._s(_vm.tag.name))])])],1),_c('span',{staticClass:"w-full p-input-icon-right"},[_c('div',[_c('InputText',{staticClass:"w-full px-0 border-x-none border-top-none border-noround",attrs:{"placeHolder":"Name tag"},model:{value:(_vm.tag.name),callback:function ($$v) {_vm.$set(_vm.tag, "name", $$v)},expression:"tag.name"}})],1),_c('i',{staticClass:"pi pi-times cursor-pointer",on:{"click":_vm.clearInput}})])]),(_vm.submitted && !_vm.$v.tag.name.required)?_c('div',{staticClass:"error-validation-text pt-1"},[_vm._v(" Tag name is required ")]):_vm._e(),(_vm.submitted && !_vm.$v.tag.colour.required)?_c('div',{staticClass:"error-validation-text pt-1"},[_vm._v(" Tag colour is required ")]):_vm._e(),_c('div',{staticClass:"container"},_vm._l((_vm.presetColors),function(colour){return _c('div',{key:colour.id,staticClass:"cursor-pointer",staticStyle:{"width":"fit-content","block-size":"fit-content","padding":"10px 0"},on:{"click":function($event){_vm.tag.colour = colour.colour}}},[_c('div',{style:({
            backgroundColor: `#${colour.colour}`,
            height: '24px',
            width: '24px',
            borderRadius: '50%',
            opacity: '0.75',
          })})])}),0),_c('div',{staticClass:"flex justify-content-between"},[_c('Button',{attrs:{"type":"submit"}},[_vm._v("Save ")]),_c('Button',{staticClass:"p-button-text p-button-secondary",on:{"click":_vm.dismiss}},[_vm._v(" CANCEL ")])],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
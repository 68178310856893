<template>
  <div class="m-r-24 m-t-6 m-b-6">
    <label
      v-if="heading"
      class="agencydesk-input-label"
    >{{ heading }}</label>
    <div>
      <Dropdown
        class="agencydesk-dropdown m-l-0 m-12"
        v-model="inputVal"
        :options="options"
        :dataKey="dataKey"
        :optionLabel="optionLabel"
        :placeholder="placeholder"
        :showClear="showClear"
        :filter="filter"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    showClear: {
      type: Boolean,
    },
    filter: {
      type: Boolean,
    },
    dataKey: {
      type: String,
    },
    optionLabel: {
      type: String,
    },
    options: {
      type: Array,
    },
    value: {
      default: null,
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style>
</style>
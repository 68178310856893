<template>
  <div class="task-entry">
    <div class="this-title">
      {{ task.email }}
    </div>
    <div class="this-description">
      {{ task.name }}
    </div>
    <div class="this-email">
      {{ task.user_email }}
    </div>
    <div class="this-duedate">
      {{ task.created_date }}
    </div>
  </div>
</template>
<script>
export default {
  name: "UserEntry",
  props: {
    task: Object,
  },
};
</script>
<template>
  <div class="container-padding" align="left">
    <!-- <div
      class="flex flex-row"
      v-if="this.$route.path !== '/finance/estimate'"
    >
      <router-link
        v-for="r in path"
        :key="r.path"
        :to="{ path: r.route }"
      >
        <h6
          :class="'mr-6 p-b-12'"
          :style="'color: grey;' + getHeaderStyle(r.route)"
        >
          {{ r.name }}
        </h6>
      </router-link>
    </div> -->

    <transition name="slide-fade">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      path: [
        {
          route: "/finance/estimates",
          name: "COST ESTIMATES",
        },
        {
          route: "/finance/invoices",
          name: "INVOICES",
        },
        {
          route: "/finance/purchase-orders",
          name: "PURCHASE ORDERS",
        },
        {
          route: "/finance/quote-requests",
          name: "QUOTE REQUESTS",
        },
      ],
    };
  },
  created() {},

  methods: {
    getHeaderStyle(route) {
      if (this.$route.path == route) {
        return "font-weight: bold; border-bottom: 2px solid #4D7CFE; color: #4D7CFE";
      }
    },
    getHeader() {
      return "Finance";
    },
  },

  computed: {},
  mounted() {},

  name: "Finance",
  components: {},
};
</script>
<template>
  <ul v-if="items">
    <template v-for="(item, i) of items">
      <li
        v-if="visible(item) && !item.separator"
        v-can="item.permission"
        :key="item.label || i"
        :class="[
          {
            'layout-menuitem-category': root,
            'active-menuitem': activeIndex === i && !item.to && !item.disabled,
          },
        ]"
        role="none"
      >
        <template v-if="root && item.to && item.items">
          <div
            :style="item.items ? 'cursor:pointer;' : 'padding-bottom:0px'"
            class="layout-menuitem-root-text justify-content-between"
            :aria-label="item.label"
            @click="handleRootClick(item)"
            style="padding-top: 10px; padding-bottom: 10px"
          >
            <div class="flex no-wrap">
              <span v-if="!item.icon && staticMenuInactive"
                >{{ item.label[0] }}{{ item.label[1] }}</span
              >
              <i v-else :class="item.icon" />
              <span
                v-if="!staticMenuInactive"
                class="menu-label"
                :style="
                  item.icon ? 'margin-left: 0.8rem;padding-top: 4px;' : ''
                "
                >{{ item.label }}
              </span>
            </div>
            <span style="padding-top: 0.5rem">
              <i
                v-if="item.items && !isExpand(item)"
                class="pi pi-fw pi-angle-down menuitem-toggle-icon" />
              <i
                v-if="item.items && isExpand(item)"
                class="pi pi-fw pi-angle-up menuitem-toggle-icon"
            /></span>
          </div>
          <transition-expand>
            <appsubmenu
              v-if="isExpand(item)"
              :items="visible(item) && item.items"
              @menuitem-click="$emit('menuitem-click', $event)"
          /></transition-expand>
        </template>
        <template v-else-if="root && !item.to">
          <div
            :style="
              item.items
                ? 'cursor:pointer;padding-bottom:1rem'
                : 'padding-bottom:0px'
            "
            class="layout-menuitem-root-text justify-content-between"
            :aria-label="item.label"
            @click="handleRootClick(item)"
          >
            <div class="flex no-wrap">
              <span v-if="!item.icon && staticMenuInactive"
                >{{ item.label[0] }}{{ item.label[1] }}</span
              >
              <i v-else :class="item.icon" />
              <span
                v-if="!staticMenuInactive"
                class="menu-label"
                :style="
                  item.icon ? 'margin-left: 0.8rem;padding-top: 4px;' : ''
                "
                >{{ item.label }}
              </span>
            </div>
            <span style="padding-top: 0.5rem">
              <i
                v-if="item.items && !isExpand(item)"
                class="pi pi-fw pi-angle-down menuitem-toggle-icon" />
              <i
                v-if="item.items && isExpand(item)"
                class="pi pi-fw pi-angle-up menuitem-toggle-icon"
            /></span>
          </div>
          <transition-expand>
            <appsubmenu
              v-if="isExpand(item)"
              :items="visible(item) && item.items"
              @menuitem-click="$emit('menuitem-click', $event)"
          /></transition-expand>
        </template>
        <template v-else>
          <router-link
            v-if="item.to"
            :to="item.to"
            :style="getLinkStyle(item.icon)"
            :class="[
              item.class,
              'p-ripple',
              { 'p-disabled': item.disabled },
              checkActive(item),
            ]"
            @click="onMenuItemClick($event, item, i)"
            :target="item.target"
            :aria-label="item.label"
            role="menuitem"
            v-ripple
          >
            <span
              style="margin-left: 5px"
              v-if="!item.icon && staticMenuInactive"
              >{{ getLabelIcon(item.label) }}</span
            >
            <i v-else :class="item.icon" />
            <span class="menu-label"
              ><template
                v-if="(!item.icon && !staticMenuInactive) || item.icon"
                >{{ item.label }}</template
              ></span
            >
            <i
              v-if="item.items"
              class="pi pi-fw pi-angle-down menuitem-toggle-icon"
            />
            <Badge v-if="item.badge" :value="item.badge" />
          </router-link>

          <transition name="layout-submenu-wrapper">
            <appsubmenu
              v-show="activeIndex === i"
              :items="visible(item) && item.items"
              @menuitem-click="$emit('menuitem-click', $event)"
            />
          </transition>
        </template>
      </li>

      <li
        class="p-menu-separator"
        :style="item.style"
        v-if="visible(item) && item.separator"
        :key="'separator' + i"
        role="separator"
      />
    </template>
  </ul>
</template>
<script>
export default {
  name: "Appsubmenu",
  props: {
    items: Array,
    root: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeIndex: null,
      expand: [],
    };
  },
  computed: {
    staticMenuInactive() {
      return this.$store.getters.staticMenuInactive;
    },
  },
  methods: {
    getLinkStyle(val) {
      var style = "";
      if (this.staticMenuInactive === true) {
        style = "margin-right:13px";
      } else {
        style = "margin-right:15px";
      }

      if (!val && !this.staticMenuInactive) {
        style += ";margin-left:15px";
      }
      return style;
    },
    getLabelIcon(val) {
      var arr = val.split(" ");

      if (arr.length > 1) {
        return arr[0][0] + "" + arr[1][0];
      } else {
        return arr[0][0] + arr[0][1];
      }
    },
    checkActive(item) {
      if (
        item.to != "/" &&
        (this.$route.path.indexOf(item.to) >= 0 ||
          this.$route.path.indexOf(item.to.slice(item.to, -1)) >= 0)
      ) {
        return "router-link-exact-active";
      }
    },
    isExpand(item) {
      return this.expand.includes(item);
    },
    handleRootClick(item) {
      if (item.to) {
        if (item.items.length > 0) {
          this.$router.push(item.items[0].to);
        } else {
          this.$router.push(item.to);
        }
      }
      var index = this.expand.indexOf(item);
      if (index < 0) {
        this.expand.push(item);
      } else {
        this.expand.splice(index, 1);
      }
    },
    onMenuItemClick(event, item, index) {
      if (item.disabled) {
        event.preventDefault();
        return;
      }

      if (!item.to && !item.url) {
        event.preventDefault();
      }

      //execute command
      if (item.command) {
        item.command({ originalEvent: event, item: item });
      }

      this.activeIndex = index === this.activeIndex ? null : index;

      this.$emit("menuitem-click", {
        originalEvent: event,
        item: item,
      });
    },
    visible(item) {
      return typeof item.visible === "function"
        ? item.visible()
        : item.visible !== false;
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.items)?_c('ul',[_vm._l((_vm.items),function(item,i){return [(_vm.visible(item) && !item.separator)?_c('li',{directives:[{name:"can",rawName:"v-can",value:(item.permission),expression:"item.permission"}],key:item.label || i,class:[
        {
          'layout-menuitem-category': _vm.root,
          'active-menuitem': _vm.activeIndex === i && !item.to && !item.disabled,
        },
      ],attrs:{"role":"none"}},[(_vm.root && item.to && item.items)?[_c('div',{staticClass:"layout-menuitem-root-text justify-content-between",staticStyle:{"padding-top":"10px","padding-bottom":"10px"},style:(item.items ? 'cursor:pointer;' : 'padding-bottom:0px'),attrs:{"aria-label":item.label},on:{"click":function($event){return _vm.handleRootClick(item)}}},[_c('div',{staticClass:"flex no-wrap"},[(!item.icon && _vm.staticMenuInactive)?_c('span',[_vm._v(_vm._s(item.label[0])+_vm._s(item.label[1]))]):_c('i',{class:item.icon}),(!_vm.staticMenuInactive)?_c('span',{staticClass:"menu-label",style:(item.icon ? 'margin-left: 0.8rem;padding-top: 4px;' : '')},[_vm._v(_vm._s(item.label)+" ")]):_vm._e()]),_c('span',{staticStyle:{"padding-top":"0.5rem"}},[(item.items && !_vm.isExpand(item))?_c('i',{staticClass:"pi pi-fw pi-angle-down menuitem-toggle-icon"}):_vm._e(),(item.items && _vm.isExpand(item))?_c('i',{staticClass:"pi pi-fw pi-angle-up menuitem-toggle-icon"}):_vm._e()])]),_c('transition-expand',[(_vm.isExpand(item))?_c('appsubmenu',{attrs:{"items":_vm.visible(item) && item.items},on:{"menuitem-click":function($event){return _vm.$emit('menuitem-click', $event)}}}):_vm._e()],1)]:(_vm.root && !item.to)?[_c('div',{staticClass:"layout-menuitem-root-text justify-content-between",style:(item.items
              ? 'cursor:pointer;padding-bottom:1rem'
              : 'padding-bottom:0px'),attrs:{"aria-label":item.label},on:{"click":function($event){return _vm.handleRootClick(item)}}},[_c('div',{staticClass:"flex no-wrap"},[(!item.icon && _vm.staticMenuInactive)?_c('span',[_vm._v(_vm._s(item.label[0])+_vm._s(item.label[1]))]):_c('i',{class:item.icon}),(!_vm.staticMenuInactive)?_c('span',{staticClass:"menu-label",style:(item.icon ? 'margin-left: 0.8rem;padding-top: 4px;' : '')},[_vm._v(_vm._s(item.label)+" ")]):_vm._e()]),_c('span',{staticStyle:{"padding-top":"0.5rem"}},[(item.items && !_vm.isExpand(item))?_c('i',{staticClass:"pi pi-fw pi-angle-down menuitem-toggle-icon"}):_vm._e(),(item.items && _vm.isExpand(item))?_c('i',{staticClass:"pi pi-fw pi-angle-up menuitem-toggle-icon"}):_vm._e()])]),_c('transition-expand',[(_vm.isExpand(item))?_c('appsubmenu',{attrs:{"items":_vm.visible(item) && item.items},on:{"menuitem-click":function($event){return _vm.$emit('menuitem-click', $event)}}}):_vm._e()],1)]:[(item.to)?_c('router-link',{directives:[{name:"ripple",rawName:"v-ripple"}],class:[
            item.class,
            'p-ripple',
            { 'p-disabled': item.disabled },
            _vm.checkActive(item),
          ],style:(_vm.getLinkStyle(item.icon)),attrs:{"to":item.to,"target":item.target,"aria-label":item.label,"role":"menuitem"},on:{"click":function($event){return _vm.onMenuItemClick($event, item, i)}}},[(!item.icon && _vm.staticMenuInactive)?_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.getLabelIcon(item.label)))]):_c('i',{class:item.icon}),_c('span',{staticClass:"menu-label"},[((!item.icon && !_vm.staticMenuInactive) || item.icon)?[_vm._v(_vm._s(item.label))]:_vm._e()],2),(item.items)?_c('i',{staticClass:"pi pi-fw pi-angle-down menuitem-toggle-icon"}):_vm._e(),(item.badge)?_c('Badge',{attrs:{"value":item.badge}}):_vm._e()],1):_vm._e(),_c('transition',{attrs:{"name":"layout-submenu-wrapper"}},[_c('appsubmenu',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeIndex === i),expression:"activeIndex === i"}],attrs:{"items":_vm.visible(item) && item.items},on:{"menuitem-click":function($event){return _vm.$emit('menuitem-click', $event)}}})],1)]],2):_vm._e(),(_vm.visible(item) && item.separator)?_c('li',{key:'separator' + i,staticClass:"p-menu-separator",style:(item.style),attrs:{"role":"separator"}}):_vm._e()]})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
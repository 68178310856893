<template>
  <div class="m-r-24 m-t-6 m-b-6">
    <label
      v-if="label"
      class="agencydesk-input-label"
    >{{ label }}</label>
    <div>
      <Textarea
        class="agencydesk-textarea m-l-0 m-12"
        :autoResize="true"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    value: {
      default: null,
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style>
</style>
<template>
  <td colspan="7">
    <div
      class="col header-background-week font-normal text-center font-normal text-center"
      :style="
        currentWeek ? 'background-color: #F2F4F6' : 'background-color: #b9c5d0'
      "
    >
      <span
        >Wk {{ weekNumber }} -
        {{
          currentWeek ? "In progress. Total Scheduled Hours" : "Tracked Hours"
        }}
        :
        <template v-if="!currentWeek">
          {{ convertMinsToHrsMins(workedMinutes) }} /</template
        >
        {{ convertMinsToHrsMins(plannedMinutes) }}
        <template v-if="!currentWeek"
          >({{ formatNum(percentageWorked) }})</template
        >
      </span>
    </div>
  </td>
</template>

<script>
export default {
  props: {
    userTasks: {
      type: Array,
      default: () => [],
    },
    today: {
      type: String,
      default: "",
    },
    week: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    formatNum(num) {
      return num.toFixed(2) + "%";
    },
  },
  computed: {
    weekEnd() {
      return Object.keys(this.week).pop();
    },
    weekStart() {
      return Object.keys(this.week)[0];
    },
    currentWeek() {
      return this.today < this.weekEnd;
    },
    weekNumber() {
      var firstDayOfWeek = Object.keys(this.week)[0];
      firstDayOfWeek = new Date(firstDayOfWeek);
      var firstDayOfYear = new Date(firstDayOfWeek.getFullYear(), 0, 1);
      var pastDaysOfYear = (firstDayOfWeek - firstDayOfYear) / 86400000;
      return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    },
    workedMinutes() {
      var totalMinutes = 0;
      this.userTasks.forEach((a) => {
        a.resource_plan.forEach((b) => {
          const planDate = new Date(b.date);
          if (
            planDate >= new Date(this.weekStart) &&
            planDate <= new Date(this.weekEnd)
          ) {
            totalMinutes += Number(b.minutes_worked);
          }
        });
      });
      return totalMinutes;
    },
    plannedMinutes() {
      var totalMinutes = 0;
      this.userTasks.forEach((a) => {
        a.resource_plan.forEach((b) => {
          const planDate = new Date(b.date);
          if (
            planDate >= new Date(this.weekStart) &&
            planDate <= new Date(this.weekEnd)
          ) {
            totalMinutes += Number(b.minutes);
          }
        });
      });
      return totalMinutes < 0 ? 0 : totalMinutes;
    },
    percentageWorked() {
      if (this.plannedMinutes <= 0) {
        return 0;
      }
      return (this.workedMinutes / this.plannedMinutes) * 100;
    },
  },
};
</script>

<style>
.header-background-week {
  border-radius: 6px;
  margin: 4px;
  height: 43px;
  padding: 4px !important;
  place-content: center;
  pointer-events: none;
  font-size: smaller;
}
</style>
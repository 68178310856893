<template>
  <div class="vpa-container" :style="`width: ${doubleRadius}px`">
    <svg
      class="vpa-svg"
      xmlns="http://www.w3.org/2000/svg"
      :height="doubleRadius"
      :width="doubleRadius"
      :viewBox="`0 0 ${doubleRadius} ${doubleRadius}`"
      preserveAspectRatio="xMinYMin meet"
    >
      <circle
        class="animated"
        :stroke="strokeColor"
        fill="transparent"
        :stroke-linecap="strokeLinecap"
        :stroke-dasharray="`${circumference} ${circumference}`"
        :stroke-dashoffset="strokeDashoffset"
        :stroke-width="stroke"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"
      />
    </svg>
    <div
      :style="`border-width: ${stroke}px; border-color: ${innerStrokeColor}; background-color: ${color}`"
      class="vpa-image-container"
    >
      <img v-if="image" loading="lazy" :src="image" alt="Avatar" />
      <div v-else class="vpa-initials">
        {{ initials }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "vue-progress-avatar",
  props: {
    image: {
      type: String,
      required: false,
    },
    totalPoits: {
      type: Number,
      default: 100,
      required: false,
    },
    strokeColor: {
      type: String,
      default: "#BBEE29",
      required: false,
    },
    fillColor: {
      type: String,
      default: "#f5f5f5",
      required: false,
    },
    innerStrokeColor: {
      type: String,
      default: "#dedede",
      required: false,
    },
    strokeLinecap: {
      type: String,
      default: "round",
      required: false,
    },
    user: {
      type: Object,
      default: null,
      required: false,
    },
    progress: {
      type: Number,
      default: 0,
      required: false,
    },
    radius: {
      type: Number,
      default: 40,
      required: false,
    },
    stroke: {
      type: Number,
      default: 4,
      required: false,
    },
  },
  data() {
    const doubleRadius = this.radius * 2;
    const normalizedRadius = this.radius - this.stroke / 2;
    const circumference = normalizedRadius * 2 * Math.PI;

    return {
      doubleRadius,
      normalizedRadius,
      circumference,
    };
  },
  computed: {
    strokeDashoffset() {
      return (
        this.circumference -
        (this.progress / this.totalPoits) * this.circumference
      );
    },
    initials() {
      return this.user?.name
        ? this.user.name[0]
        : "" + this.user?.surname
        ? this.user.surname[0]
        : "";
    },
    color() {
      if (this.image) return "transparent";
      var hash = 0;
      var str =
        this.user.name +
        this.user.surname +
        this.user.username +
        this.user.email;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      var h = hash % 6;
      if (h < 0) {
        h = h * -1;
      }

      var cols = [
        "#5E00CC",
        "#00BAFF",
        "#F5D900",
        "#00B978",
        "#E0005E",
        "#ED5200",
        "#CCB244",
      ];

      return cols[h];
    },
  },
};
</script>

<style lang="css">
.vpa-container * {
  box-sizing: border-box;
}

.vpa-container {
  position: relative;
}

.vpa-container .vpa-svg {
  position: relative;
  z-index: 10;
  display: block;
  vertical-align: middle;
}

.vpa-container svg circle.animated {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.vpa-image-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-style: solid;
}

.vpa-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.vpa-initials {
  text-align: center;
  font-size: 1.5em;
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 9px;
}

.vpa-initials-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
}
</style>

<template>
  <div
    class="flex flex-column align-items-center justify-content-center"
    style="height: 100vh"
  >
    <div class="flex justify-content-center">
      <div class="w-30rem">
        <h5 class="font-bold text-primary mb-3">All done!</h5>
        <h1 style="font-size: 32px">Agencydesk fits like a glove …</h1>

        <form @submit="submit" @keyup.enter="submit" style="margin-top: 2rem">
          <div style="margin-top: 5rem">
            <Button
              type="submit"
              class="button is-primary"
              label="Start working!"
              style="width: 150px"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      team: ["", "", ""],
    };
  },
  metaInfo: {
    title: "Welcome",
  },
  methods: {
    submit() {
      this.$router.push("/onboard/complete");
    },
  },
};
</script>
  
  <style>
</style>
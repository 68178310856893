<template>
  <div class="flex grid">
    <div class="col-12 lg:col-8" align="left">
      <div class="flex justify-content-between align-items-center">
        <div class="col">
          <div class="cursor-pointer headerHover font-light">
            <span>Job Notes</span>
          </div>
        </div>
        <div class="col flex justify-content-end">
          <div class="mr-3">
            <FilterSearchButton
              @input="handleNotesSearch"
              v-model="noteSearchTerm"
            />
          </div>
        </div>
      </div>
      <div class="card">
        <div v-if="notes && notes.length === 0">
          <p>No Notes Yet</p>
        </div>
        <div v-else>
          <div v-for="note in notes" :key="note.uid">
            <div class="flex align-items-center p-3 pt-1">
              <div class="flex-grow-0">
                <div v-if="isLoading">
                  <Skeleton shape="circle" width="3rem" height="3rem" />
                </div>
                <Avatar
                  v-else
                  :image="note.user.profile_picture"
                  :label="
                    !note.user.profile_picture ? getIntials(note.user) : null
                  "
                  :style="{
                    'background-color': getColorFromName(note.user.name),
                    color: '#ffffff',
                  }"
                  size="large"
                  shape="circle"
                />
              </div>
              <div class="flex flex-column flex-grow-1 pl-4">
                <div class="flex flex-row justify-content-between">
                  <Skeleton v-if="isLoading" width="40%"></Skeleton>
                  <div v-else>
                    <span class="font-bold">{{ note.user.name }}</span>
                    commented on
                    <span
                      v-if="note.task.title != ''"
                      @click="nav(note.task)"
                      class="text-link cursor-pointer"
                      >{{ note.task.task_type_name }} -
                      {{ note.task.title }}</span
                    >
                    <span
                      v-else
                      @click="nav(note.task)"
                      class="text-link cursor-pointer"
                      >{{ note.task.task_type_name }}</span
                    >
                  </div>
                  <Skeleton v-if="isLoading" width="10%"></Skeleton>
                  <div v-else class="font-light text-sm">
                    {{ formatDate(note.created_date.nice) }}
                  </div>
                </div>

                <div class="pt-2">
                  <Skeleton v-if="isLoading" width="20%"></Skeleton>
                  <span v-else class="note-text"> {{ note.note_text }}</span>
                </div>
              </div>
            </div>
            <div v-for="c in note.comments" :key="c.uid">
              <div class="flex align-items-center p-3 ml-4 border-left">
                <div class="flex-grow-0">
                  <div v-if="isLoading">
                    <Skeleton shape="circle" width="3rem" height="3rem" />
                  </div>
                  <Avatar
                    v-else
                    :image="c.user.profile_picture"
                    :label="!c.user.profile_picture ? getIntials(c.user) : null"
                    :style="{
                      'background-color': getColorFromName(c.user.name),
                      color: '#ffffff',
                    }"
                    size="large"
                    shape="circle"
                  />
                </div>
                <div class="flex flex-column flex-grow-1 pl-4">
                  <div class="flex flex-row justify-content-between">
                    <Skeleton v-if="isLoading" width="40%"></Skeleton>
                    <div v-else>
                      <span class="font-bold">{{ c.user.name }}</span>
                      replied
                    </div>
                    <Skeleton v-if="isLoading" width="10%"></Skeleton>
                    <div v-else class="font-light text-sm">
                      {{ formatDate(c.created_date.nice) }}
                    </div>
                  </div>

                  <div class="pt-2">
                    <Skeleton v-if="isLoading" width="20%"></Skeleton>
                    <span v-else> {{ c.note_text }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-4" align="left">
      <div class="flex justify-content-between align-items-center">
        <div class="col">
          <div class="cursor-pointer headerHover font-light">
            <span>Task Documents</span>
          </div>
        </div>
        <div class="col flex justify-content-end">
          <div class="mr-3">
            <FilterSearchButton
              @input="onTaskDocumentSearchChange"
              v-model="documentSearchTerm"
            />
          </div>
        </div>
      </div>
      <div class="card">
        <div v-if="documents == undefined">
          <p>No Documents Yet</p>
        </div>
        <div v-else>
          <div v-for="document in documents" :key="document.uid">
            <div class="align-items-center p-3 pt-1">
              <Skeleton v-if="isLoading" height="50px"></Skeleton>
              <div v-else class="flex-grow-0">
                <Attachment :file="document"></Attachment>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="field col-12 cursor-pointer" v-if="!isLoading">
          <file-pond
            name="profile"
            ref="pond"
            class="font-light"
            label-idle="<i class='las la-cloud-upload-alt pr-2 i-20'></i>Drop documents or <span class='text-link cursor-pointer'>upload files</span>"
            v-bind:allow-multiple="true"
          />
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api";
import {
  state,
  fetchJobNotes,
  fetchJobDocuments,
} from "../../../services/data_service.js";
import Vue from "vue";
import Attachment from "@/application/Tasks/components/Attachment";
import vueFilePond, { setOptions } from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
// var FilePond = vueFilePond(FilePondPluginFileValidateType);
export default {
  components: {
    Attachment,
    // FilePond,
  },
  props: {
    isActive: {
      type: Boolean,
      default: true,
    },
  },
  name: "Home",
  data() {
    return {
      isLoading: false,
      noteSearchTerm: null,
      documentSearchTerm: null,
      notes: [],
      documents: [],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        category: { value: null, matchMode: FilterMatchMode.CONTAINS },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
  },
  async mounted() {
    await this.loadData();
  },

  methods: {
    onTaskDocumentSearchChange(text) {
      const searchTerm = text.toLowerCase();
      const documentsWithoutExtensions = state.jobDocuments.map((file) => {
        const parts = file.name.split(".");
        return { ...file, name: parts.slice(0, -1).join(".") };
      });
      const searchedDocuments = documentsWithoutExtensions.filter((item) => {
        return item.name.toLowerCase().includes(searchTerm);
      });
      this.documents = searchedDocuments;
    },
    handleNotesSearch(text) {
      const searchTerm = text.toLowerCase();
      const values = state.jobNotes.filter((item) => {
        return (
          item.note_text.toLowerCase().includes(searchTerm) ||
          item.comments.some((comment) =>
            comment.note_text.toLowerCase().includes(searchTerm)
          )
        );
      });
      this.notes = values;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return `${date.getDate()} ${date.toLocaleString("default", {
        month: "short",
      })} ${date.getFullYear() % 100}`;
    },
    nav(ev) {
      this.$store.dispatch("setSelectedObject", {
        uid: ev.uid,
      });
      this.$store.dispatch("setSliderView", "TaskSlider");
    },
    rowClass() {
      return "row-accessories";
    },
    async loadData() {
      if (!state.jobNotes) {
        this.isLoading = true;
        state.jobNotes = [];
        for (let index = 0; index < 3; index++) {
          state.jobNotes.push({});
        }
      }
      if (!state.jobDocuments) {
        this.isLoading = true;
        state.jobDocuments = [];
        for (let index = 0; index < 3; index++) {
          state.jobDocuments.push({});
        }
      }
      await Promise.all([
        fetchJobNotes(this.$route.params.uid),
        fetchJobDocuments(this.$route.params.uid),
      ]);
      this.isLoading = false;
      this.notes = state.jobNotes;
      this.documents = state.jobDocuments;
    },
  },

  computed: {
    // notes() {
    //   return state.jobNotes;
    // },
    // documents() {
    //   return state.jobDocuments;
    // },
  },

  watch: {
    async isActive(n, o) {
      if (n === true) {
        await this.loadData();
      }
    },
  },
};
</script>

<style scoped>
.note-text-box {
  background-color: rgba(185, 197, 208, 0.18);
  padding: 10px;
  margin-top: 10px;
  border-radius: 8px;
  background-clip: padding-box;
}
.note-text {
  color: #252631 !important;
}
</style>

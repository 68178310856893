var render = function render(){var _vm=this,_c=_vm._self._c;return _c('th',{class:['max-300 flex header task-header pr-2', _vm.taskClasses],on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[_c('div',{staticClass:"cursor-grab col-1 align-self-center handle"},[(_vm.hover)?_c('i',{staticClass:"la la-grip-lines-vertical"}):_vm._e(),(!_vm.hover && _vm.task.date_due && _vm.task.date_due.time_until == 'Overdue')?_c('div',{staticClass:"task-notice",staticStyle:{"position":"absolute"}}):_vm._e()]),_c('div',{staticClass:"col-7 p-02 justify-content-start align-self-center flex-column"},[_c('div',{class:['font-weight-normal cursor-pointer text-start', { 'text-ellipsis-wrap': !_vm.expand }],on:{"click":function($event){return _vm.getTask(_vm.task.uid)}}},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
          value: _vm.task.job?.title,
        }),expression:"{\n          value: task.job?.title,\n        }",modifiers:{"bottom":true}}],staticClass:"font-light font-smaller"},[_vm._v(_vm._s(_vm.task.job?.client))]),_c('br'),_vm._v(" "+_vm._s(_vm.task.title ? _vm.task.title : _vm.task.task_type_name)+" ")]),_c('transition-expand',[(_vm.expand)?_c('div',{staticClass:"font-light justify-content-start align-self-center flex font-smaller"},[_c('span',[_vm._v(_vm._s(_vm.task.minutes ? _vm.secToHM(_vm.task.minutes * 60) : "00:00")+" / "+_vm._s(_vm.task.expected_hours ? _vm.task.expected_hours : "0:00"))])]):_vm._e()])],1),_c('div',{staticClass:"col inline-flex align-self-center justify-content-between"},[_c('div',{staticClass:"col p-0"},[_c('span',{staticClass:"p-badge p-component w-3rem",style:(_vm.isExceeded(_vm.task)
            ? 'background-color: #ffab2b'
            : _vm.task.hours_unplanned &&
              _vm.task.hours_planned !== _vm.task.estimated_hours &&
              _vm.task.minutes_unplanned > 0 &&
              _vm.task.hours_unplanned != '00:00'
            ? 'background-color: #6dd230'
            : 'background-color: transparent')},[(_vm.isExceeded(_vm.task))?_c('i',{staticClass:"las la-hourglass-end text-16 text-center",staticStyle:{"color":"white","padding-top":"2px"}}):_c('span',{style:(_vm.task.hours_unplanned &&
            _vm.task.hours_planned !== _vm.task.estimated_hours &&
            _vm.task.minutes_unplanned > 0 &&
            _vm.task.hours_unplanned != '00:00'
              ? ''
              : 'background-color: transparent')},[(
              _vm.task.hours_unplanned &&
              _vm.task.hours_planned !== _vm.task.estimated_hours &&
              _vm.task.minutes_unplanned > 0 &&
              _vm.task.hours_unplanned != '00:00'
            )?[_vm._v(_vm._s(_vm.task.hours_unplanned ? _vm.task.hours_unplanned : _vm.task.estimated_hours))]:[_vm._v("00:00")]],2)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hover),expression:"hover"}],staticClass:"absolute right-0 mr-1 align-self-center"},[_c('i',{class:'cursor-pointer align-self-center  font-smaller ' +
          (_vm.expand ? ' la la-minus' : ' la la-plus'),on:{"click":function($event){_vm.expand = !_vm.expand}}}),_c('i',{staticClass:"cursor-pointer la la-ellipsis-v align-self-center",on:{"click":_vm.toggleMenu}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
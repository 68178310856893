<template>
  <div class="sm:col-12 mt-3 mb-3">
    <div class="font-light">Timeline</div>
    <div class="flex justify-content-between">
      <div
        class="flex vertical-middle align-items-center"
        v-if="job && job.date_start && job.date_due"
      >
        <h2 style="margin: 0px" class="text-h2">
          {{ job.date_start?.date | moment("DD MMM ") }} -
          {{ job.date_due.date | moment("DD MMM YYYY") }}
        </h2>
        <div class="pl-5" v-if="nextMilestone">
          <i class="lab la-font-awesome-flag" style="color: #4d7cfa"></i
          ><span class="pl-2"
            >Next milestone:
            <span class="text-primary">{{
              toTitleCase(nextMilestone?.status)
            }}</span>
            – {{ nextMilestone?.date }}</span
          >
        </div>
      </div>
      <div>
        <Button
          v-can="'admin'"
          label="Add Milestone"
          class="p-button-text p-button-secondary"
          icon="las la-plus"
          @click="$store.dispatch('setSliderView', 'MilestoneSlider')"
        />
        <Button
          style="height: 100%"
          class="p-button-text p-button-secondary"
          :icon="showTimeline ? 'las la-angle-up' : 'las la-angle-down'"
          @click="toggleTimeline"
        />
      </div>
    </div>
    <transition-expand>
      <div v-if="showTimeline">
        <div class="card mb-0 py-3 relative">
          <div
            class="z-0 absolute bg-tint h-full top-0 left-0 border-round-left-md"
            :style="getTimelineStyle()"
          ></div>
          <Timeline
            class="z-5"
            :value="customEvents"
            ref="timeline"
            layout="horizontal"
          >
            <template #marker="slotProps">
              <span
                class="custom-marker shadow-2"
                :style="{ color: slotProps.item.color }"
              >
                <i :class="slotProps.item.icon"></i>
              </span>
            </template>
            <template #content="slotProps">
              <div
                :class="
                  'font-light text-500 ' +
                  (slotProps.item.uid ? 'cursor-pointer' : '')
                "
                style="white-space: nowrap"
                v-can-click="'admin'"
                @click="editMilestone(slotProps.item)"
                v-if="slotProps.index % 2 === 0"
              >
                {{ slotProps.item.date }}:<span
                  v-if="slotProps.item.status"
                  class="text-blue-700 ml-1"
                  >{{ slotProps.item.status }}</span
                >
              </div>
            </template>
            <template #opposite="slotProps">
              <div
                v-if="slotProps.index % 2 !== 0"
                :class="
                  'font-light text-500 ' +
                  (slotProps.item.uid ? 'cursor-pointer' : '')
                "
                style="white-space: nowrap"
                v-can-click="'admin'"
                @click="editMilestone(slotProps.item)"
              >
                {{ slotProps.item.date }}:<span
                  v-if="slotProps.item.status"
                  class="text-blue-700 ml-1"
                  >{{ slotProps.item.status }}</span
                >
              </div>
            </template>
          </Timeline>
        </div>
      </div>
    </transition-expand>
  </div>
</template>
 
  <script>
export default {
  props: {
    job: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showTimeline: true,
      customEvents: [],
    };
  },
  mounted() {
    this.handleMilestones();
  },
  computed: {
    nextMilestone() {
      var today = new Date();
      return this.customEvents?.find((m) => {
        return new Date(m.dateTimestamp) > today;
      });
    },
  },
  methods: {
    toggleTimeline() {
      this.showTimeline = !this.showTimeline;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = { day: "2-digit", month: "short" };
      return date.toLocaleDateString("en-US", options);
    },
    handleMilestones() {
      this.customEvents = [];
      if (this.job && this.job.milestones) {
        Object.keys(this.job.milestones).forEach((m) => {
          this.customEvents.push({
            status: this.job.milestones[m].label,
            date: this.formatDate(m),
            dateTimestamp: m,
            uid: this.job.milestones[m].uid,
            icon:
              this.job.milestones[m].job_due === 1
                ? "las la-flag-checkered"
                : this.job.milestones[m].job_start === 1
                ? "las la-play"
                : "lab la-font-awesome-flag",
            color: "rgb(77, 124, 254)",
          });
        });

        // Sort milestones by date
        this.customEvents.sort((a, b) => {
          return new Date(a.dateTimestamp) - new Date(b.dateTimestamp);
        });
      }
      this.$nextTick(() => {
        var timelinesDiv = document.getElementsByClassName(
          "p-timeline-event-separator"
        );
        if (timelinesDiv?.length <= 1) return;
        var lastTimelineDiv = timelinesDiv[timelinesDiv.length - 1];
        var connectorDiv = document.createElement("div");
        connectorDiv.className = "p-timeline-event-connector connect-50-size";
        if (lastTimelineDiv?.firstChild) {
          lastTimelineDiv.insertBefore(
            connectorDiv,
            lastTimelineDiv.firstChild
          );
        } else {
          lastTimelineDiv?.appendChild(connectorDiv);
        }
      });
    },
    editMilestone(milestone) {
      if (!milestone.uid || !this.canView("admin")) return;
      this.$store.dispatch("setSelectedObject", milestone);
      this.$store.dispatch("setSliderView", "MilestoneSlider");
    },
    differentInTime(start_date, end_date) {
      let date1 = new Date(start_date);
      let date2 = new Date(end_date);
      let Difference_In_Time = date2.getTime() - date1.getTime();
      return Math.round(Difference_In_Time / (1000 * 3600 * 24));
    },
    getTimelineStyle() {
      let current_day = new Date();
      let total_days = this.differentInTime(
        this.job.date_start?.date,
        this.job.date_due.date
      );
      let days_spend = this.differentInTime(
        this.job.date_start?.date,
        current_day
      );
      let time = ((days_spend / total_days) * 100).toFixed(0);
      return `width:${time >= 100 ? 100 : time}%`;
    },
  },
  watch: {
    "job.milestones"(n, o) {
      this.handleMilestones();
    },
  },
};
</script>
  
  <style scoped>
.custom-marker {
  border-radius: 50%;
  border-color: rgb(77, 124, 254);
  border-width: 2px;
  border-style: solid;
  width: 25px;
  height: 24px;
  padding-top: 2px;
  text-align: center;
}

.connect-50-size {
  width: 24px !important;
}
</style>
  
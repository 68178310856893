<template>
  <div class="card pt-1">
    <div class="hidden sm:flex justify-content-between">
      <div class="hidden sm:flex align-items-center">
        <router-link v-for="r in path" :key="r.path" :to="{ path: r.route }">
          <h6 class="sm:pr-5" :style="'color: grey;' + getHeaderStyle(r.route)">
            {{ r.header }}
          </h6>
        </router-link>
      </div>
      <div class="flex mb-2" style="height: 40px">
        <div class="mr-2">
          <FilterSearchButton v-if="buttonLabel" v-model="searchTerm" />
        </div>
        <div>
          <Button
            style="height: 38px"
            v-if="buttonLabel"
            @click="determineFunctionCall()"
            :label="buttonLabel"
            icon="las la-plus"
          />

          <ConnectXero
            v-if="
              $route.path == '/settings/account_structure' && !integrationId
            "
            class="ml-2"
          ></ConnectXero>

          <Button
            v-if="$route.path == '/settings/account_structure' && integrationId"
            :loading="syncingAccounts"
            style="background-color: #ffab2b; border: #ffab2b"
            class="ml-2"
            @click="syncAccounts()"
            label="Sync Accounts"
            icon="las la-sync"
          />
        </div>
      </div>
    </div>

    <!-- hidden sm:flex -->
    <div class="flex sm:hidden">
      <Dropdown
        :options="path"
        @change="selectRouteHeader"
        optionLabel="header"
        optionValue="header"
        placeholder="Company Details"
        class="mb-3 w-full"
        v-model="pathName"
      />

      <div class="flex sm:hidden" v-if="buttonLabel">
        <div>
          <FilterSearchButton v-model="searchTerm" />
        </div>
        <div>
          <Button
            @click="determineFunctionCall()"
            :label="buttonLabel"
            icon="las la-plus"
          />
        </div>
      </div>
    </div>
    <Divider class="flex sm:hidden" />
    <div>
      <transition name="slide-fade">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      syncingAccounts: false,
      pathName: "",
      buttonLabel: null,
      path: [
        {
          route: "/settings",
          header: "Details",
        },
        {
          route: "/settings/finance",
          header: "Finance",
        },
        {
          label: "New Account",
          action: "AccountStructureSlider",
          route: "/settings/account_structure",
          header: "Accounting",
        },
        {
          label: "New Billing Tier",
          action: "BillingTierSlider",
          route: "/settings/billing_tier",
          header: "Billing Tiers",
        },
        {
          label: "New Category",
          action: "CategoriesSlider",
          route: "/settings/category",
          header: "Categories",
        },
        {
          label: "New Tag",
          action: "CategoriesSlider",
          route: "/settings/global_tags",
          header: "Global Tags",
        },
      ],
    };
  },
  watch: {
    searchTerm(newTerm) {
      this.navigateWithSearchTerm(newTerm);
    },
    $route(to, from) {
      const item = this.path.find((entry) => entry.route === to.path);
      if (item) {
        this.buttonLabel = item.label;
      } else {
        this.buttonLabel = null;
      }
    },
  },
  mounted() {
    const item = this.path.find((entry) => entry.route === this.$route.path);
    if (item) {
      this.buttonLabel = item.label;
    }
  },
  methods: {
    syncAccounts() {
      this.syncingAccounts = true;
      this.$axios
        .get(process.env.VUE_APP_ROOT_API + "/v1/departments/gls/sync")
        .then((response) => {
          this.syncingAccounts = false;
          this.$toast.add({
            severity: "success",
            summary: "Accounts have been synced",
            life: 3000,
          });
        })
        .catch((error) => {
          this.syncingAccounts = false;
          console.error(error);
        });
    },
    determineFunctionCall() {
      const item = this.path.find((entry) => entry.label === this.buttonLabel);
      if (this.buttonLabel === "New Tag") {
        this.$router.push({ query: { action: "tag" } });
        return;
      }
      if (item) {
        this.$store.dispatch("setSliderView", item.action);
      }
    },
    navigateWithSearchTerm(searchTerm) {
      this.$router.push({ query: { term: searchTerm } });
    },
    getHeaderStyle(route) {
      if (this.$route.path == route) {
        return "font-weight: bold; color: #4D7CFE";
      }
    },
    selectRouteHeader(route) {
      const routeToNavigate = this.path.filter(
        (element) => element.header === route.value
      )[0];
      if (routeToNavigate) {
        this.$router.push(routeToNavigate.route);
      }
    },
  },
  created() {
    this.selectRouteHeader("Company Details");
  },
  computed: {
    integrationId() {
      return this.$store.getters.integrationId;
    },
  },
  name: "SettingsGenerak",
};
</script>

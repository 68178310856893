<template>
  <Dialog
    :visible.sync="display"
    :modal="true"
    :closable="false"
    position="center"
    dismissableMask
    :showHeader="false"
    :contentStyle="{
      borderRadius: '6px',
    }"
  >
    <form @submit.prevent="submitHandler" v-form-enter>
      <div class="pt-3">
        <div
          class="flex justify-content-between align-items-center p-3 border-round"
          style="width: 400px; height: 56px; background-color: #f8f9fa"
        >
          <div class="mb-0 text-16">Invite a User</div>
          <div class="p-2">
            <i
              class="la la-times cursor-pointer"
              @click="clearInvitePopup()"
            ></i>
          </div>
        </div>
      </div>
      <div class="field col-12 sm:col-12 pl-0">
        <label for="name">Email</label>
        <div class="p-inputgroup">
          <InputText
            v-if="!isLoading"
            placeHolder="Email Address"
            v-model="inviteUser.email"
          />
          <Skeleton height="100%" borderRadius="6px" v-else />
        </div>
        <div
          class="error-validation-text pt-2"
          v-if="submitted && !$v.inviteUser.email.required"
        >
          Email is required
        </div>
        <div
          class="error-validation-text pt-2"
          v-if="submitted && !$v.inviteUser.email.email"
        >
          Please enter a valid email
        </div>
      </div>
      <!-- <div class="text-20">
        <Button
          type="submit"
          icon="las la-paper-plane i-20"
          class="cursor-pointer grey-button"
        />
      </div> -->
      <div class="add-button flex justify-content-between mt-4">
        <Button label="Send" class="cursor-pointer text-14" type="submit" />
        <Button
          @click="clearInvitePopup()"
          label="CANCEL"
          class="p-button-text p-button-secondary"
        />
      </div>
    </form>
  </Dialog>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
export default {
  props: {
    display: Boolean,
  },
  data() {
    return {
      submitted: false,
      isLoading: false,
      displayInput: false,
      inviteUser: {
        email: null,
      },
    };
  },
  validations: {
    inviteUser: {
      email: {
        required,
        email,
      },
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },

  methods: {
    submitHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the required fields",
          life: 3000,
        });
      } else {
        this.isLoading = true;
        this.$axios
          .post(
            process.env.VUE_APP_ROOT_API + "/v1/users/invite",
            JSON.stringify(this.inviteUser),
            {
              headers: { "Content-type": "application/json" },
            }
          )
          .then((response) => {
            this.clearInvitePopup();
            this.isLoading = false;
            this.$toast.add({
              severity: "success",
              summary: "Email has been sent",
              life: 3000,
            });
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response && error.response.status === 401) {
              console.error("NOOOOOOOOO!");
            } else {
              // Handle error however you want
            }
            console.error(error);
          });
      }
    },
    clearInvitePopup() {
      this.$emit("dismiss");
      this.inviteUser.email = null;
    },
  },
};
</script>

<style>
</style>
<template>
  <div class="layout-footer flex flex-row justify-content-start">
    <span class="font-light ml-2"
      >© {{ year }} AgencyDesk - All Rights Reserved</span
    >
  </div>
</template>

<script>
export default {
  name: "AppFooter",
  methods: {
    footerImage() {
      return "../assets/agencydesk-rocket.svg";
    },
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>
<template>
  <div class="card pt-2">
    <div
      class="flex flex-wrap sm:justify-content-end justify-content-center align-items-center pb-2"
    >
      <div class="mr-3">
        <FilterSearchButton v-model="filters['global'].value" />
      </div>
      <div class="mr-3">
        <Button label="New Recurring" icon="las la-plus" />
      </div>
    </div>

    <div>
      <DataTable
        responsiveLayout="scroll"
        :rowClass="rowClass"
        :value="templates"
        editMode="row"
        :filters.sync="filters"
        dataKey="uid"
        :editingRows.sync="editingRows"
        @row-edit-cancel="onRowEditCancel"
        @row-edit-save="saveRow"
        :paginator="true"
        :rows="30"
        :rowsPerPageOptions="[30, 50, 100]"
      >
        <template #empty>
          <Button
            v-if="!filters['global'].value"
            label="New Recurring"
            class="p-button-text p-button-secondary"
            icon="las la-plus"
          />
          <div v-else>No Results</div></template
        >

        <Column field="type_name" header="Name">
          <template #body="slotProps">
            <span
              class="cursor-pointer"
              @click="$store.dispatch('setSelectedObject', slotProps.data)"
            >
              {{ slotProps.data.type_name }}
            </span>
          </template>
          <template #editor="slotProps">
            <InputText placeHolder="Name" v-model="slotProps.data.type_name" />
          </template>
        </Column>
        <Column field="type" header="Type">
          <template #body="slotProps">
            {{ slotProps.data[slotProps.column.field] }}
          </template>
          <template #editor="slotProps">
            <InputText
              placeHolder="Type"
              v-model="slotProps.data[slotProps.column.field]"
            />
          </template>
        </Column>

        <Column field="created" header="Created">
          <template #body="slotProps">
            {{ slotProps.data[slotProps.column.field] }}
          </template>
          <template #editor="slotProps">
            <InputText
              placeHolder="Created"
              v-model="slotProps.data[slotProps.column.field]"
            />
          </template>
        </Column>

        <Column sortable field="active" header="Active">
          <template #body="slotProps">
            <AActiveSwitch v-model="slotProps.data[slotProps.column.field]" />
          </template>
        </Column>
        <Column bodyStyle="text-align:right">
          <template #body="slotProps">
            <template v-if="editingRow !== slotProps.data.uid">
              <div class="flex">
                <Button
                  icon="las la-pen i-20"
                  class="p-button-md p-button-rounded grey-button mr-3"
                  @click="editRow(slotProps.data, template)"
                />
                <Button
                  icon=" las la-trash i-20"
                  class="p-button-md p-button-rounded grey-button"
                />
              </div> </template
            ><template v-else>
              <div class="flex">
                <Button
                  icon="las la-check i-20"
                  class="p-button-md p-button-rounded grey-button mr-3"
                  @click="saveRow(slotProps)"
                />
                <Button
                  icon=" las la-times i-20"
                  class="p-button-md p-button-rounded grey-button"
                  @click="onRowEditCancel(slotProps.data, template)"
                />
              </div>
            </template>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { state, loadData } from "../../../services/data_service.js";
import { FilterMatchMode } from "primevue/api";
import Vue from "vue";
export default {
  data() {
    return {
      drag: false,
      originalRows: [],
      editingRows: [],
      editingRow: null,
      tempaltes: [],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },

  methods: {
    rowClass() {
      return "row-accessories";
    },

    editRow(r, arr) {
      this.editingRow = r.uid;
      this.editingRows.push(r);
      var index = arr.indexOf(r);
      this.originalRows[index] = {
        ...arr[index],
      };
    },
    saveRow(r) {
      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API + "/v1/templates/" + r.data.uid,
          JSON.stringify(r.data),
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          this.editingRow = null;
          var i2 = this.editingRows.indexOf(r);
          this.editingRows.splice(i2, 1);
          this.$toast.add({
            severity: "success",
            summary: "Recurring Updated",
            life: 3000,
          });

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },

    onRowEditCancel(r, arr) {
      var index = arr.indexOf(r);
      Vue.set(arr, index, this.originalRows[index]);
      this.editingRow = null;
      var i2 = this.editingRows.indexOf(r);
      this.editingRows.splice(i2, 1);
    },
  },
  metaInfo: {
    title: "Settings | Recurring",

    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: "" },
      { name: "keywords", content: "" },
    ],
  },
};
</script>

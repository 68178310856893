<template>
  <Dialog
    :visible="display"
    :modal="true"
    :closable="false"
    :style="{ maxWidth: '400px' }"
    @show="updateTime"
    :header="prop_name || 'Save tracked time to task'"
    @hide="resetForm"
  >
    <form @submit.prevent="submitHandler">
      <div class="p-fluid formgrid grid">
        <div class="field col-6">
          <label for="Time">Time to submit</label>
          <InputMask v-model="time" mask="99:99" />
        </div>

        <div class="field col-6">
          <label for="Tracked">Tracked time</label>
          <div class="p-inputgroup flex align-items-center pl-1">
            <div>{{ formattedCurrentTimer }}</div>
          </div>
        </div>
        <div class="field col-12">
          <label for="message">Description</label>
          <Textarea
            id="message"
            :autoResize="true"
            v-model="message"
            rows="4"
          />
        </div>

        <div class="field-checkbox col-12">
          <div v-if="task && task.reviewed_by" class="flex align-items-center">
            <Checkbox
              v-model="sendForReview"
              class="pr-1"
              id="review"
              :binary="true"
            />
            <label for="review">Send for Review</label>
          </div>
          <div v-else class="flex align-items-center">
            <Checkbox
              v-model="complete"
              class="pr-1"
              id="complete"
              :binary="true"
            />
            <label for="complete">Mark as task complete</label>
          </div>
        </div>
      </div>
      <div class="flex justify-content-between">
        <Button
          type="submit"
          label="SAVE"
          :loading="saving"
          :disabled="!isFormValid"
          class="is-primary"
        />
        <Button
          class="p-button-text p-button-secondary"
          @click="$emit('dismiss')"
        >
          CANCEL
        </Button>
      </div>
    </form>
  </Dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    display: Boolean,
    prop_uid: String,
    prop_time: String,
    prop_name: String,
    task: Object,
    forReview: Boolean,
  },
  data() {
    return {
      time: null,
      message: "",
      complete: false,
      sendForReview: false,
      saving: false,
    };
  },
  computed: {
    ...mapGetters(["currentTimer", "user", "selectedObject"]),
    formattedCurrentTimer() {
      return this.secToHMS(this.currentTimer);
    },
    isFormValid() {
      return this.time && this.message;
    },
    taskUid() {
      return this.prop_uid || this.selectedObject;
    },
  },
  watch: {
    forReview(val) {
      this.sendForReview = val;
    },
  },
  methods: {
    updateTime() {
      this.time = this.prop_time || this.secToHM(this.currentTimer);
    },
    resetForm() {
      this.message = "";
      this.time = null;
      this.complete = false;
      this.sendForReview = false;
      this.saving = false;
    },
    async submitHandler() {
      if (!this.isFormValid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all fields",
          life: 3000,
        });
        return;
      }

      this.saving = true;

      try {
        // Save time first
        await this.saveTime();

        // Then handle review or completion
        if (this.sendForReview) {
          await this.sendTaskForReview();
        } else if (this.complete) {
          await this.completeTask();
        }

        this.$toast.add({
          severity: "success",
          summary: "Time Saved",
          life: 3000,
        });

        if (!this.prop_uid) {
          this.$store.dispatch("setTimer", null);
        }
        this.$emit("dismiss");
      } catch (error) {
        console.error(error);
        this.$toast.add({
          severity: "error",
          summary: "Error saving time",
          detail: error.message,
          life: 5000,
        });
      } finally {
        this.saving = false;
      }
    },
    async saveTime() {
      const [hours, minutes] = this.time.split(":").map(Number);
      const totalMinutes = hours * 60 + minutes;

      const form = new FormData();
      form.append("task", this.taskUid);
      form.append("commit_date", this.$moment().format("YYYYMMDD"));
      form.append("commit_minutes", totalMinutes);
      form.append("commit_message", this.message);
      form.append("user_uid", this.user.uid);

      // We no longer add 'complete' here as we'll handle it separately

      await this.$axios.post(
        `${process.env.VUE_APP_ROOT_API}/v1/tasks/${this.taskUid}/timer/stop`,
        form,
        {
          headers: { "Content-type": "application/x-www-form-urlencoded" },
        }
      );
    },
    async completeTask() {
      const response = await this.$axios.get(
        `${process.env.VUE_APP_ROOT_API}/v1/tasks/${this.taskUid}/complete/${this.user.uid}`
      );
      this.task = response.data.data;
      this.$toast.add({
        severity: "success",
        summary: `Task marked as ${
          this.task.is_complete === 1 ? "completed" : "incomplete"
        }`,
        life: 2000,
      });
    },
    async sendTaskForReview() {
      const response = await this.$axios.get(
        `${process.env.VUE_APP_ROOT_API}/v1/tasks/${this.taskUid}/review`
      );
      this.task.in_review = response.data.data;
      this.$toast.add({
        severity: "success",
        summary: `Task sent for ${
          response.data.data === 1 ? "review" : "rework"
        }`,
        life: 2000,
      });
    },
  },
};
</script>
<template>
  <div>
    <div>
      <TaskLoading v-if="!tasks && notasks != 'none'" />
      <TaskLoading v-if="!tasks && notasks != 'none'" />
    </div>
    <div v-if="tasks || notasks == 'none'">
      <div style="margin-bottom: 20px">
        <div class="task-tab-section active">
          <i class="la la-check" /> Tasks
        </div>
        <div class="task-tab-section">
          <i class="la la-robot" /> Automations
        </div>
        <div class="task-tab-section">
          <i class="la la-quote-right" /> Notes
        </div>
        <div class="task-tab-section">
          <i class="la la-folder-open" /> Documents
        </div>

        <div style="position: absolute; right: 40px; top: 15px">
          <Button
            v-can="'admin'"
            class="button p-ripple blue"
            @click="addGroup = !addGroup"
            v-if="addGroup == false"
            label="New Group"
            style="
              background: none;
              border: 2px solid #ddd;
              color: #666;
              padding: 10px 10px;
              font-size: 15px;
            "
          />
        </div>

        <div
          class="job-entry"
          style="
            background: #f8fafb;
            border-radius: 0px;
            padding: 10px 10px;
            margin-top: 10px;
          "
          v-if="addGroup == true"
        >
          <InputText
            v-focus
            @keyup.enter="$event.target.blur()"
            v-model="addGroupName"
            style="
              border: none;
              background: none;
              box-shadow: none;
              border-bottom: 1px solid #ddd;
              border-radius: 0px;
            "
            value="Untitled"
            autofocus
            @focus="$event.target.select()"
            @blur="onEnter"
          />
          <br /><br />
        </div>
      </div>
      <div
        v-if="!tasks[0] && addGroup == false"
        style="padding: 80px; font-size: 16px"
        align="center"
      >
        <div
          style="
            display: inline-block;
            padding: 30px 80px;
            background: #f8fafb;
            border-radius: 100px;
          "
        >
          <a @click="addGroup = !addGroup">Create a group</a> to start adding
          tasks.
        </div>
      </div>
      <draggable
        @end="onEndGroup($event)"
        :id="tasks.uid"
        group="tasks-groups"
        ghost-class="ghost-class"
        :animation="200"
      >
        <div
          class="job-tasks-container"
          :id="task.uid"
          v-for="(task, id) in tasks"
          :key="id"
        >
          <div class="task-group">
            <div>
              <div class="flex flex-wrap grid">
                <div
                  class="col"
                  style="
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    height: 100%;
                    width: 100%;
                  "
                  @click="toggleGroup(task.uid)"
                />
                <h3 style="margin: 0px" class="col">
                  {{ task.title }}
                </h3>
                <div class="col" v-if="!groupToggle.includes(task.uid)">
                  Assigned to
                </div>
                <div class="col" v-if="!groupToggle.includes(task.uid)">
                  Due
                </div>
                <div class="col" v-if="!groupToggle.includes(task.uid)">
                  Time
                </div>
                <div
                  v-if="task.tasks && groupToggle.includes(task.uid)"
                  class="col"
                >
                  {{ task.tasks.length }} task{{
                    task.tasks.length != 1 ? "s" : ""
                  }}
                </div>
                <GroupMenu :group="task.uid" />
              </div>
            </div>
          </div>
          <div
            class="flex flex-wrap"
            name="list"
            v-if="!groupToggle.includes(task.uid)"
          >
            <draggable
              class="col"
              :id="task.uid"
              group="tasks"
              :class="'group-' + task.uid"
              @end="onEnd($event)"
              ghost-class="ghost-class"
              :animation="140"
            >
              <div
                v-for="(taskitem, uid) in task.tasks"
                :key="uid"
                :id="taskitem.uid"
              >
                <TaskForm :task="taskitem" />
                <i
                  class="la la-trash task-del"
                  @click="deleteTask(taskitem.uid)"
                />
              </div>
            </draggable>
          </div>
          <div style="margin-bottom: 20px; margin-top: 20px">
            <a
              @click="
                $store.dispatch('setSelectedObject', { uid: task.uid });
                $store.dispatch('setSliderView', 'TaskSlider');
              "
              label="New Task"
            >
              + Add Task
            </a>
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import TaskForm from "@/application/Tasks/components/TaskForm";
import GroupMenu from "@/application/Job/components/GroupMenu";
import TaskLoading from "@/application/Tasks/components/TaskLoading";
export default {
  name: "Home",
  components: {
    TaskForm,
    TaskLoading,
    draggable,
    GroupMenu,
  },
  props: {
    tasks: Array,
  },
  data() {
    return {
      errors: [],
      groupToggle: [],
      notasks: "",
      showGroupDrop: false,
      selectedTags: [],
      addGroup: false,
      addGroupName: "Untitled",
      dragging: false,
    };
  },
  created() {},

  methods: {
    onEnd: function (e) {
      var form = new FormData();
      const job_uid = this.$route.params.uid;
      form.append("task", e.item.id);
      form.append("group", e.to.id);
      form.append("job", job_uid);
      var sortlist = "";
      const children = this.$el.querySelectorAll(
        ".group-" + e.to.id + " .job-tasks-item"
      );
      children.forEach((value) => {
        sortlist += value.id + ",";
      });
      form.append("sortlist", sortlist);
      this.$axios
        .post(process.env.VUE_APP_ROOT_API + "/v1/tasks/group/move", form, {
          headers: { "Content-type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          //
        })
        .catch((error) => {
          console.error(error);
        });
    },

    onEndGroup: function (e) {
      var form = new FormData();
      const job_uid = this.$route.params.uid;
      form.append("job", job_uid);
      var sortlist = "";
      const children = this.$el.querySelectorAll(".job-tasks-container");
      children.forEach((value) => {
        sortlist += value.id + ",";
      });
      form.append("sortlist", sortlist);
      this.$axios
        .post(process.env.VUE_APP_ROOT_API + "/v1/tasks/group/sort", form, {
          headers: { "Content-type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          //
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onEnter: function () {
      if (this.addGroupName != "" && this.addGroupName != "Untitled") {
        const job_uid = this.$route.params.uid;
        var form = new FormData();
        form.append("title", this.addGroupName);
        const title = this.addGroupName;
        form.append("job", job_uid);
        this.$axios
          .post(process.env.VUE_APP_ROOT_API + "/v1/tasks/group", form, {
            headers: { "Content-type": "application/x-www-form-urlencoded" },
          })
          .then((response) => {
            this.$toast.add({
              severity: "success",
              summary: "Group Saved",
              detail: title + " has been added",
              life: 3000,
            });
            this.addGroup = false;
            this.addGroupName = "Untitled";
          })
          .catch((error) => {
            this.isLoading = false;
            console.error(error);
          });
      }

      this.addGroup = false;
      this.addGroupName = "Untitled";
    },
    toggleGroup(uid) {
      if (!this.groupToggle.includes(uid)) {
        this.groupToggle.push(uid);
      } else {
        for (var i = this.groupToggle.length - 1; i >= 0; --i) {
          if (this.groupToggle[i] == uid) {
            this.groupToggle.splice(i, 1);
          }
        }
      }
    },
    deleteGroup(group) {
      this.$confirm.require({
        message: "Are you sure you want to delete this group?",
        header: "Confirm Delete",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.$axios
            .delete(
              process.env.VUE_APP_ROOT_API + "/v1/tasks/group/" + group,
              {}
            )
            .then((response) => {
              this.$toast.add({
                severity: "success",
                summary: group.title + " Deleted",
                detail: group.title + " has been deleted",
                life: 3000,
              });
            })
            .catch((error) => {
              console.error(error);
            });
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    deleteTask(uid) {
      this.$confirm.require({
        message: "Are you sure you want to delete this task?",
        header: "Confirm Delete",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.$axios
            .delete(process.env.VUE_APP_ROOT_API + "/v1/tasks/" + uid, {})
            .then((response) => {
              this.$bus.emit("refreshData");
              this.$toast.add({
                severity: "success",
                summary: "Task Deleted",
                detail: "Task has been deleted",
                life: 3000,
              });
            })
            .catch((error) => {
              console.error(error);
            });
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
  },

  computed: {
    toggled(uid) {
      return this.groupToggle.includes(uid);
    },
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
    dragOptions() {
      return {
        ghostClass: "ghost",
      };
    },
    job_uid() {
      return this.$route.params.uid;
    },
  },

  metaInfo() {
    return {
      title: "Job Tasks",
      meta: [{ vmid: "description", name: "description", content: "" }],
    };
  },
};
</script>
<style lang="scss" scoped>
</style>
<template>
  <div class="card pt-2">
    <div
      class="flex flex-wrap sm:justify-content-end justify-content-center align-items-center pb-2"
    >
      <div class="mr-3">
        <FilterSearchButton v-model="filters['global'].value" />
      </div>
      <div class="mr-3">
        <Button
          label="New Department"
          @click="$store.dispatch('setSliderView', 'DepartmentSlider')"
          icon="las la-plus"
        />
      </div>
    </div>

    <div>
      <DataTable
        responsiveLayout="scroll"
        :rowClass="rowClass"
        :value="departments"
        editMode="row"
        :filters.sync="filters"
        filterDisplay="menu"
        dataKey="uid"
        :editingRows.sync="editingRows"
        @row-edit-cancel="onRowEditCancel"
        @row-edit-save="saveRow"
        :paginator="true"
        :rows="30"
        :rowsPerPageOptions="[30, 50, 100]"
      >
        <template #empty>
          <Button
            v-if="!filters['global'].value"
            label="New Department"
            class="p-button-text p-button-secondary"
            @click="$store.dispatch('setSliderView', 'DepartmentSlider')"
            icon="las la-plus"
          />
          <div v-else>No Results</div></template
        >

        <Column
          sortable
          field="department_name"
          filterField="department_name"
          header="Department"
        >
          <template #body="slotProps">
            <Skeleton width="60%" v-if="isLoading"></Skeleton>
            <template v-else>
              <span
                class="cursor-pointer"
                @click="
                  $store.dispatch('setSelectedObject', slotProps.data);
                  $store.dispatch('setSliderView', 'DepartmentSlider');
                "
              >
                <!-- @click="editRow(slotProps.data, departments)" -->
                {{ slotProps.data.department_name }}
              </span>
            </template>
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by department name"
            />
          </template>
          <template #editor="slotProps">
            <InputText
              placeHolder="Name"
              v-model="slotProps.data.department_name"
            />
          </template>
        </Column>

        <Column field="rate" header="Default Hourly Rate">
          <template #body="slotProps">
            <Skeleton width="35%" v-if="isLoading"></Skeleton>
            <template v-else>
              {{ slotProps.data[slotProps.column.field] }}
            </template>
          </template>
          <template #editor="slotProps">
            <InputText
              placeHolder="Rate"
              v-model="slotProps.data[slotProps.column.field]"
            />
          </template>
        </Column>

        <Column field="gl_sales" header="GL Sales Code (Tasks)">
          <template #body="slotProps">
            <Skeleton width="35%" v-if="isLoading"></Skeleton>
            <template v-else>
              {{ slotProps.data[slotProps.column.field] }}
            </template>
          </template>
          <template #editor="slotProps">
            <InputText
              placeHolder="Sales Code"
              v-model="slotProps.data[slotProps.column.field]"
            />
          </template>
        </Column>
        <Column field="gl_cost_of_sales" header="GL Cost of Sales Code (Costs)">
          <template #body="slotProps">
            <Skeleton width="35%" v-if="isLoading"></Skeleton>
            <template v-else>
              {{ slotProps.data[slotProps.column.field] }}
            </template>
          </template>
          <template #editor="slotProps">
            <InputText
              placeHolder="Cost of Sales Code"
              v-model="slotProps.data[slotProps.column.field]"
            />
          </template>
        </Column>

        <Column sortable field="active" header="Active">
          <template #body="slotProps">
            <Skeleton
              width="4rem"
              height="2rem"
              borderRadius="16px"
              v-if="isLoading"
            ></Skeleton>
            <template v-else>
              <AActiveSwitch
                @change="saveRow(slotProps.data)"
                v-model="slotProps.data[slotProps.column.field]"
              />
            </template>
          </template>
        </Column>
        <Column bodyStyle="text-align:right" :styles="{ width: '1rem' }">
          <template #body="slotProps">
            <div
              class="flex justify-content-end align-items-center invisible cursor-pointer"
            >
              <template v-if="editingRow !== slotProps.data.uid">
                <i
                  @click="
                    $store.dispatch('setSelectedObject', slotProps.data);
                    $store.dispatch('setSliderView', 'DepartmentSlider');
                  "
                  :class="getEditClass(rowInEdit)"
                ></i>
                <!-- @click="editRow(slotProps.data, departments)" -->

                <i
                  @click="
                    deletePrompt(
                      slotProps.data.department_name,
                      '/v1/departments/' + slotProps.data.uid
                    )
                  "
                  :class="getDeleteClass(rowInEdit)"
                />
              </template>
              <template v-else>
                <i
                  class="las la-check i-20 mr-3 grey"
                  @click="saveRow(slotProps.data)"
                />
                <i
                  class="las la-times i-20 grey"
                  @click="onRowEditCancel(slotProps.data, departments)"
                />
              </template>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <!-- 
    <Button
      label="New Department"
      class="p-button-text p-button-secondary mt-5"
      @click="$store.dispatch('setSliderView', 'DepartmentSlider')"
      icon="las la-plus"
    /> -->
  </div>
</template>

<script>
import { state, fetchDepartments } from "../../../services/data_service.js";
import { FilterMatchMode } from "primevue/api";
import Vue from "vue";
export default {
  data() {
    return {
      isLoading: true,
      drag: false,
      originalRows: [],
      editingRows: [],
      editingRow: null,
      rowInEdit: false,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        department_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  computed: {
    accounts() {
      return state.accounts;
    },
    departments() {
      return state.departments;
    },
    countDep() {
      return this.departments.length;
    },
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
  },
  async mounted() {
    if (!this.departments) {
      state.departments = [{}, {}, {}, {}, {}, {}];
    }
    await this.loadData();
    this.isLoading = false;
  },
  methods: {
    getEditClass(t) {
      return t === true ? "hidden" : "las la-pen i-20 mr-3 grey";
    },
    getDeleteClass(t) {
      return t === true ? "hidden" : "las la-trash i-20 grey";
    },
    rowClass() {
      return "row-accessories";
    },
    async loadData() {
      await fetchDepartments();
    },

    editRow(r, arr) {
      this.rowInEdit = true;
      this.editingRow = r.uid;
      this.editingRows.push(r);
      var index = arr.indexOf(r);
      this.originalRows[index] = {
        ...arr[index],
      };
    },
    saveRow(r) {
      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API + "/v1/departments/" + r.uid,
          JSON.stringify(r),
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          this.rowInEdit = false;
          this.editingRow = null;
          var i2 = this.editingRows.indexOf(r);
          this.editingRows.splice(i2, 1);
          this.$toast.add({
            severity: "success",
            summary: "Department Updated",
            life: 3000,
          });

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },

    onRowEditCancel(r, arr) {
      this.rowInEdit = false;
      var index = arr.indexOf(r);
      Vue.set(arr, index, this.originalRows[index]);
      this.editingRow = null;
      var i2 = this.editingRows.indexOf(r);
      this.editingRows.splice(i2, 1);
    },
  },
  metaInfo: {
    title: "Settings | Departments",

    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: "" },
      { name: "keywords", content: "" },
    ],
  },
};
</script>


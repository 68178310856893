<template>
  <div :class="containerClass" @click="onWrapperClick" v-if="hasAuth">
    <AppTopBar @menu-toggle="onMenuToggle" />

    <div class="layout-sidebar" @click="onSidebarClick">
      <div class="layout-topbar-logo cursor-pointer" style="height: 46px">
        <div>
          <img
            v-show="!miniLogo"
            style="width: 140px; margin-left: 25px; margin-top: 15px"
            @click="onMenuToggle"
            fit="contain"
            alt="Logo"
            src="../assets/logo_new.svg"
          />
          <img
            v-show="miniLogo"
            fit="contain"
            @click="onMenuToggle"
            alt="Logo"
            style="margin-top: 17px; margin-left: 25.5px; height: 19px"
            src="../assets/a-icon.svg"
          />
        </div>
      </div>
      <AppMenu
        :key="permissions"
        :model="menu"
        @menuitem-click="onMenuItemClick"
      />
    </div>
    <Toast />
    <ConfirmDialog />
    <transition name="fade-up">
      <Popup :componentName="popupDialog" />
    </transition>
    <Slider :componentName="sliderView" />
    <EstimateCostTypeSlider :componentName="estimateCostTypeSliderView" />

    <div class="layout-main-container">
      <div class="layout-main">
        <transition name="slide-fade">
          <router-view />
        </transition>
      </div>
      <AppFooter />
    </div>

    <transition name="layout-mask">
      <div class="layout-mask p-component-overlay" v-if="mobileMenuActive" />
    </transition>
  </div>
</template>

<script>
import Slider from "@/application/Slider/views/Slider";
import EstimateCostTypeSlider from "@/application/Slider/views/EstimateCostTypeSlider";
import Popup from "@/application/Popup/views/Popup";
import AppTopBar from "./AppTopBar.vue";
import AppMenu from "./AppMenu.vue";
import AppFooter from "./AppFooter.vue";
import {
  fetchUser,
  fetchAccounts,
  state,
  fetchTaskTags,
} from "../services/data_service.js";
export default {
  timers: {
    tick: { time: 1000, autoStart: true, repeat: true, immediate: true },
  },
  data() {
    return {
      time: null,
      layoutMode: "static",
      overlayMenuActive: false,
      miniLogo: this.staticMenuInactive == true,
      logoSrc: "../assets/logo_new.svg",
      mobileMenuActive: false,
      menu: [
        {
          label: "Jobs",
          to: "/jobs/active",
          icon: "la la-suitcase",
        },
        {
          label: "My Desk",
          icon: "la la-coffee",
          to: "/desk",
        },
        {
          label: "Task Schedule",
          icon: "la la-stream",
          to: "/task-schedule",
          permission: "admin",
        },
        {
          label: "Admin",
          permission: "admin",
        },
        {
          label: "Finance",
          icon: "la la-wallet",
          to: "/finance/invoices",
          permission: "admin",
        },
        {
          label: "Clients",
          icon: "la la-user-tie",
          to: "/clients",
          permission: "admin",
        },
        {
          label: "Suppliers",
          icon: "la la-users",
          to: "/suppliers",
          permission: "admin",
        },
        {
          label: "Settings",
          icon: "la la-fingerprint",
          to: "/settings",
          permission: "admin",
        },
      ],
      hasAuth: false,
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
    selectedJobUid() {
      state.tasksJob = null;
      state.jobCosts = null;
      state.jobItems = null;
      state.jobEstimate = null;
      state.invoices = null;
      state.jobNotes = null;
      state.jobDocuments = null;
    },
  },
  methods: {
    checkSideBar() {
      return "noScroll";
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.$store.dispatch("toggleStaticMenuInactive");
        }

        setTimeout(
          () => {
            this.miniLogo = this.staticMenuInactive == true;
          },
          this.staticMenuInactive == true ? 300 : 150
        );
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
      }

      return true;
    },

    tick() {
      this.$store.state.currentTime = this.$moment().unix();
    },
  },
  computed: {
    sliderView() {
      return this.$store.getters.sliderView;
    },
    estimateCostTypeSliderView() {
      return this.$store.getters.estimateCostTypeSliderView;
    },
    staticMenuInactive() {
      return this.$store.getters.staticMenuInactive;
    },
    popupDialog() {
      return this.$store.getters.popupDialog;
    },
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },

    logo() {
      return this.$appState.darkTheme
        ? "images/logo-white.svg"
        : "images/logo.svg";
    },
    expire() {
      return this.$store.getters.expire;
    },
    userName() {
      return localStorage.getItem("userName");
    },
    timer() {
      return this.$store.getters.timer;
    },
    timer_active() {
      return this.timer && this.timer.active;
    },
    timer_on() {
      return this.timer_active === "1";
    },
    currentTimer() {
      return this.$store.getters.currentTimer;
    },
    routeName() {
      return this.$route.path;
    },
    user() {
      return state.user;
    },
    selectedJobUid() {
      return state.selectedJobUid;
    },
    permissions() {
      return this.$store.getters.permissions;
    },
  },

  async mounted() {
    if (new Date().getTime() > this.expire * 1000) {
      await this.$store.dispatch("logout");
    } else {
      this.hasAuth = true;
      this.miniLogo = this.staticMenuInactive == true;
      fetchUser();
      fetchTaskTags();
      fetchAccounts();
    }
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    Slider,
    AppFooter: AppFooter,
    Popup,
    EstimateCostTypeSlider,
  },
  metaInfo() {
    return {
      titleTemplate: this.currentTimer
        ? this.secToHMS(this.currentTimer) + " | %s | Agencydesk"
        : "%s | Agencydesk",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        { vmid: "description", name: "description", content: "AgencyDesk" },
      ],
      link: [{ rel: "icon", type: "image/x-icon", href: "/favicon.ico" }],
    };
  },
};
</script>


<template>
  <div class="switching-container">
    <div class="loading-spinner"></div>
    <p class="switching-text">Switching Accounts...</p>
  </div>
</template>

<script>
import {
  fetchAccounts,
  fetchJobs,
  fetchTaskTags,
  fetchUser,
} from "../../../services/data_service";
export default {
  name: "SwitchAccount",
  data() {
    return {
      uid: null,
    };
  },
  mounted() {
    this.uid = this.$route.params.uid;
    if (!this.uid) {
      this.$router.push("/");
    }
    this.switchProfile();
  },
  methods: {
    switchProfile() {
      this.$axios
        .get(process.env.VUE_APP_ROOT_API + "/v1/accounts/switch/" + this.uid)
        .then(async (res) => {
          await this.$store.dispatch("setToken", res.data.data);

          await Promise.all([
            fetchUser(),
            fetchTaskTags(),
            fetchAccounts(),
            fetchJobs(),
          ]);

          setTimeout(() => {
            //avoid duplicate navigation
            if (this.$route.path !== "/jobs/active") {
              this.$router.replace("/");
            }
            this.$store.dispatch("setTimer", undefined);
            this.$toast.add({
              severity: "success",
              summary: "Account Switched",
              life: 3000,
            });
          }, 1500);
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.switching-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f5f7; /* Light background */
}

.loading-spinner {
  border: 8px solid #e8ecef; /* Light grey */
  border-top: 8px solid #4d7cfe; /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1.5s linear infinite;
  margin-bottom: 20px;
}

.switching-text {
  font-size: 1.5rem;
  font-weight: bold;
  color: #4d7cfe;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
<template>
  <div style="min-width: 321px" :class="setColumnWidth()">
    <div class="flex align-items-center p-3">
      <!-- <i
        @click="expand = !expand"
        class="las cursor-pointer text-22 mr-3"
        :class="!expand ? 'la-plus-square' : 'la-minus-square'"
      /> -->
      <span class="text-black text-20">{{ heading }}</span>

      <div
        class="col flex justify-content-end align-items-center mr-3"
        v-if="tasks"
      >
        <i class="las la-check mr-1" />
        <span class="mr-1">{{ tasks.length }}</span>
        <i class="las la-stopwatch mr-1" />
        <span>{{ timeRemaining }}</span>
      </div>
    </div>

    <simplebar class="grey-background-custom p-3 border-round" v-full-height>
      <FilterSearchButton
        v-model="search"
        v-if="heading !== 'In Review' && heading !== 'For Today'"
        fullWidth
      />

      <template v-if="filteredTasks?.length === 0">
        <div class="card text-center text-black">
          {{ getNoTasksMessage() }}
        </div>
      </template>
      <draggable
        delay="250"
        :delay-on-touch-only="true"
        style="min-height: 200px"
        :animation="140"
        v-model="tasks"
        :group="{ name: 'people' }"
        :id="listId"
        @end="onEnd"
        @remove="handleUpdate"
        @update="handleUpdate"
        @add="handleUpdate"
        :move="onMoveCallback"
        @start="onStart"
      >
        <TaskCard
          :id="task.uid"
          :task="task"
          :ref="task.uid"
          class="mb-3"
          :expand="expand"
          :listId="listId"
          v-for="(task, id) in filteredTasks"
          :key="id"
          @toggleMenu="toggleMenu"
        />
      </draggable>
    </simplebar>
  </div>
</template>

<script>
import { state } from "../../../services/data_service.js";
import draggable from "vuedraggable";
import TaskCard from "@/application/Tasks/components/TaskCard";
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
export default {
  name: "DeskColumn",
  data() {
    return {
      expand: false,
      selectedId: null,
      search: "",
    };
  },
  props: {
    notasks: String,
    isLoading: Boolean,
    heading: String,
    listId: Number,
    screenHeight: Number,
    value: {
      required: true,
    },
  },
  components: {
    TaskCard,
    simplebar,
    draggable,
  },
  methods: {
    setColumnWidth() {
      if (this.myTasks) {
        if (this.myTasks.review.length < 1) {
          return "col-12 bg-grey-custom md:col-4";
        }
        return "col-12 bg-grey-custom md:col-3";
      }
      return null;
    },
    toggleMenu(event) {
      this.$emit("toggleMenu", event);
    },
    onMoveCallback(evt) {
      if (evt.to.id == 4) {
        this.$emit(
          "ghostDesk",
          this.tasks.find((a) => a.uid === this.selectedId)
        );
      } else {
        this.$emit("ghostDesk", null);
      }
    },
    handleUpdate() {
      this.$store.dispatch("setDeskOrder", {
        id: this.listId,
        order: this.tasks.map((a) => a.uid),
      });
    },
    onEnd(ev) {
      //1 - My Tasks
      //2 - Today
      //3 - Complete
      //4 - Desk
      //5 - Review

      switch (ev.from.id) {
        case "1":
          switch (ev.to.id) {
            case "2":
              this.allocateTime();
              break;
            case "3":
              this.toggleComplete();
              break;
            case "4":
              this.toggleTimer();
              break;
            case "5":
              this.toggleInReview();
          }
          break;
        case "2":
          switch (ev.to.id) {
            case "1":
              this.allocateTime();
              break;
            case "3":
              this.toggleComplete();
              break;
            case "4":
              this.toggleTimer();
              break;
            case "5":
              this.toggleInReview();
          }
          break;

        case "3":
          switch (ev.to.id) {
            case "1":
            case "2":
              var t = this.myTasks.completed.find(
                (a) => a.uid == this.selectedId
              );
              if (
                (t.today === "0" && ev.to.id === "2") ||
                (t.today === "1" && ev.to.id === "1")
              ) {
                this.allocateTime();
              }

              this.toggleComplete();
              break;
            case "4":
              this.toggleComplete();
              this.toggleTimer();
              break;
            case "5":
              this.toggleComplete();
              this.toggleInReview();
          }
          break;
        case "5":
          switch (ev.to.id) {
            case "1":
            case "2":
              var r = this.myTasks.review.find((a) => a.uid == this.selectedId);
              if (
                (r.today === "0" && ev.to.id === "2") ||
                (r.today === "1" && ev.to.id === "1")
              ) {
                this.allocateTime();
              }

              this.toggleInReview();
              break;
            case "3":
              this.toggleComplete();
              this.toggleInReview();
              break;
            case "4":
              this.toggleInReview();
              this.toggleTimer();
              break;
          }
      }
    },
    onStart(ev) {
      // this.$store.dispatch("setIsDragging", true);
      this.selectedId = ev.item.id;
    },
    allocateTime() {
      var url =
        process.env.VUE_APP_ROOT_API +
        "/v1/tasks/" +
        this.selectedId +
        "/allocate?date=" +
        this.buildShortDate(new Date()) +
        "&user=" +
        this.user_uid;

      this.$axios.get(url);
    },
    toggleTimer() {
      this.$axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "/v1/tasks/" +
            this.selectedId +
            "/timer/toggle",
          {}
        )
        .then((response) => {
          this.$bus.emit("refreshData");
          this.$store.dispatch("setTimer", response.data.data);
          this.isLoading = false;
          this.users = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    toggleComplete() {
      this.$axios
        .get(
          process.env.VUE_APP_ROOT_API +
            `/v1/tasks/${this.selectedId}/complete`,
          {}
        )
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary:
              "Task marked as " +
              (response.data.data === 1 ? "completed" : "incomplete"),

            life: 2000,
          });
        });
    },
    toggleInReview() {
      this.$axios
        .get(
          process.env.VUE_APP_ROOT_API + `/v1/tasks/${this.selectedId}/review`,
          {}
        )
        .then((response) => {
          if (response.data.data === 1) {
            this.$toast.add({
              severity: "success",
              summary: "Task sent for review",
              life: 2000,
            });
          }
        });
    },
    getNoTasksMessage() {
      if (this.search) {
        return "No search results";
      }

      switch (this.heading) {
        case "My Tasks":
          return "You have no assigned tasks.";
        case "For Today":
          return "You have no tasks scheduled for today.";
        case "Completed":
          return "No completed tasks";
        case "In Review":
          return "You have tasks sent for review";
      }
    },
  },
  computed: {
    user_uid() {
      return this.$store.getters.user.uid;
    },
    myDesk() {
      return state.myDesk;
    },
    myTasks() {
      return state.myTasks;
    },
    tasks: {
      get() {
        return this.value;
      },
      set(tasks) {
        this.$emit("input", tasks);
      },
    },

    filteredTasks() {
      return this.search
        ? this.tasks.filter((task) => {
            return (
              task.title?.toLowerCase().includes(this.search.toLowerCase()) ||
              task.task_type_name
                ?.toLowerCase()
                .includes(this.search.toLowerCase()) ||
              task.client_name
                ?.toLowerCase()
                .includes(this.search.toLowerCase())
            );
          })
        : this.tasks;
    },

    timeRemaining() {
      var s = 0;
      if (this.heading === "For Today") {
        this.tasks.forEach((a) => {
          s += this.convertH2M(this.get_today_work_hours(a, this.user_uid));
        });
      } else {
        this.tasks.forEach((task) => {
          var user = task.users.find((a) => a.uid === this.user_uid);
          if (user && user.estimated_hours) {
            s += this.convertH2M(user.estimated_hours);
          } else {
            s += this.convertH2M(task.estimated_hours);
          }
        });
      }
      var hours = s / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (
        (rhours < 10 ? "0" + rhours : rhours) +
        ":" +
        (rminutes < 10 ? "0" + rminutes : rminutes)
      );
    },
  },
  mounted() {},
  watch: {},
};
</script>


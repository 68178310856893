<template>
  <div class="m-r-24 m-t-6">
    <Button
      class="
        agencydesk-button-add
        button
        p-button-secondary p-button-outlined
        m-12
      "
      :label="label"
      @click="handleClick"
      :icon="icon"
    />
  </div>
</template>

<script>
export default {
  props: {
    clickAction: {
      type: Function,
    },
    label: {
      default: null,
    },
    icon: {
      icon: null,
    },
  },
  methods: {
    handleClick() {
      this.$emit("click", this.clickAction);
    },
  },
};
</script>

<style>
</style>
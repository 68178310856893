<template>
  <div class="job-menu col-2">
    <router-link
      :to="{ path: '/settings/users' }"
    >
      <div
        class="job-menu--item menu-items"
        :class="{ active: $route.name == 'settings--users' }"
      >
        <i class="la la-user-friends" /> Tasks
      </div>
    </router-link>
    <router-link
      :to="{ path: '/settings/structure' }"
    >
      <div
        class="job-menu--item menu-items"
        :class="{ active: $route.name == 'settings--structure' }"
      >
        <i class="la la-folder-open" /> Team Members
      </div>
    </router-link>
    <router-link
      :to="{ path: '/settings/tasks' }"
    >
      <div
        class="job-menu--item menu-items"
        :class="{ active: $route.name == 'settings--tasks' }"
      >
        <i class="la la-comments" /> Automation
      </div>
    </router-link>
    <router-link
      :to="{ path: '/settings/costs' }"
    >
      <div
        class="job-menu--item menu-items"
        :class="{ active: $route.name == 'settings--costs' }"
      >
        <i class="la la-suitcase" /> Meetings
      </div>
    </router-link>
    <router-link
      :to="{ path: '/settings/templates' }"
    >
      <div
        class="job-menu--item menu-items"
        :class="{ active: $route.name == 'settings--templates' }"
      >
        <i class="la la-file-invoice-dollar" /> Notes
      </div>
    </router-link>
    <router-link
      :to="{ path: '/settings/notifications' }"
    >
      <div
        class="job-menu--item menu-items"
        :class="{
          active: $route.name == 'settings--notifications',
        }"
      >
        <i class="la la-balance-scale-left" /> Documents
      </div>
    </router-link>
    <div class="link-group-label job-menu--item p-4 font-light menu-items">
      Finance
    </div>
    <router-link
      :to="{ path: '/finance/estimates' }"
    >
      <div
        class="job-menu--item menu-items"
        :class="{ active: $route.name == 'settings--recurring' }"
      >
        <i class="la la-balance-scale-left" /> Estimates
      </div>
    </router-link>
    <router-link
      :to="{ path: '/finance/estimates/estimates' }"
    >
      <div
        class="job-menu--item menu-items"
        :class="{ active: $route.name == 'settings--recurring' }"
      >
        <i class="la la-balance-scale-left" /> Costs
      </div>
    </router-link>
    <router-link
      :to="{ path: '/settings/recurring' }"
    >
      <div
        class="job-menu--item menu-items"
        :class="{ active: $route.name == 'settings--recurring' }"
      >
        <i class="la la-balance-scale-left" /> Invoices
      </div>
    </router-link>
    <router-link
      :to="{ path: '/settings/recurring' }"
    >
      <div
        class="job-menu--item menu-items"
        :class="{ active: $route.name == 'settings--recurring' }"
      >
        <i class="la la-balance-scale-left" /> Purchase Orders
      </div>
    </router-link>
    <router-link
      :to="{ path: '/settings/recurring' }"
    >
      <div
        class="job-menu--item menu-items"
        :class="{ active: $route.name == 'settings--recurring' }"
      >
        <i class="la la-balance-scale-left" /> Quote Requests
      </div>
    </router-link>
    <router-link
      :to="{ path: '/settings/recurring' }"
    >
      <div
        class="job-menu--item menu-items"
        :class="{ active: $route.name == 'settings--recurring' }"
      >
        <i class="la la-balance-scale-left" /> Expenses
      </div>
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
</style>
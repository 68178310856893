<template>
  <div class="container-padding">
    <div class="flex col estimates-container">
      <side-menu />
      <div class="col">
        <div class="flex border-bottom" style="height: 60px">
          <i class="las la-arrow-left text-20 text-color pt-3 pr-1" />
          <h2 class="col-7">
            Cost Estimate <span class="font-light">1144</span>
          </h2>
          <p class="pt-3 col-">
            Status: <span>{{ status }}</span>
          </p>
          <i class="las la-ellipsis-h text-20 pt-3" />
        </div>
        <form>
          <div class="flex container-padding">
            <div class="flex-column col-5 mr-6">
              <div class="flex p-3">
                <p class="col-3">Title</p>
                <InputText
                  v-model="title"
                  placeholder="Holiday & Christmas Promo Email Campaign"
                  class="width"
                />
              </div>
              <div class="flex p-3">
                <p class="col-3">Date</p>
                <Calendar v-model="date" showIcon="true" class="width" />
              </div>
              <div class="flex p-3">
                <p class="col-3">Attention</p>
                <Dropdown
                  v-model="attention"
                  :options="person"
                  optionLabel="name"
                  placeholder="Jade Summers"
                  class="width"
                />
              </div>
              <div class="flex p-3">
                <p class="col-3">Deposit Req</p>
                <Dropdown
                  v-model="deposit"
                  :options="isBillable"
                  optionLabel="billable"
                  placeholder="Billable"
                  class="width"
                />
              </div>
            </div>
            <div class="col flex-column">
              <h2 class="pb-3">Cipla {{ location }}</h2>
              <div v-for="i in adress" :key="i.adress" class="pb-5">
                <p>Unit {{ i.houseNumber }}, {{ i.adress }}</p>
                <p>{{ i.road }}</p>
                <p>{{ i.city }}, {{ i.zipCode }}</p>
              </div>
              <div class="pb-4">
                <p>VAT No: {{ vat }}</p>
                <p>Billing Tier: {{ tier }}</p>
              </div>
              <div class="font-light">
                <p>Issued by {{ issued }}</p>
                <p>Approved by {{ approved }}</p>
              </div>
            </div>
          </div>
          <div class="container-padding">
            <div class="flex">
              <p class="col-1 ml-4 mr-3">Notes</p>
              <Textarea v-model="notes" rows="5" cols="30" />
            </div>
          </div>
          <div class="container-padding" />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import SideMenu from "../components/SideMenu.vue";

export default {
  components: {
    SideMenu,
  },
  data() {
    return {
      issued: "Jane Austin",
      approved: "Jane Austin",
      tier: "10% Discount",
      vat: "09872376514",
      status: "Draft",
      title: "",
      date: null,
      attention: null,
      isBillable: [
        {
          billable: "Yes",
        },
        {
          billable: "No",
        },
      ],
      person: [
        {
          name: "Jade Summers",
        },
      ],
      deposit: null,
      notes: "",
      location: "South Africa",
      adress: [
        {
          houseNumber: "3",
          adress: "The Block Business Park",
          road: "134 Marine Rd",
          city: "Cape Town",
          zipCode: "8001",
        },
      ],
    };
  },
};
</script>

<style scoped>
.width {
  min-width: 75%;
}
</style>
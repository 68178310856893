<template>
  <div class="inline-flex p-2 align-items-center mb-3" style="z-index: 3">
    <div
      v-if="draft > 0"
      :class="
        'mr-4 p-1  ' +
        (filters.indexOf('draft') >= 0
          ? 'bottom-border-grey'
          : 'bottom-border-invisible')
      "
      @click="filter('draft')"
    >
      <p class="font-light cursor-pointer">
        Draft
        <Badge style="background-color: #e8ecef" class="ml-1" :value="draft" />
      </p>
    </div>
    <div
      v-if="approved > 0"
      :class="
        'mr-4 p-1 ' +
        (filters.indexOf('approved') >= 0
          ? 'bottom-border-warning'
          : 'bottom-border-invisible')
      "
      @click="filter('approved')"
    >
      <p class="font-light cursor-pointer">
        Active<Badge
          style="background-color: #6dd230"
          class="ml-1"
          :value="approved"
        />
      </p>
    </div>
    <div
      v-if="invoiced > 0"
      :class="
        'mr-4 p-1 ' +
        (filters.indexOf('invoiced') >= 0
          ? 'bottom-border-positive'
          : 'bottom-border-invisible')
      "
      @click="filter('invoiced')"
    >
      <p class="font-light cursor-pointer">
        Invoiced<Badge
          style="background-color: #ffab2b"
          class="ml-1"
          :value="invoiced"
        />
      </p>
    </div>
    <div
      v-if="completed > 0"
      :class="
        'mr-4 p-1  ' +
        (filters.indexOf('completed') >= 0
          ? 'bottom-border-pink'
          : 'bottom-border-invisible')
      "
      @click="filter('completed')"
    >
      <p class="font-light cursor-pointer">
        Completed
        <Badge
          style="background-color: #fe4d97"
          class="ml-1"
          :value="completed"
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    draft: {
      type: Number,
    },
    approved: {
      type: Number,
      default: 0,
    },
    invoiced: {
      type: Number,
      default: 0,
    },
    completed: {
      type: Number,
      default: 0,
    },
    filters: {
      type: Array,
    },
  },
  methods: {
    filter(f) {
      this.$emit("filter", f);
    },
  },
};
</script>

<style>
</style>
<template>
  <Button
    @click="connectXero()"
    :disabled="isXeroLoading"
    style="
      width: 200px;
      height: 38px;
      background: #1ab4d7;
      border-color: #1ab4d7;
    "
  >
    <div style="width: 20px" v-if="isXeroLoading">
      <span
        style="margin-left: 70px"
        class="pi pi-spinner pi-spin p-button-icon p-button-icon-left"
      ></span>
    </div>
    <div
      v-else
      class="flex align-items-center justify-content-center"
      style="width: 200px"
    >
      <div>Sync To Xero</div>
      <!-- <div>
        <img
          src="../assets/xero/xero.svg"
          alt="Xero Icon"
          style="width: 48px; height: 48px; top: 1px; position: relative"
        />
      </div> -->
    </div>
  </Button>
</template>

<script>
export default {
  data() {
    return {
      isXeroLoading: false,
    };
  },
  methods: {
    connectXero() {
      this.isXeroLoading = true;
      this.$axios
        .get(process.env.VUE_APP_ROOT_API + "/v1/finance/xero")
        .then((response) => {
          window.open(response.data.data, "_blank");
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Error connecting to Xero",
            life: 3000,
          });
        })
        .finally(() => {
          setTimeout(() => {
            this.isXeroLoading = false;
          }, 1000);
        });
    },
  },
};
</script>

<style>
</style>
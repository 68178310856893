var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inline-flex p-2 align-items-center mb-3",staticStyle:{"z-index":"3"}},[(_vm.draft > 0)?_c('div',{class:'mr-4 p-1  ' +
      (_vm.filters.indexOf('draft') >= 0
        ? 'bottom-border-grey'
        : 'bottom-border-invisible'),on:{"click":function($event){return _vm.filter('draft')}}},[_c('p',{staticClass:"font-light cursor-pointer"},[_vm._v(" Draft "),_c('Badge',{staticClass:"ml-1",staticStyle:{"background-color":"#e8ecef"},attrs:{"value":_vm.draft}})],1)]):_vm._e(),(_vm.approved > 0)?_c('div',{class:'mr-4 p-1 ' +
      (_vm.filters.indexOf('approved') >= 0
        ? 'bottom-border-warning'
        : 'bottom-border-invisible'),on:{"click":function($event){return _vm.filter('approved')}}},[_c('p',{staticClass:"font-light cursor-pointer"},[_vm._v(" Active"),_c('Badge',{staticClass:"ml-1",staticStyle:{"background-color":"#6dd230"},attrs:{"value":_vm.approved}})],1)]):_vm._e(),(_vm.invoiced > 0)?_c('div',{class:'mr-4 p-1 ' +
      (_vm.filters.indexOf('invoiced') >= 0
        ? 'bottom-border-positive'
        : 'bottom-border-invisible'),on:{"click":function($event){return _vm.filter('invoiced')}}},[_c('p',{staticClass:"font-light cursor-pointer"},[_vm._v(" Invoiced"),_c('Badge',{staticClass:"ml-1",staticStyle:{"background-color":"#ffab2b"},attrs:{"value":_vm.invoiced}})],1)]):_vm._e(),(_vm.completed > 0)?_c('div',{class:'mr-4 p-1  ' +
      (_vm.filters.indexOf('completed') >= 0
        ? 'bottom-border-pink'
        : 'bottom-border-invisible'),on:{"click":function($event){return _vm.filter('completed')}}},[_c('p',{staticClass:"font-light cursor-pointer"},[_vm._v(" Completed "),_c('Badge',{staticClass:"ml-1",staticStyle:{"background-color":"#fe4d97"},attrs:{"value":_vm.completed}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <Dropdown
      v-if="profiles?.length > 1"
      v-model="selectedProfile"
      :options="profiles"
      dataKey="uid"
      optionLabel="name"
      @change="switchProfile"
      class="custom-dropdown"
    >
    </Dropdown>
    <div v-else style="font-size: 14px" class="ml-2">
      {{ selectedProfile?.name }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      profiles: null,
      selectedProfile: null,
    };
  },
  mounted() {
    this.getProfiles();
    this.checkDispatchRenderGetProfile();
  },
  methods: {
    checkDispatchRenderGetProfile() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === "setRecentlySavedProfile") {
          this.getProfiles();
        }
      });
    },
    getProfiles() {
      this.$axios
        .get(process.env.VUE_APP_ROOT_API + "/v1/accounts/profiles")
        .then((res) => {
          this.profiles = res?.data?.data;
          this.selectedProfile = this.profiles.find((a) => a.selected === true);
        });
    },
    switchProfile() {
      this.$router.replace("/switch-account/" + this.selectedProfile.uid);
    },
  },
};
</script>

<style scoped>
.p-dropdown {
  border: none;
  background: none;
}

.profile-switch {
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 999;
}

.spinner-div {
  position: fixed;
  top: 50%;
  left: 50%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the color and opacity as needed */
  z-index: 1; /* Ensure the overlay is on top of the content */
}
</style>
<template>
  <div class="flex">
    <div class="col-6 p-0">
      <Complete></Complete>
    </div>
    <div class="col-6 p-0">
      <CompleteLanding></CompleteLanding>
    </div>
  </div>
</template>

<script>
import Complete from "../components/CompleteForm.vue";
import CompleteLanding from "../components/CompleteLanding.vue";
export default {
  components: { Complete, CompleteLanding },
};
</script>
<template>
  <div>
    <div class="p-inputgroup align-items-center">
      <Button
        :label="
          computedTimeLabel !== label ? computedTimeLabel : 'Set ' + label
        "
        @click="toggleOverlay"
        :disabled="disabled"
        style="width: 100%"
        :class="
          'p-button-gray-outline ' +
          (computedTimeLabel == label
            ? 'p-button-color-light-gray'
            : 'p-button-color-gray')
        "
      />
    </div>

    <!-- Time Picker Dialog -->
    <OverlayPanel
      ref="timeModal"
      appendTo="body"
      style="width: 300px"
      @hide="handleHide"
    >
      <InputNumber
        v-model="hours"
        style="width: 50%"
        mode="decimal"
        showButtons
        class="button-rounded-left-border"
        buttonLayout="vertical"
        decrementButtonClass="p-button-secondary p-button-text"
        incrementButtonClass="p-button-secondary p-button-text"
        incrementButtonIcon="pi pi-chevron-up"
        decrementButtonIcon="pi pi-chevron-down"
        :min="0"
      />

      <InputNumber
        v-model="minutes"
        mode="decimal"
        style="width: 50%"
        showButtons
        class="button-rounded-right-border"
        :step="step"
        buttonLayout="vertical"
        decrementButtonClass="p-button-secondary p-button-text"
        incrementButtonClass="p-button-secondary p-button-text"
        incrementButtonIcon="pi pi-chevron-up"
        decrementButtonIcon="pi pi-chevron-down"
      />

      <div class="flex flex-wrap gap-2 justify-content-around">
        <Button
          :label="s.label"
          style="width: 30%"
          class="p-button-primary p-button-outlined p-button-hover-primary"
          v-for="s in suggestedTimes"
          :key="s.label"
          @click="setQuickTime(s.value)"
        />
      </div>
    </OverlayPanel>
  </div>
</template>

<script>
export default {
  props: {
    time: {
      type: String,
      default: "01:00",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Estimated Time",
    },
  },
  mounted() {
    if (!this.time) {
      this.$emit("update", "01:00");
    }
  },
  data() {
    return {
      showDialog: false,
      hours: 0,
      minutes: 0,
      step: 5,
      suggestedTimes: [
        { label: "15mins", value: 15 },
        { label: "30mins", value: 30 },
        { label: "45mins", value: 45 },
        { label: "2hrs", value: 120 },
        { label: "3hrs", value: 180 },
        { label: "4hrs", value: 240 },
        { label: "6hrs", value: 360 },
        { label: "8hrs", value: 480 },
        { label: "12hrs", value: 720 },
      ],
    };
  },
  computed: {
    computedTimeLabel() {
      return this.time ?? `${this.label}`;
    },
  },
  watch: {
    time: {
      immediate: true,
      handler(newTime) {
        if (!newTime) return;

        // Initialize hours and minutes based on passed time
        const [h, m] = newTime.split(":").map(Number);
        this.hours = h || 0;
        this.minutes = m || 0;
      },
    },
    minutes(newMinutes, oldMinutes) {
      if (newMinutes >= 60) {
        if (newMinutes - oldMinutes !== this.step) {
          this.minutes = oldMinutes;
          return;
        } else {
          this.hours += 1;
          this.minutes = newMinutes - 60;
        }
      } else if (newMinutes < 0) {
        if (this.hours == 0) {
          this.minutes = 0;
          return;
        }

        this.hours -= 1;
        this.minutes = 60 + newMinutes;
      }
    },
  },
  methods: {
    toggleOverlay(event) {
      this.$refs.timeModal.toggle(event);
    },
    setQuickTime(minutes) {
      this.hours = Math.floor(minutes / 60);
      this.minutes = minutes % 60;
      this.updateTime();
      this.toggleOverlay();
    },
    confirmTime() {
      this.updateTime();
      this.$refs.timeModal.hide();
    },
    updateTime() {
      const formattedTime = `${this.hours
        .toString()
        .padStart(2, "0")}:${this.minutes.toString().padStart(2, "0")}`;

      this.$emit("update", formattedTime);
    },
    handleHide() {
      this.updateTime();
    },
  },
};
</script>

<template>
  <div class="flex flex-column">
    <LoginLogo></LoginLogo>
    <div class="flex justify-content-center text-center">
      <div class="card p-6 w-30rem" style="height: 450px">
        <h2 class="mb-5">Login</h2>

        <form
          v-if="!isLoading"
          method="post"
          action="/"
          align="left"
          @submit="submit"
          @keyup.enter="submit"
        >
          <div class="field">
            <label for="email" class="label">Email</label>
            <InputText
              id="email"
              name="email"
              autocomplete="email"
              v-model="email"
              type="email"
              class="w-full mb-2"
              placeholder="Enter your email"
              style="padding: 1rem"
            />
          </div>
          <div class="field">
            <label class="label">Password</label>
            <div>
              <InputText
                id="password1"
                type="password"
                v-model="password"
                placeholder="Enter your password"
                class="w-full mb-2"
                style="padding: 1rem"
              ></InputText>
            </div>
            <div align="right">
              <a href="/forgot-password">Forgot Password?</a>
            </div>
          </div>

          <!-- <div class="field-checkbox">
            <Checkbox id="binary" v-model="remember" :binary="true" />
            <label for="binary">Remember me</label>
          </div> -->
          <br />
          <div>
            <Button
              type="submit"
              class="button is-primary w-full"
              label="SIGN IN"
            />
          </div>
          <br />
          <div align="center">
            Don't have an account? <a href="/signup">Sign up here</a>
          </div>
        </form>
        <div v-else class="pt-5">
          <RocketLoad />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      loadingText: "",
      isLoading: false,
      email: "",
      password: "",
      remember: false,
      redirectUrl: null,
    };
  },
  mounted() {
    var redirectUrl = localStorage.getItem("redirectUrl");
    if (redirectUrl) {
      this.redirectUrl = redirectUrl;
    }
  },
  beforeDestroy() {
    localStorage.removeItem("redirectUrl");
  },
  methods: {
    submit(e) {
      e.preventDefault();
      this.isLoading = true;
      var form = new FormData();
      form.append("email", this.email);
      form.append("password", this.password);
      return this.$store
        .dispatch("authenticate", form)
        .then(() => {
          if (this.isAuth == true) {
            if (this.redirectUrl && this.redirectUrl != "/login") {
              this.$router.push(this.redirectUrl);
            } else {
              this.$router.push({ path: "/jobs/active" });
            }
          }
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    userName() {
      var userName = this.$store.getters.user.userName;
      firstName = false;
      if (userName) {
        var names = userName.split(" ");
        var firstName = names[0];
      }
      return firstName;
    },
    isAuth() {
      return this.$store.getters.isAuthenticated;
    },
  },

  metaInfo: {
    title: "Login",
    meta: [{ vmid: "description", name: "description", content: "Login Page" }],
  },
};
</script>

<style scoped>
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}
</style>
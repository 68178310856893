<template>
  <Sidebar
    :showCloseIcon="false"
    @hide="clear"
    :visible.sync="isVisible"
    position="right"
    :autoZIndex="false"
    :class="this.setSliderClass()"
    :style="this.setSliderStyle()"
  >
    <component v-if="componentName !== ''" :is="componentFile" />
  </Sidebar>
</template>

<script>
export default {
  name: "Slider",
  props: {
    componentName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    componentFile() {
      if (this.componentName == "") {
        return "";
      }
      return () => import("../components/" + this.componentName);
    },
  },
  mounted() {
    if (this.componentFile) {
      this.isVisible = true;
    }
  },
  watch: {
    componentName(n) {
      this.isVisible = n != "";
    },
  },
  methods: {
    logStepComponentData() {
      this.componentFile().then((a) => {});
    },
    clear() {
      this.clearSliderContent();
    },
    setSliderClass() {
      if (this.componentName == "NotificationSlider") {
        return "p-sidebar-no-padding p-sidebar-small";
      }
      return "";
    },
    setSliderStyle() {
      return "z-index: 1000 !important;";
    },
  },
};
</script>

<style>
</style>
<template>
  <div class="tasks-schedule card">
    <div
      class="table-scroll"
      data-simplebar="init"
    >
      <div
        class="simplebar-wrapper"
        style="margin: 0px"
      >
        <div class="simplebar-height-auto-observer-wrapper">
          <div class="simplebar-height-auto-observer"></div>
        </div>
        <div class="simplebar-mask">
          <div
            class="simplebar-offset"
            style="right: 0px; bottom: 0px"
          >
            <div
              class="simplebar-content-wrapper"
              tabindex="0"
              role="region"
              aria-label="scrollable content"
              style="height: 100%; overflow: scroll"
            >
              <div
                class="simplebar-content"
                style="padding: 0px"
              >
                <table id="main-table">
                  <thead
                    class="resource-thead"
                    style="border-top: 1px solid rgb(232, 236, 239)"
                  >
                    <th
                      class="col header department-header resource-th"
                      style="
                        border-right: 4px solid rgb(232, 236, 239);
                        min-width: 300px;
                      "
                    ></th>
                    <th
                      v-for="i in 28"
                      :key="i"
                      :colspan="i == 3 || i == 18 ? '2' : '1'"
                      rowspan="2"
                      class="col pb-0 pt-0 font-light month-header"
                      style="z-index: 3; font-size: 12px"
                    >
                      <p
                        class="font-light"
                        style="color: rgb(152, 169, 188)"
                      >
                        <span>
                          <Skeleton
                            v-if="i == 3 || i == 18"
                            width="100%"
                            height="14px"
                          ></Skeleton>
                        </span>
                      </p>
                    </th>
                  </thead>
                  <thead class="resource-thead-days">
                    <th
                      class="header department-header resource-th"
                      style="
                        border-right: 4px solid rgb(232, 236, 239);
                        z-index: 9;
                      "
                    >
                      <div class="flex justify-content-center align-items-center p-3">
                        <Skeleton
                          width="100%"
                          height="40px"
                        ></Skeleton>
                      </div>
                    </th>
                    <th
                      v-for="i in 30"
                      :key="i"
                      class="col text-center p-2 date-cell department-header"
                    >
                      <p
                        class="font-light"
                        style="color: rgb(119, 140, 162); font-size: x-small"
                      >
                        <Skeleton
                          width="50%"
                          height="14px"
                        ></Skeleton>
                      </p>
                      <p
                        class="font-light"
                        style="color: rgb(119, 140, 162); font-size: small"
                      >
                        <Skeleton
                          width="50%"
                          height="14px"
                        ></Skeleton>
                      </p>
                    </th>
                  </thead>
                  <!---->
                  <tbody
                    v-for="i in 3"
                    :key="i"
                  >
                    <tr
                      id="userRow"
                      class="resource-thead-user"
                    >
                      <th class="header user-header resource-th">
                        <div class="flex justify-content-between align-items-center">
                          <div class="col-3 align-self-center flex justify-content-center">
                            <Skeleton
                              shape="circle"
                              width="3rem"
                              height="3rem"
                            />
                          </div>
                          <div class="col cursor-pointer flex flex-column justify-content-start">
                            <div class="flex align-items-start p-1 font-weight-normal">
                              <Skeleton
                                width="50%"
                                height="14px"
                              ></Skeleton>
                            </div>
                            <div class="font-light flex align-items-start p-1 font-smaller">
                              <Skeleton
                                width="55%"
                                height="14px"
                              ></Skeleton>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th
                        v-for="i in 30"
                        :key="i"
                        class="cursor-pointer"
                        style="border-left: 1px solid rgb(232, 236, 239)"
                      >
                        <div
                          class="flex align-items-end cell-half border-bottom-cell"
                          style="--height: 29px"
                        >
                          <div class="col font-light cell-over"></div>
                        </div>
                        <div
                          class="flex align-items-end cell-half"
                          style="--height: 29px"
                        >
                          <div class="col font-light cell-total"></div>
                        </div>
                      </th>
                    </tr>
                    <tr
                      v-for="i in 7"
                      :key="i"
                    >
                      <th
                        class="flex header task-header pr-2 p-1"
                        style="max-width: 300px"
                      >
                        <div class="col-1 align-self-center"></div>
                        <div class="col-7 p-02 justify-content-start align-self-center flex">
                          <Skeleton
                            width="55%"
                            height="14px"
                          ></Skeleton>
                        </div>
                        <div class="col inline-flex align-self-center justify-content-between">
                          <Skeleton
                            width="100% "
                            class="w-3rem p-badge p-component ml-5"
                          ></Skeleton>
                        </div>
                      </th>
                    </tr>
                  </tbody>
                  <!---->
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
<template>
  <div>
    <div class="flex justify-content-between">
      <div class="col flex">
        <div
          @click="
            $router.push('/clients'), $store.dispatch('setSelectedObject', null)
          "
          class="cursor-pointer headerHover"
        >
          <h1>{{ hasUid ? "Clients" : "New Client" }}</h1>
        </div>
        <i v-if="hasUid" class="las la-angle-right text-20 pt-1 pr-2 pl-2" />
        <h1 v-if="client">
          {{ client.name }}
        </h1>
        <div class="text-12 pl-3 pt-2" v-if="client?.temp_client == 1">
          <span
            style="
              border-radius: 6px;
              background: #ffab2b !important;
              color: white;
            "
            class="p-2"
          >
            Prospective</span
          >
        </div>
      </div>
      <div
        class="col-2 flex flex-row justify-content-end align-items-center flex-grow-0 text-right font-light"
      >
        <i class="las la-ellipsis-h text-20 cursor-pointer" />
      </div>
    </div>
    <div class="card">
      <!-- <div
        v-if="hasUid"
        class="flex sm:justify-content-start justify-content-around align-items-center"
      >
        <router-link v-for="r in path" :key="r.path" :to="{ path: r.route }">
          <h6 class="sm:pr-5" :style="'color: grey;' + getHeaderStyle(r.route)">
            {{ r.header }}
          </h6>
        </router-link>
      </div>
      <Divider v-if="hasUid" class="m-2"></Divider> -->
      <div>
        <transition name="slide-fade">
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { state } from "../../../services/data_service";
export default {
  name: "Client",
  data() {
    return {};
  },

  mounted() {},
  methods: {
    getHeaderStyle(route) {
      if (this.$route.path == route) {
        return "font-weight: bold; color: #4D7CFE";
      }
    },
  },
  computed: {
    client() {
      return state.client;
    },
    path() {
      return [
        {
          header: "Details",
          icon: "la-check",
          route: "/client/" + (state.client ? state.client.uid : ""),
        },

        {
          header: "Purchase Orders",
          icon: "la-comments",
        },
        {
          header: "Linked Jobs",
          icon: "la-folder-open",
        },
        {
          header: "Linked Cost Estimates",
          icon: "la-robot",
        },
        {
          header: "Linked Invoices",
          icon: "la-robot",
        },
        {
          header: "Notes",
          icon: "la-robot",
        },
        {
          header: "Documents",
          icon: "la-robot",
        },
      ];
    },
    hasUid() {
      return this.$route.params.uid;
    },
  },
  metaInfo() {
    return {
      title: this.client?.name,
      meta: [{ vmid: "description", name: "description", content: "" }],
    };
  },
};
</script>

<style>
</style>

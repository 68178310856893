<template>
  <div class="container-padding" align="left">
    <h1>Theme Elements</h1>
    <h4>Inputs</h4>
    <div class="grid p-fluid">
      <div class="col-12 md:col-4">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-user" />
          </span>
          <InputText placeholder="Username" />
        </div>
      </div>

      <div class="col-12 md:col-4">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">$</span>
          <InputText placeholder="Price" />
          <span class="p-inputgroup-addon">.00</span>
        </div>
      </div>

      <div class="col-12 md:col-4">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-globe" /></span>
          <InputText placeholder="Website" />
        </div>
      </div>

      <div class="col-12 md:col-6">
        <div class="p-inputgroup">
          <MultiSelect
            v-model="selectedCities2"
            :options="cities"
            optionLabel="name"
            placeholder="Select a City"
            display="chip"
            :filter="true"
          />
        </div>
      </div>

      <div class="col-12 md:col-12">
        <Editor ref="editor" v-model="value" editorStyle="height: 320px" />
      </div>

      <div class="col-12 md:col-6">
        <div class="p-inputgroup">
          <Button
            label="Primary"
            class="p-button-primary"
            @click="showSuccess"
          />
          <Button
            label="Success"
            class="p-button-success"
            @click="showSuccess"
          />
          <Button label="Info" class="p-button-info" @click="showInfo" />
          <Button label="Warn" class="p-button-warning" @click="showWarn" />
          <Button label="Error" class="p-button-danger" @click="showError" />
        </div>
      </div>

      <div class="col-12 md:col-12">
        <h4>Switch</h4>
        <InputSwitch v-model="switched" />
      </div>

      <div class="col-12 md:col-6">
        <h4>Checkbox</h4>
        <div class="grid p-fluid">
          <div
            v-for="category of categories"
            :key="category.key"
            class="field-checkbox col-12 md:col-4"
          >
            <Checkbox
              :id="category.key"
              name="category"
              :value="category"
              v-model="selectedCategories"
            />
            <label :for="category.key">{{ category.name }}</label>
          </div>
        </div>
      </div>

      <div class="col-12 md:col-6">
        <h4>Radio</h4>
        <div class="grid p-fluid">
          <div class="col-12 md:col-4">
            <RadioButton id="radio1" name="radio" value="1" v-model="radio" />
            <label for="radio1">One</label>
          </div>
          <div class="col-12 md:col-4">
            <RadioButton id="radio2" name="radio" value="2" v-model="radio" />
            <label for="radio2">Two</label>
          </div>
          <div class="col-12 md:col-4">
            <RadioButton id="radio3" name="radio" value="3" v-model="radio" />
            <label for="radio3">Three</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checked1: false,
      checked2: false,
      checked3: "1",
      switched: false,
      value: "",
      switched2: false,
      radio: "1",
      categories: [
        { name: "Yes", key: "A" },
        { name: "No", key: "M" },
        { name: "Maybe", key: "P" },
      ],
      selectedCategories: [],
      selectedCities1: null,
      selectedCities2: null,
      selectedCountries: null,
      cities: [
        { name: "New York", code: "NY" },
        { name: "Rome", code: "RM" },
        { name: "London", code: "LDN" },
        { name: "Istanbul", code: "IST" },
        { name: "Paris", code: "PRS" },
      ],
      countries: [
        { name: "Australia", code: "AU" },
        { name: "Brazil", code: "BR" },
        { name: "China", code: "CN" },
        { name: "Egypt", code: "EG" },
        { name: "France", code: "FR" },
        { name: "Germany", code: "DE" },
        { name: "India", code: "IN" },
        { name: "Japan", code: "JP" },
        { name: "Spain", code: "ES" },
        { name: "United States", code: "US" },
      ],
    };
  },
  created() {
    this.selectedCategories = this.categories.slice(1, 2);
  },

  methods: {
    handleKeyPress(event) {
      const editor = this.$refs.editor;
      if (editor && editor.$el.contains(event.target)) {
        // If "Enter" is pressed inside the editor, create a new line
        event.stopPropagation();
      } else {
        // Else, submit the form
        this.submitHandler();
      }
    },
    showSuccess() {
      this.$toast.add({
        severity: "success",
        summary: "Success Message",
        detail: "Message Content",
        life: 3000,
      });
    },
    showInfo() {
      this.$toast.add({
        severity: "info",
        detail: "Message Content",
        life: 3000,
      });
    },
    showWarn() {
      this.$toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: "Message Content",
        life: 3000,
      });
    },
    showError() {
      this.$toast.add({
        severity: "error",
        summary: "Error Message",
        detail: "Message Content",
        life: 3000,
      });
    },
  },

  computed: {},
  mounted() {},

  name: "Theme",
  components: {},

  metaInfo: {
    title: "Theme",

    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: "" },
      { name: "keywords", content: "" },
    ],
  },
};
</script>
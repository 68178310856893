var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid"},[_vm._m(0),_c('div',{staticClass:"card col-12 pt-3"},[_c('div',{staticClass:"col-12 pb-3 flex sm:inline-flex flex-row align-items-center sm:justify-content-end justify-content-center"},[_c('div',{staticClass:"mr-3"},[_c('FilterSearchButton')],1),_c('div',{staticClass:"mr-3"},[_c('Button',{attrs:{"label":"Add Costing","icon":"las la-plus"},on:{"click":function($event){_vm.$store.dispatch('setSelectedObject', null);
            _vm.$store.dispatch('setSliderView', 'JobCostingSlider');}}})],1)]),_c('DataTable',{attrs:{"responsiveLayout":"scroll","value":_vm.jobs,"rowClass":_vm.rowClass,"editMode":"row","dataKey":"uid","paginator":true,"rows":30,"rowsPerPageOptions":[30, 50, 100]},on:{"row-click":_vm.navJob},scopedSlots:_vm._u([{key:"empty",fn:function(){return undefined},proxy:true}])},[_c('Column',{staticStyle:{"min-width":"2.5rem"},attrs:{"field":"managed_by","header":"AE","filterField":"managed_by.name"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(_vm.isLoading)?_c('div',[_c('Skeleton',{attrs:{"shape":"circle","width":"3rem","height":"3rem"}})],1):_c('Avatar',{style:({
              'background-color': !slotProps.data[slotProps.column.field]
                .profile_picture
                ? _vm.getColorFromName(slotProps.data[slotProps.column.field])
                : 'none',
              color: '#ffffff',
            }),attrs:{"image":slotProps.data[slotProps.column.field].profile_picture,"label":!slotProps.data[slotProps.column.field].profile_picture
                ? _vm.getIntials(slotProps.data[slotProps.column.field])
                : null,"size":"large","shape":"circle"}})]}}])}),_c('Column',{attrs:{"field":"title"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(_vm.isLoading)?_c('Skeleton'):_vm._e(),(_vm.isLoading)?_c('Skeleton',{staticClass:"mt-1",attrs:{"width":"75%"}}):[_c('div',{staticClass:"cursor-pointer"},[_vm._v(" "+_vm._s(slotProps.data[slotProps.column.field])+" ")]),_c('div',{staticClass:"font-light text-sm cursor-pointer"},[_vm._v(" "+_vm._s(slotProps.data.client.name)+" ")])]]}}])}),_c('Column',{attrs:{"field":"job_number"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(_vm.isLoading)?_c('Skeleton'):_vm._e(),(_vm.isLoading)?_c('Skeleton',{staticClass:"mt-1",attrs:{"width":"75%"}}):[_c('div',{staticClass:"font-light text-sm text-link-hover"},[_vm._v(" "+_vm._s(slotProps.data[slotProps.column.field])+" ")])]]}}])}),_c('Column',{attrs:{"field":"category","header":"Category"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(_vm.isLoading)?_c('Skeleton'):_c('span',[_vm._v(" "+_vm._s(slotProps.data[slotProps.column.field]))])]}}])}),_c('Column',{attrs:{"field":"created_date","header":"Created"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(_vm.isLoading)?_c('Skeleton'):_vm._e(),(_vm.isLoading)?_c('Skeleton',{staticClass:"mt-1",attrs:{"width":"75%"}}):[_c('div',{staticClass:"font-light text-sm text-link-hover"},[_vm._v(" "+_vm._s(slotProps.data[slotProps.column.field].nice)+" ")])]]}}])}),_c('Column',{attrs:{"field":"budget","header":"Amount"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(_vm.isLoading)?[_c('Skeleton',{staticClass:"mt-1"})]:[_c('div',{staticClass:"text-md"},[_vm._v(" "+_vm._s(_vm.formatCurrency( slotProps.data[slotProps.column.field].amount, _vm.setCurrencySymbol(slotProps.data) ))+" ")])]]}}])})],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 flex sm:justify-content-start justify-content-center align-items-center pb-0"},[_c('div',{staticClass:"p-3 mr-1 w-full flex sm:justify-content-start justify-content-center align-items-center"},[_c('h1',[_vm._v("Costings")])])])
}]

export { render, staticRenderFns }
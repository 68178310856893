<template>
  <div class="grid">
    <div
      class="flex flex-wrap w-full justify-content-between align-items-center"
    >
      <div
        class="flex flex-wrap sm:justify-content-end justify-content-center align-items-center"
      >
        <h1 class="p-3 mr-1">Clients</h1>
      </div>
    </div>

    <TabView
      class="col-12 p-tab-show-panel sticky-tabs"
      :activeIndex.sync="active"
    >
      <TabPanel header="Active">
        <ActiveClients key="active" v-if="active === 0" />
      </TabPanel>
      <TabPanel header="Prospective">
        <ProspectiveClients key="prospect" v-if="active === 1" />
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
import ActiveClients from "./ActiveClients.vue";
import ProspectiveClients from "./ProspectiveClients.vue";

export default {
  name: "Clients",
  data() {
    return {
      active: 0,
    };
  },
  components: {
    ActiveClients,
    ProspectiveClients,
  },
  metaInfo: {
    title: "Clients",
    meta: [
      { vmid: "description", name: "description", content: "Clients Page" },
    ],
  },
};
</script>

<style>
/* Add any global styles here */
</style>

<template>
  <component :is="layout">
    <router-view :layout.sync="layout" />
  </component>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      taskchannel: [],
      layout: "div",
    };
  },
  computed: {
    isAuth() {
      return this.$store.getters.isAuthenticated;
    },
    refreshData() {
      return this.$store.getters.refreshData;
    },
  },

  watch: {
    isAuth(n, o) {
      if (n == false) {
        this.$router.push("/login");
      }
    },
  },

  created() {},
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inline-flex p-2 align-items-center mb-3",staticStyle:{"z-index":"3"}},[_c('div',{class:'mr-2 sm:mr-3 md:mr-4 p-1  ' +
      (_vm.filters.indexOf('active') >= 0
        ? 'bottom-border-positive'
        : 'bottom-border-invisible'),on:{"click":function($event){return _vm.filter('active')}}},[_c('p',{staticClass:"font-light cursor-pointer"},[_vm._v(" Active "),_c('Badge',{staticClass:"ml-1",staticStyle:{"background-color":"#6dd230"},attrs:{"value":_vm.active}})],1)]),_c('div',{class:'mr-2 sm:mr-3 md:mr-4 p-1  ' +
      (_vm.filters.indexOf('completed') >= 0
        ? 'bottom-border-grey'
        : 'bottom-border-invisible'),on:{"click":function($event){return _vm.filter('completed')}}},[_c('p',{staticClass:"font-light cursor-pointer"},[_vm._v(" Completed "),_c('Badge',{staticClass:"ml-1",staticStyle:{"background-color":"#e8ecef"},attrs:{"value":_vm.completed}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
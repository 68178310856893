<template>
  <div class="flex justify-content-between">
    <label v-if="label">{{ label }}</label>
    <InputSwitch
      :value="inputVal == '1'"
      @change="inputVal = updateInputSwitch(inputVal)"
      class="green"
      :disabled="disabled"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    value: {
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style>
</style>
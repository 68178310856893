<template>
  <div class="flex p-24">
    <div>
      <div class="flex m-b-24">
        <h1>Purchase Orders</h1>

        <div
          class="flex col justify-content-end"
          style="padding: 0px; margin-left: 24px"
        >
          WIP
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { state, fetchEstimates } from "../../../services/data_service.js";
import Vue from "vue";
export default {
  name: "Home",
  data() {
    return {
      drag: false,
      originalRows: [],
      editingRows: [],
      editingRow: null,
      isWelcome: false,
    };
  },
  created() {
    this.$bus.on("refreshData", fetchEstimates);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", fetchEstimates);
  },

  async mounted() {
    await fetchEstimates();
  },

  methods: {
    rowClass() {
      return "row-accessories";
    },
    editRow(r) {
      this.editingRow = r.uid;
      this.editingRows.push(r);
    },
    saveRow(r) {
      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API + "/v1/departments/" + r.data.uid,
          JSON.stringify(r.data),
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: "Department Updated",
            life: 3000,
          });

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },
    onRowEditInit(event) {
      this.originalRows[event.index] = {
        ...this.selectedDepartments[event.index],
      };
    },
    onRowEditCancel(event) {
      Vue.set(
        this.selectedDepartments,
        event.index,
        this.originalRows[event.index]
      );
    },
    addNew(d) {
      var row = {
        id: this.selectedDepartments.length + 1,
        name: d,
        rate: "",
      };

      this.selectedDepartments.push(row);
      this.editingRows.push(row);
    },
  },

  computed: {
    estimates() {
      return state.estimates;
    },
  },
};
</script>

<style lang="scss" >
// table th {
//   background: none !important;
// }
</style>
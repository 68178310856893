var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex align-items-center"},[_c('div',{staticClass:"col flex-grow-0"},[(!_vm.file.url.match(/.(jpg|jpeg|png|gif)$/i) ? null : _vm.file.url)?_c('div',{staticClass:"p-avatar p-component p-avatar-image p-avatar-circle p-avatar-lg"},[_c('img',{directives:[{name:"img",rawName:"v-img",value:({
          src: _vm.file.preview
            ? _vm.file.preview
            : _vm.file.thumb
            ? _vm.file.thumb
            : _vm.file.url,
        }),expression:"{\n          src: file.preview\n            ? file.preview\n            : file.thumb\n            ? file.thumb\n            : file.url,\n        }"}],attrs:{"src":_vm.file.thumb ? _vm.file.thumb : _vm.file.url}})]):_c('Avatar',{style:({
        'background-color': '#F2F4F6',
        color: '#B9C5D0',
      }),attrs:{"size":"large","shape":"circle","icon":!_vm.file.url.match(/.(jpg|jpeg|png|gif)$/i) ? 'las la-file' : null}})],1),_c('div',{staticClass:"col-11"},[_c('div',{staticClass:"col-10 p-0 filename",attrs:{"title":_vm.file.name}},[_vm._v(_vm._s(_vm.file.name))]),_c('div',{staticClass:"col-12 p-0 flex"},[_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"font-light text-xs"},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.created_date))+" ")]),_c('div',{staticClass:"font-light text-xs"},[_vm._v(_vm._s(_vm.file.size)+"Kb")])]),_c('div',{staticClass:"col-grow-0 p-0"},[_c('i',{staticClass:"las la-trash i-20 cursor-pointer pr-2 grey",on:{"click":function($event){$event.stopPropagation();return _vm.deletePrompt(
              _vm.file.name,
              '/v1/tasks/upload/delete?attachment=' + _vm.file.uid
            )}}}),_c('a',{attrs:{"href":_vm.file.url}},[_c('i',{staticClass:"las la-download cursor-pointer i-20 grey"})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
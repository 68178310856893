<template>
  <div>
    <welcome
      v-if="isWelcome"
      title="Your Company Structure: TASKS"
      image="tasks"
      text="It’s time to start thinking about the type of tasks<br>you will be assigning to your staff."
      :action="hideWelcome"
    />
    <div class="flex flex-column m-1 p-1 m-lg-3 p-b-lg-3" v-else>
      <h2 class="mb-3">Tasks Setup</h2>

      <div class="flex">
        <div class="lg:col-10">
          <div class="flex mt-3 mb-2">
            <div class="lg:col-10">
              <div class="flex">
                <div class="mr-4 cursor-pointer" style="color: #778ca2">
                  Collapse/Expand Tasks
                </div>
                <div class="mr-4 cursor-pointer" style="color: #778ca2">
                  Collapse/Expand Departments
                </div>
                <div class="mr-4 cursor-pointer" style="color: #778ca2">
                  <i class="pi pi-plus mr-2" />Import Tasks
                </div>
              </div>
            </div>
            <div class="lg:col-2">
              <div
                class="mr-4 cursor-pointer"
                style="color: #778ca2; text-align: right"
              >
                Task Tips
              </div>
            </div>
          </div>
          <div
            v-for="department in departments"
            :key="department.id"
            class="pb-4"
          >
            <DataTable
              :value="department.tasks"
              :expandedRows.sync="expandedRows"
              editMode="row"
              dataKey="id"
              :editingRows.sync="editingRows"
              @row-edit-init="onRowEditInit($event, department.tasks)"
              @row-edit-cancel="onRowEditCancel($event, department.tasks)"
            >
              <Column :expander="true" headerStyle="width: 3rem" />
              <Column field="name" :header="'Dept: ' + department.name">
                <template #editor="slotProps">
                  <InputText
                    placeHolder="Name"
                    v-model="slotProps.data[slotProps.column.field]"
                  />
                </template>
              </Column>

              <Column field="rate" header="Rate">
                <template #editor="slotProps">
                  <InputText
                    placeHolder="Rate"
                    v-model="slotProps.data[slotProps.column.field]"
                  />
                </template>
              </Column>

              <Column field="linkToDepartments" header="Link to Department's">
                <template #body="slotProps">
                  <span>{{
                    slotProps.data.linkToDepartments.join(" | ")
                  }}</span>
                </template>
                <template #editor="slotProps">
                  <InputText
                    placeHolder="Sales"
                    v-model="slotProps.data[slotProps.column.field]"
                  />
                </template>
              </Column>
              <Column field="sales" header="GL Sales">
                <template #editor="slotProps">
                  <InputText
                    placeHolder="Sales"
                    v-model="slotProps.data[slotProps.column.field]"
                  />
                </template>
              </Column>
              <Column field="costToSales" header="GL COS">
                <template #editor="slotProps">
                  <InputText
                    placeHolder="Cost to Sales"
                    v-model="slotProps.data[slotProps.column.field]"
                  />
                </template>
              </Column>
              <Column field="minTime" header="Min Time">
                <template #editor="slotProps">
                  <InputText
                    placeHolder="Minimun Time"
                    v-model="slotProps.data[slotProps.column.field]"
                  />
                </template>
              </Column>
              <Column field="inUse" header="In Use">
                <template #body="slotProps">
                  <InputSwitch
                    v-model="slotProps.data[slotProps.column.field]"
                  />
                </template>
                <template #editor="slotProps">
                  <InputSwitch
                    v-model="slotProps.data[slotProps.column.field]"
                  />
                </template>
              </Column>
              <Column
                :rowEditor="true"
                headerStyle="width:7rem"
                bodyStyle="text-align:center"
              />
              <template #expansion="slotProps">
                <div v-if="slotProps.data.description">
                  {{ slotProps.data.description }}
                </div>
                <div v-else class="p-text-italic">
                  No Description
                </div> </template
              ><template #editor="slotProps">
                <InputText
                  placeHolder="Minimun Time"
                  v-model="slotProps.data[slotProps.column.field]"
                />
              </template>
            </DataTable>
            <Button
              @click="addNew(department)"
              icon="pi pi-plus"
              class="button p-button-secondary mt-3 p-button-sm p-button-text"
              label="Create a Task"
            />
          </div>
          <div class="flex justify-content-between mt-3">
            <Button
              @click="$router.push('/onboard/staff')"
              class="button is-primary"
              label="BACK"
            />
            <Button
              @click="$router.push('/jobs')"
              class="button is-primary"
              label="FINISH"
            />
          </div>
        </div>
        <Divider layout="vertical" />
        <div class="lg:col-2">
          <div class="user-entry">Suggested</div>
          <div
            v-for="suggestion in suggestions"
            :key="suggestion"
            class="p-3 border-bottom"
          >
            <i class="pi pi-plus-circle mr-1 cursor-pointer" />
            {{ suggestion }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Welcome from "./WelcomeForm.vue";
export default {
  components: { Welcome },
  mounted() {
    this.getDepartments();
  },
  data() {
    return {
      editingRows: [],
      editingRow: null,
      originalRows: [],
      expandedRows: [],
      isWelcome: true,
      suggestions: [
        "Desktop Publishing",
        "Design",
        "UI Design",
        "Moodboard Design",
        "UX Design",
        "Brand Design",
      ],
      departments: [
        {
          id: 1,
          name: "Copy",
          tasks: [
            {
              id: 1,
              name: "Copywriting",
              description: null,
              rate: "750/hr",
              linkToDepartments: ["Copy", "Strategy", "Paid Media"],
              sales: "100-123",
              costToSales: "110-543",
              minTime: "00:15",
              inUse: true,
            },
            {
              id: 2,
              name: "Proof reading",
              description:
                "General proof reading and editing addressing grammer and sentence structure.",
              rate: "750/hr",
              linkToDepartments: ["Copy", "Strategy", "Paid Media"],
              sales: "100-123",
              costToSales: "110-543",
              minTime: "00:15",
              inUse: true,
            },
          ],
        },
        {
          id: 2,
          name: "Development",
          tasks: [
            {
              id: 4,
              name: "Copywriting",
              description: null,
              rate: "750/hr",
              linkToDepartments: ["Copy", "Strategy", "Paid Media"],
              sales: "100-125",
              costToSales: "110-543",
              minTime: "00:15",
              inUse: true,
            },
          ],
        },
      ],
    };
  },
  methods: {
    getDepartments() {
      this.$axios.get("/departmenrts").then((res) => {
        this.departments = res;
      });
    },
    hideWelcome() {
      this.isWelcome = false;
    },
    onRowEditInit(event, array) {
      this.originalRows[event.index] = array[event.index];
    },
    onRowEditCancel(event, array) {
      Vue.set(array, event.index, this.originalRows[event.index]);
    },
    addNew(d, r) {
      var row = {
        id: d.tasks.length + 1,
        name: null,
        description: null,
        rate: null,
        linkToDepartments: [],
        sales: null,
        costToSales: null,
        minTime: null,
        inUse: true,
      };
      d.tasks.push(row);
      this.editingRows.push(row);
    },
  },
};
</script>

<style>
</style>
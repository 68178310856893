<template>
  <div
    class="flex align-items-center justify-content-center overflow-x-hidden overflow-y-hidden"
    :style="getStyle()"
  >
    <ProgressSpinner />
  </div>
</template>
<script>
export default {
  name: "RocketLoad",
  props: {
    full: {
      default: false,
    },
  },
  methods: {
    getStyle() {
      return this.full ? "height:90vh" : "";
    },
  },
};
</script>

<style>
@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: purple !important;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%,
  90% {
    stroke: #ffa700;
  }
}
.rocket-box {
  width: 120px;
  height: 260px;
  transition: all 2s;
  transform: perspective(1000px) rotateX(0px);
  transform-origin: center;
  animation: 1.5s ease-in-out 0s infinite alternate both wiggle;
}
.exhaust {
  transform: translateX(-50px) translateY(170px) rotateZ(60deg) !important;
  animation: 0.2s ease-in-out 0s infinite alternate both smoke;
}
@keyframes wiggle {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
@keyframes smoke {
  100% {
    opacity: 0;
  }
}
</style>
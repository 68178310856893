<template>
  <div class="desk-entry-background">
    <draggable
      delay="250"
      :delay-on-touch-only="true"
      :animation="140"
      v-model="dragItem"
      :group="{ name: 'people' }"
      id="4"
      class="desk-container"
      handle=".handle2"
    >
      <div
        v-if="task"
        :class="'flex flex-wrap align-items-center card p-0 handle outside'"
      >
        <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4 pl-5">
          <div
            class="flex justify-content-center sm:justify-content-start text-16 font-light pb-1"
          >
            <template>{{ task.client }}</template>
          </div>
          <div
            :class="'flex align-items-center justify-content-center sm:justify-content-start'"
          >
            <span
              class="text-16 text-black cursor-pointer iconHover pb-1"
              @click="showTask"
              >{{ task.task_type_name }} {{ task.task_type_name ? " - " : "" }}
              {{ task.title }}</span
            >
          </div>
          <div
            class="flex text-16 font-light justify-content-center sm:justify-content-start pt-1"
            v-if="task.job"
          >
            {{ task.job.job_number }}
            {{ task.job.title }}
          </div>
        </div>
        <div
          class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4 flex align-items-center justify-content-center"
        >
          <div class="vl sm:h-6rem" />
          <div
            class="sm:col-4 md:col-4 lg:col-4 xl:col-4 ml-3 flex sm:justify-content-center justify-content-start"
          >
            <div class="cursor-pointer" @click="toggleDesk(task.uid)">
              <div class="flex">
                <Button
                  :icon="'la la-' + (!isPaused ? 'play' : 'pause')"
                  class="w-5rem h-5rem border-5 border-solid border-green-custom p-button-rounded p-button-success p-5 i-24"
                />
              </div>
            </div>
          </div>
          <div
            class="flex sm:col-4 md:col-4 lg:col-4 xl:col-4 justify-content-evenly flex-wrap"
          >
            <div
              class="col-12 flex justify-content-start pb-0 text-20 text-black"
            >
              {{ secToHMS(currentTimer) }}
            </div>
            <div class="col-12 flex justify-content-start pt-1 pb-0 text-sm">
              Today’s Goal:
            </div>
            <div class="col-12 flex pt-0 justify-content-start">
              <i class="las la-stopwatch mr-1"></i>
              {{ today_work_hours }}
            </div>
          </div>
          <div class="mr-1 sm:col-4 md:col-4 lg:col-4 xl:col-4 xl:pl-1">
            <div
              class="flex align-items-center justify-content-end sm:justify-content-center mb-3"
              @click="
                $store.dispatch('setSelectedObject', task.uid);
                display = true;
              "
            >
              <div>
                <Button
                  icon=" las la-save i-20"
                  class="p-button-md p-button-rounded grey-button"
                />
              </div>
              <div class="text-lighter text-sm xl:pl-1 pl-3 sm:block"></div>
            </div>
            <div
              @click="cancelPrompt(task.uid)"
              class="flex align-items-center justify-content-end sm:justify-content-center mt-3"
            >
              <div>
                <Button
                  icon=" las la-times i-20"
                  class="p-button-md p-button-rounded grey-button"
                />
              </div>
              <div class="text-lighter text-sm xl:pl-1 pl-3 sm:block"></div>
            </div>
          </div>
          <div class="vl sm:h-6rem" />
          <!-- <div class="text-white flex align-items-center" v-if="prevLoaded">
            <Button
              @click="addToDesk(task.uid)"
              icon="las la-stopwatch i-20"
              class="p-button-rounded p-button-text grey-button m-r-6"
            ></Button
            >Select a task to start working.
          </div> -->
        </div>

        <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-4 p-3 pr-5">
          <div class="flex justify-content-between align-items-center mb-3">
            <div
              class="text-lighter flex align-items-center iconHover cursor-pointer"
              style="
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
                -o-user-select: none;
              "
              @click="swapProgressType()"
            >
              {{ taskProgressType }}
              <i
                v-if="task.users && task.users.length > 1"
                class="ml-2 las la-exchange-alt"
              />
            </div>

            <div :class="'text-lighter'">
              {{ convertMinsToHrsMins(getUsedTime(task)) }}
              /
              {{ convertMinsToHrsMins(getTotalTime(task)) }}
            </div>
          </div>
          <div class="task-entry-progress border-4 progress-bg">
            <div
              :style="getProgressStyle(task)"
              class="task-entry-progress border-4"
            />
          </div>
          <div
            v-if="task.date_due"
            class="flex justify-content-between align-items-center mt-3"
          >
            <div class="due-date-tag flex col p-0">
              Due:
              <span class="text-active pl-1">{{ task.date_due?.nice }}</span>
            </div>

            <div
              @click="
                $store.dispatch('setSelectedObject', task.uid);
                forReview = true;
                display = true;
              "
              class="flex align-items-center cursor-pointer btn-hover"
              v-if="task.reviewed_by"
            >
              <div class="text-lighter pr-3">Send for review</div>
              <Button
                icon=" las la-arrow-right i-20"
                class="p-button-md p-button-rounded grey-button"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-else class="flex desk-entry-card align-items-center flex-wrap">
        <p class="col text-center text-18 white-text">
          <i class="la-lg las la-stopwatch mr-1"></i>
          Select a task to start working.
        </p>
      </div>
    </draggable>
    <!-- <Button
      v-if="task"
      icon="las la-times i-20 "
      @click="toggleDesk(task.uid)"
      iconPos="right"
      class="p-button-rounded p-button-text grey-button exit-button"
    ></Button> -->

    <SaveTime
      v-if="task"
      :display="display"
      :prop_name="task.title"
      :task="task"
      :forReview="forReview"
      @dismiss="
        display = false;
        forReview = false;
      "
    />
  </div>
</template>
<script>
import draggable from "vuedraggable";
import SaveTime from "../components/SaveTime.vue";
import { state, cancelTime } from "../../../services/data_service.js";
export default {
  name: "DeskEntry",
  components: {
    SaveTime,
    draggable,
  },
  props: {
    task: Object,
    myTasks: Object,
  },
  data() {
    return {
      display: false,
      forReview: false,
      dragItem: null,
      checked: true,
      counterInterval: null,
      taskProgressType: "My Progress",
    };
  },
  watch: {
    display(n, o) {
      if (n && this.isPaused) {
        this.toggleDesk(this.task.uid);
      }
    },
    task() {
      this.taskProgressType = "My Progress";
    },
  },
  computed: {
    timersArr() {
      return state.myTasks.timers;
    },
    pausedTasks() {
      if (this.timersArr) {
        return this.timersArr[0];
      }
      return [];
    },
    today_work_hours() {
      var s = 0;
      this.myTasks.today.forEach((a) => {
        s += this.convertH2M(this.get_today_work_hours(a, this.user_uid));
      });

      var hours = s / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (
        (rhours < 10 ? "0" + rhours : rhours) +
        ":" +
        (rminutes < 10 ? "0" + rminutes : rminutes)
      );
    },

    currentTimer() {
      return this.$store.getters.currentTimer;
    },

    user_work_hours() {
      return 0;
    },
    user_uid() {
      return this.$store.getters.user.uid;
    },
    isPaused() {
      return state.isPaused;
    },
    today() {
      return new Date().toLocaleString("default", { weekday: "long" });
    },
  },

  methods: {
    showTask() {
      this.$store.dispatch("setSelectedObject", { uid: this.task.uid });
      this.$store.dispatch("setSliderView", "TaskSlider");
    },

    getTagClass(t) {
      switch (t) {
        case "Overdue":
          return "overdue-tag";
        default:
          return "days-tag";
      }
    },
    getUsedTime(task) {
      if (this.taskProgressType === "Total Progress") {
        var m = 0;
        task.users.forEach((u) => {
          m += parseInt(u.minutes);
        });
        return m;
      } else {
        return task.users.filter((u) => u.uid === this.user_uid)[0].minutes;
      }
    },
    getTotalTime(task) {
      if (this.taskProgressType === "Total Progress") {
        var m = 0;
        task.users.forEach((u) => {
          m += parseInt(u.minutes_expected);
        });
        return m;
      } else {
        return task.users.filter((u) => u.uid === this.user_uid)[0]
          .estimated_minutes;
      }
    },

    getProgressStyle(task) {
      var used = this.getUsedTime(task);
      var total = this.getTotalTime(task);
      var progress = (used / total) * 100;
      if (progress >= 100) {
        return "background: #fe4d97;border-bottom-left-radius: 4px;border-bottom-right-radius: 4px;";
      } else {
        return (
          "border-bottom-left-radius: 4px;background: #6dd230;width:" +
          progress +
          "%"
        );
      }
    },

    toggleDesk(uid) {
      state.isPaused = !state.isPaused;
      this.$axios
        .get(
          process.env.VUE_APP_ROOT_API + "/v1/tasks/" + uid + "/timer/toggle",
          {}
        )
        .then((response) => {
          this.$bus.emit("refreshData");
          this.$store.dispatch("setTimer", response.data.data);
        })
        .catch((error) => {});
    },
    cancelPrompt(uid) {
      this.$confirm.require({
        message: "Are you sure you want to cancel this time?",
        header: "Confirm Cancel",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          state.isPaused = !state.isPaused;
          this.cancelTime(uid);
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    swapProgressType() {
      this.taskProgressType =
        this.taskProgressType === "Total Progress"
          ? "My Progress"
          : "Total Progress";
    },
    async cancelTime(uid) {
      var respsonse = await cancelTime(uid);
      this.$store.dispatch("setTimer", respsonse);
    },
  },
};
</script>
<style scoped>
.card {
  margin-bottom: unset !important;
}

.desk-entry-background {
  background-color: #b9c5d0;
  border-radius: 25px;
}

.white-text {
  color: #edfbff;
}

.outside {
  border-radius: 25px;
  border: 8px solid #e8ecef;
}

.progress-bg {
  background: #e8ecef;
}

.desk-entry-card {
  min-height: 75px;
}

.no-pad-left {
  padding-left: unset !important;
}

.used-time {
  font-weight: 500;
  color: #515f6f;
}

.vertical-middle {
  vertical-align: middle;
}

.stopwatch-button {
  height: 100%;
  border-radius: 50%;
  color: white;
}

.p-inputswitch-slider {
  background: #6dd230 !important;
}

.task-tag-review {
  border-radius: 4px;
  background: #b9c5d0;
  color: white;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 7px;
  padding-right: 7px;
}

.exit-button {
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
}

.btn-hover:hover {
  background: rgba(248, 250, 251, 0.6) !important;
  border-radius: 1rem;
}

.vl {
  border-left: 2px solid #eee;
}
</style>
<template>
  <div>
    <!-- <div
      class="wired-margin flex flex-wrap sm:justify-content-end justify-content-center align-items-center pb-2"
    >
      <div>
        <FilterSearchButton v-model="filters['global'].value" />
      </div>
      <div>
        <Button
          label="New Account"
          @click="$store.dispatch('setSliderView', 'AccountStructureSlider')"
          icon="las la-plus"
        />
      </div>
    </div> -->
    <div>
      <DataTable
        responsiveLayout="scroll"
        :rowClass="rowClass"
        :value="accountFinance"
        :filters.sync="filters"
        filterDisplay="menu"
        editMode="row"
        dataKey="uid"
        :editingRows.sync="editingRows"
        @row-edit-cancel="onRowEditCancel"
        @row-edit-save="saveRow"
        :paginator="true"
        :rows="30"
        :rowsPerPageOptions="[30, 50, 100]"
      >
        <template #empty>
          <Button
            v-if="!filters['global'].value"
            label="New Account"
            class="p-button-text p-button-secondary"
            @click="$store.dispatch('setSliderView', 'AccountStructureSlider')"
            icon="las la-plus"
          />
          <div v-else>No Results</div>
        </template>
        <Column field="gl_code" header="Account Code" filterField="gl_code">
          <template #body="slotProps">
            <Skeleton width="60%" v-if="isLoading"></Skeleton>
            <template v-else>
              <span
                class="cursor-pointer"
                @click="
                  $store.dispatch('setSelectedObject', slotProps.data);
                  $store.dispatch('setSliderView', 'AccountStructureSlider');
                "
              >
                {{ slotProps.data.gl_code }}
              </span>
            </template>
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by name"
            />
          </template>
        </Column>
        <Column
          field="account_type"
          header="Account Type"
          filterField="account_type"
        >
          <template #body="slotProps">
            <Skeleton width="20%" v-if="isLoading"></Skeleton>
            <template v-else>
              {{ toTitleCase(slotProps.data.account_type) }}
            </template>
          </template>
        </Column>

        <Column field="gl_name" header="Account Name" filterField="gl_name">
          <template #body="slotProps">
            <Skeleton width="75%" v-if="isLoading"></Skeleton>
            <template v-else>
              {{ slotProps.data[slotProps.column.field] }}
            </template>
          </template>
          <!-- <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by name"
            />
          </template> -->
        </Column>

        <Column
          sortable
          field="active"
          header="Active"
          bodyStyle="translateY(7px)"
        >
          <template #body="slotProps">
            <Skeleton
              width="4rem"
              height="2rem"
              borderRadius="16px"
              v-if="isLoading"
            ></Skeleton>
            <template v-else>
              <AActiveSwitch
                @change="saveRow(slotProps.data)"
                v-model="slotProps.data[slotProps.column.field]"
              />
            </template>
          </template>
        </Column>
        <Column bodyStyle="text-align:right" :styles="{ width: '1rem' }">
          <template #body="slotProps">
            <div
              class="flex justify-content-end align-items-center invisible cursor-pointer"
            >
              <template v-if="editingRow !== slotProps.data.uid">
                <i
                  v-if="slotProps.data.is_default != 1"
                  @click="
                    $store.dispatch('setSelectedObject', slotProps.data);
                    $store.dispatch('setSliderView', 'AccountStructureSlider');
                  "
                  :class="getEditClass(rowInEdit)"
                ></i>
                <i
                  v-if="slotProps.data.is_default != 1"
                  @click="
                    deletePrompt(
                      slotProps.data.gl_name,
                      '/v1/departments/' + slotProps.data.uid
                    )
                  "
                  :class="getDeleteClass(rowInEdit)"
                />
              </template>
              <template v-else>
                <!-- <i
                  class="las la-check i-20 mr-3 grey"
                  @click="saveRow(slotProps.data)"
                /> -->
                <i
                  class="las la-check i-20 mr-3 grey"
                  @click="
                    $store.dispatch('setSelectedObject', slotProps.data);
                    $store.dispatch('setSliderView', 'AccountStructureSlider');
                  "
                />

                <i
                  class="las la-times i-20 grey"
                  @click="onRowEditCancel(slotProps.data, accountFinance)"
                />
              </template>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>
<script>
import { state, fetchAccountFinance } from "../../../services/data_service.js";
import { FilterMatchMode } from "primevue/api";
import Vue from "vue";
export default {
  data() {
    return {
      isLoading: true,
      loadingText: "Loading...",
      inputIsVisible: false,
      originalRows: [],
      editingRows: [],
      editingRow: null,
      selectedDepartments: [],
      rowInEdit: false,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        apply_from: { value: null, matchMode: FilterMatchMode.DATE_IS },
      },
    };
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
  },
  watch: {
    $route(to, from) {
      this.filters["global"].value = to.query.term;
    },
  },
  methods: {
    getEditClass(b) {
      return b === true ? "hidden" : "las la-pen i-20 mr-3 grey";
    },
    getDeleteClass(b) {
      return b === true ? "hidden" : "las la-trash i-20 grey";
    },
    rowClass() {
      return "row-accessories";
    },
    async loadData() {
      await Promise.all([fetchAccountFinance()]);
    },

    editRow(r, arr) {
      this.rowInEdit = true;
      this.editingRow = r.uid;
      this.editingRows.push(r);
      var index = arr.indexOf(r);
      this.originalRows[index] = {
        ...arr[index],
      };
    },
    saveRow(r) {
      r.apply_from = this.$moment(r.apply_from).format("YYYY-MM-DD");
      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API +
            "/v1/tasktype/update/billingtier/" +
            r.uid,
          JSON.stringify(r),
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          this.rowInEdit = false;
          this.editingRow = null;
          var i2 = this.editingRows.indexOf(r);
          this.editingRows.splice(i2, 1);
          this.$toast.add({
            severity: "success",
            summary: "Account Updated",
            life: 3000,
          });

          this.isLoading = false;
        })
        .catch((error) => {
          r.active = this.updateInputSwitch(r.active === 0 ? 1 : 0);

          this.isLoading = false;
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },
    onRowEditCancel(r, arr) {
      this.rowInEdit = false;
      var index = arr.indexOf(r);
      Vue.set(arr, index, this.originalRows[index]);

      var i2 = this.editingRows.indexOf(r);
      this.editingRows.splice(i2, 1);
      this.editingRow = null;
    },
    addNew(d) {
      var row = {
        id: this.selectedDepartments.length + 1,
        name: d,
        rate: "",
      };

      this.selectedDepartments.push(row);
      this.editingRows.push(row);
    },
    confirmDelete(item) {
      this.item = item;
      this.deleteProductDialog = true;
    },
    getHeaderStyle(route) {
      if (this.$route.path == route) {
        return "font-weight: bold; color: #4D7CFE";
      }
    },
    checkDate(d) {
      var date = this.currentDate;
      date = new Date();
      var dd = String(date.getDate()).padStart(2, "0");
      var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = date.getFullYear();
      date = yyyy + "-" + mm + "-" + dd;

      if (d < date) {
        return true;
      }
      return false;
    },
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    user_uid() {
      return this.$store.getters.user.uid;
    },
    obj() {
      return this.$store.getters.selectedObject;
    },

    accountFinance() {
      return state.accountFinance;
    },

    currentDate() {
      var currentDate = new Date();
      return currentDate;
    },
  },

  async mounted() {
    if (!this.accountFinance) {
      state.accountFinance = [{}, {}, {}, {}, {}, {}];
    }
    await this.loadData();
    this.task_type = this.obj;
    this.isLoading = false;
  },
  name: "Home",
  components: {},

  metaInfo: {
    title: "Settings | Accounts",

    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: "" },
      { name: "keywords", content: "" },
    ],
  },
};
</script>
<template>
  <div align="left">
    <div class="flex justify-content-between estimates pb-2">
      <div class="col flex">
        <div @click="goBack" class="cursor-pointer headerHover">
          <h1>Cost Estimate</h1>
          <!-- <h1 v-else>Cost Estimate Template</h1> -->
        </div>
        <i
          style="color: #778ca2"
          class="las text-20 la-angle-right pt-1 pr-1"
        />
        <Skeleton
          v-if="isLoading"
          height="24px"
          width="6rem"
          borderRadius="6px"
        />
        <h1 class="flex align-items-center mr-3" v-else-if="form.title">
          {{ form.title }}&nbsp;
          <h1>:&nbsp;{{ form.ce_number }}</h1>
          <span class="text-12 pl-4 mr-3"
            ><span
              :style="getTagStyle() + 'borderRadius: 4px'"
              class="p-2 py-1 uppercase"
              >{{ form.status }}</span
            ></span
          >
          <i
            v-if="form.status != 'Draft'"
            class="las la-pen grey i-20 cursor-pointer"
            @click="changeStatusPrompt(form.uid)"
          />
          <i
            v-if="form.status == 'Draft'"
            class="las la-pen grey i-20 cursor-pointer"
            @click="
              $store.dispatch('setSelectedObject', form);
              $store.dispatch('setSliderView', 'CostEstimateSlider');
            "
          />
        </h1>
      </div>
      <div class="flex text-center align-items-center">
        <div
          v-if="form.status === 'Issued' || form.status === 'Draft'"
          class="pr-3"
        >
          <Button
            :label="setAcceptLabel()"
            class="p-button-text p-button-secondary button-outline"
            icon="las la-suitcase"
            @click="convertCostOptions()"
          />
        </div>
        <div v-if="form.status === 'Draft'" class="pl-3 pr-3">
          <Button
            @click="issueEstimate(form.uid)"
            label="Issue"
            icon="las la-check"
          />
        </div>
        <div
          v-if="
            form.status === 'Accepted' ||
            form.status === 'Issued' ||
            form.status === 'Converted'
          "
          class="pl-3 pr-3"
        >
          <Button label="Export" icon="las la-download" />
        </div>
        <i
          v-if="stateEstimate != null"
          class="las la-ellipsis-h text-20 cursor-pointer"
          @click="toggleMenu"
        />
        <Menu id="overlay_menu" ref="menu" :model="menuItems" :popup="true" />
        <Dialog
          header="Enter Template Name"
          :visible.sync="displayDialog"
          :containerStyle="{ width: '350px' }"
          :modal="true"
        >
          <div class="confirmation-content">
            <InputText
              class="w-full"
              v-model="templateName"
              placeholder="Template Name"
            />
          </div>
          <template #footer>
            <Button
              label="Save"
              icon="pi pi-check"
              @click="saveAsTemplate"
              class="p-button-text"
            />
            <Button
              label="Cancel"
              icon="pi pi-times"
              @click="closeDialog"
              class="p-button-text"
              autofocus
            />
          </template>
        </Dialog>
      </div>
      <!-- <div class="flex">
        <Skeleton
          v-if="isLoading"
          height="24px"
          width="6rem"
          borderRadius="6px"
        />
        <h1
          class="flex align-items-center mr-3"
          style="color: #687a8d"
          v-if="form.ce_number"
        >
          {{ form.ce_number }}
        </h1>
        <div
          class="flex align-items-center"
          style="color: #687a8d"
          v-if="form.title"
        >
          <i
            v-if="form.status == 'Draft'"
            class="las la-pen text-20 cursor-pointer mx-2"
            @click="
              $store.dispatch('setSelectedObject', form);
              $store.dispatch('setSliderView', 'CostEstimateSlider');
            "
          />
          <i
            class="las la-ellipsis-h text-20 cursor-pointer"
            @click="toggleMenu"
          />
          <Menu id="overlay_menu" ref="menu" :model="menuItems" :popup="true" />
          <Dialog
            header="Enter Template Name"
            :visible.sync="displayDialog"
            :containerStyle="{ width: '350px' }"
            :modal="true"
          >
            <div class="confirmation-content">
              <InputText
                class="w-full"
                v-model="templateName"
                placeHolder="Template Name"
              />
            </div>
            <template #footer>
              <Button
                label="Save"
                icon="pi pi-check"
                @click="saveAsTemplate"
                class="p-button-text"
              />
              <Button
                label="Cancel"
                icon="pi pi-times"
                @click="closeDialog"
                class="p-button-text"
                autofocus
              />
            </template>
          </Dialog>
        </div>
      </div> -->
    </div>
    <div class="card">
      <div
        class="flex sm:justify-content-start justify-content-around align-items-center"
      >
        <router-link v-for="r in path" :key="r.path" :to="{ path: r.route }">
          <h6
            :class="'sm:pr-5'"
            :style="'color: grey;' + getHeaderStyle(r.route)"
          >
            {{ r.header }}
          </h6>
        </router-link>
      </div>
      <Divider class="mt-0 mb-2"></Divider>

      <div class="pt-1">
        <div class="p-fluid formgrid grid">
          <div class="col-12 md:col-12 lg:col-12 xl:col-12">
            <div class="p-fluid formgrid grid justify-content-start p-2">
              <div class="field col-6 sm:col-6 md:col-4 lg:col-4 xl:col-6">
                <label class="font-light">Title</label>
                <div v-if="!isLoading">{{ form.title }}</div>
                <Skeleton height="18px" borderRadius="6px" v-else />
              </div>
              <div class="field col-3 sm:col-6 md:col-4 lg:col-4 xl:col-3">
                <label class="font-light">Client</label>
                <div v-if="!isLoading">
                  {{
                    form.client && form.client.name ? form.client.name : "N/A"
                  }}
                </div>

                <Skeleton height="18px" borderRadius="6px" v-else />
              </div>
              <div class="field col-3 sm:col-6 md:col-4 lg:col-4 xl:col-3">
                <label class="font-light" for="Contact">Contact</label>
                <template v-if="!isLoading">
                  <div v-if="estimates_contacts.length > 0">
                    {{
                      form.contact &&
                      form.contact.contactDetails &&
                      form.contact.contactDetails.contact_person
                        ? form.contact.contactDetails.contact_person
                        : "N/A"
                    }}
                  </div>
                  <div v-else-if="contacts.length > 0">
                    {{
                      form.contact &&
                      form.contact.contactDetails &&
                      form.contact.contactDetails.contact_person
                        ? form.contact.contactDetails.contact_person
                        : "N/A"
                    }}
                  </div>

                  <div v-else class="p-inputgroup">
                    <div>No contacts assigned yet</div>
                  </div></template
                >
                <Skeleton height="18px" borderRadius="6px" v-else />
              </div>
              <div class="field col-3 sm:col-6 md:col-4 lg:col-4 xl:col-3">
                <label class="font-light" for="Date">Date</label>
                <div v-if="!isLoading">
                  {{
                    form.created_date
                      ? form.created_date
                      : "N/A" | moment("DD MMM YY")
                  }}
                </div>

                <Skeleton height="18px" borderRadius="6px" v-else />
              </div>
              <div class="field col-3 sm:col-6 md:col-4 lg:col-4 xl:col-3">
                <label class="font-light" for="ValidUntil">Valid Until</label>
                <div v-if="!isLoading">
                  {{
                    form.valid_date
                      ? form.valid_date
                      : "N/A" | moment("DD MMM YY")
                  }}
                </div>

                <Skeleton height="18px" borderRadius="6px" v-else />
              </div>
              <div class="field col-3 sm:col-6 md:col-4 lg:col-4 xl:col-3">
                <label class="font-light" for="Terms">Terms</label>
                <div v-if="!isLoading">
                  {{
                    form.term && form.term.description
                      ? form.term.description
                      : "N/A"
                  }}
                </div>

                <Skeleton height="18px" borderRadius="6px" v-else />
              </div>
              <div class="field col-3 sm:col-6 md:col-4 lg:col-4 xl:col-3">
                <label class="font-light" for="Category">Category</label>
                <div v-if="!isLoading">
                  {{
                    form.term && form.category.description
                      ? form.category.description
                      : "N/A"
                  }}
                </div>

                <Skeleton height="18px" borderRadius="6px" v-else />
              </div>
            </div>

            <div class="field col-12 p-2">
              <label class="font-light" for="Description">Description</label>
              <div v-if="!isLoading" v-html="form.description"></div>

              <Skeleton height="108px" borderRadius="6px" v-else />
            </div>
          </div>
        </div>
        <div
          class="flex justify-content-between align-items-center my-3"
          style="font-size: 18px"
        >
          <div>
            <p class="p-t-12 p-2" style="font-size: 18px">Tasks &#38; Costs</p>
          </div>

          <div v-if="form.status == 'Draft'">
            <Button
              v-if="!addGroup"
              :disabled="editGroups && editGroups.length > 0"
              @click="insertGroup()"
              class="p-button-text p-button-secondary"
              label="New Group"
              icon="las la-plus"
              style="height: 42px"
            />
            <div v-else-if="isLoading"></div>
            <template v-else>
              <InputText
                ref="groupInput"
                v-model="newGroup"
                placeholder="Group Name"
              ></InputText>
              <Button
                icon="pi pi-check "
                :disabled="!newGroup"
                class="p-button-rounded grey-button ml-3"
                @click="postGroup(newGroup)"
              />
              <Button
                icon="pi pi-times "
                class="p-button-rounded grey-button ml-3"
                @click="
                  newGroup = null;
                  addGroup = false;
                "
              />
            </template>
          </div>
        </div>
        <template v-if="isLoading && estimate">
          <Skeleton
            v-for="f in estimate.groups"
            :key="f.uid"
            :height="f.items.length * 80 + 'px'"
            borderRadius="6px"
            class="mb-3"
        /></template>
        <template v-else>
          <div
            v-if="form.groups.length < 1"
            class="cursor-pointer"
            style="padding-top: 48px; padding: 80px; font-size: 16px"
            align="center"
            @click="insertGroup()"
          >
            <div
              style="
                display: inline-block;
                padding: 30px 80px;
                background: #f8fafb;
                border-radius: 100px;
              "
            >
              <a>Create a group</a>
              to start adding items.
            </div>
          </div>

          <draggable
            handle=".p-datatable-thead"
            :animation="140"
            :list="form.groups"
            @end="onEndGroup($event)"
          >
            <div
              v-for="u in form.groups"
              :key="u.id"
              style="margin-bottom: 24px"
            >
              <div class="header-pointer" :id="u.id" @click="handleHeaderClick">
                <DataTable
                  :value="u.items"
                  @row-reorder="onRowReorder(u, ...arguments)"
                  responsiveLayout="scroll"
                  :rowClass="rowClass"
                  :class="
                    minimized.indexOf(u.uid) > -1
                      ? 'minimized-table'
                      : 'maximized-table'
                  "
                  :filters.sync="filters"
                  class="dt-class hide-empty-dt"
                  editMode="row"
                  dataKey="uid"
                >
                  <Column
                    :rowReorder="true"
                    :headerStyle="{
                      width: '4%',
                    }"
                    :footerStyle="{
                      backgroundColor: 'white',
                    }"
                    :reorderableColumn="false"
                    ><template #header>
                      <i
                        v-if="minimized.indexOf(u.uid) > -1"
                        class="las la-plus cursor-pointer"
                      ></i>
                      <i v-else class="las la-minus cursor-pointer"></i>
                    </template>
                  </Column>

                  <Column
                    :styles="{
                      width: '60%',
                      verticalAlign: 'top !important',
                    }"
                    field="description"
                    :footerStyle="{
                      backgroundColor: 'white',
                    }"
                  >
                    <template #header>
                      <Skeleton v-if="isLoading"></Skeleton>
                      <div v-else-if="editGroup !== u.uid" class="font-light">
                        {{ u.description }}
                      </div>
                      <template v-else>
                        <InputText
                          ref="groupInput"
                          v-model="editGroupTitle"
                          placeholder="Group Name"
                        ></InputText>
                        <Button
                          icon="pi pi-check "
                          :disabled="!editGroupTitle"
                          class="p-button-rounded grey-button-flat p-button-text ml-1"
                          @click.stop="
                            u.description = editGroupTitle;
                            editGroupTitle = null;
                            editGroup = false;
                            save();
                          "
                        />
                        <Button
                          icon="pi pi-times "
                          class="p-button-rounded grey-button-flat p-button-text ml-1"
                          @click.stop="
                            editGroupTitle = null;
                            editGroup = false;
                          "
                        />
                      </template>
                    </template>
                    <template #body="slotProps">
                      <Skeleton v-if="isLoading"></Skeleton>
                      <div v-else>
                        <div
                          class="p-1 text-link"
                          @click="navEditMode(slotProps.data, u)"
                        >
                          <span class="cursor-pointer"
                            >{{ slotProps.data.item.type_name }}
                            <Badge
                              v-if="slotProps.data.item.new"
                              :style="'background-color: #fe4d97'"
                              class="ml-2"
                              value="Unsaved"
                            /><span v-if="slotProps.data.item.title"
                              >-&nbsp;{{ slotProps.data.item.title }}</span
                            ></span
                          >
                        </div>

                        <div
                          v-if="slotProps.data.item"
                          class="p-1 flex flex-wrap font-light item-description"
                        >
                          <div v-html="slotProps.data.item.description"></div>
                        </div>
                      </div>
                    </template>
                    <template #footer>
                      <div
                        :key="u.id + '_btns'"
                        class="flex justify-content-between"
                      >
                        <div
                          class="cursor-pointer col-9"
                          style="white-space: nowrap"
                          v-if="form.status == 'Draft'"
                        >
                          <template>
                            <a
                              @click="
                                $store.dispatch(
                                  'setSliderView',
                                  'CostEstimateTaskSlider'
                                );
                                $store.dispatch('setSelectedObject', {
                                  estimate: form,
                                  group: u,
                                });
                              "
                              label="Add Task"
                              style="color: #778ca2; font-size: 14px"
                            >
                              + Add Task
                            </a>
                            <a
                              @click="
                                $store.dispatch(
                                  'setSliderView',
                                  'CostEstimateCostSlider'
                                );
                                $store.dispatch('setSelectedObject', {
                                  estimate: form,
                                  group: u,
                                });
                              "
                              label="Add Cost"
                              style="
                                margin-left: 12px;
                                color: #778ca2;
                                font-size: 14px;
                              "
                            >
                              + Add Cost
                            </a></template
                          >
                        </div>
                      </div>
                    </template>
                  </Column>

                  <Column
                    field="option"
                    :styles="{
                      width: '1%',
                      whiteSpace: 'nowrap',
                      verticalAlign: 'top !important',
                    }"
                    :footerStyle="{
                      backgroundColor: 'white',
                    }"
                  >
                    <template #body="slotProps">
                      <Skeleton v-if="isLoading"></Skeleton>
                      <div
                        class="flex flex-column"
                        v-else-if="
                          slotProps.data.item && slotProps.data.item.options
                        "
                      >
                        <div
                          class="col pr-0 ce-co"
                          v-for="(o, index) in slotProps.data.item.options"
                          :key="'q' + o.uid"
                        >
                          <span v-if="index > 0">option</span>
                          <span
                            v-else
                            style="width: 100%; display: inline-block"
                          >
                          </span>
                        </div>
                      </div>
                      <div
                        v-else-if="slotProps.data.item"
                        class="col pr-0"
                      ></div>
                    </template>
                  </Column>

                  <Column
                    :header="
                      minimized.indexOf(u.uid) < 0
                        ? 'Qty'
                        : u.items.length +
                          ' item' +
                          (u.items.length === 1 ? '' : 's')
                    "
                    field="quantity"
                    :styles="{
                      width: '6%',
                      verticalAlign: 'top !important',
                      whiteSpace: 'nowrap',
                    }"
                    :footerStyle="{
                      backgroundColor: 'white',
                    }"
                  >
                    <template #body="slotProps">
                      <Skeleton v-if="isLoading"></Skeleton>
                      <div
                        class="flex flex-column"
                        v-else-if="
                          slotProps.data.item && slotProps.data.item.options
                        "
                      >
                        <div
                          class="col pr-0 ce-co"
                          v-for="o in slotProps.data.item.options"
                          :key="'q' + o.uid"
                        >
                          {{ formatAmount(o.quantity ? o.quantity : o.units) }}
                        </div>
                      </div>
                      <div v-else-if="slotProps.data.item" class="col pr-0">
                        {{ secToHM(slotProps.data.quantity * 3600) }}
                      </div>
                    </template>
                  </Column>
                  <Column
                    :header="
                      minimized.indexOf(u.uid) < 0 ? 'Price/Unit' : 'SUB TOTAL'
                    "
                    field="price"
                    :styles="{
                      width: '10%',
                      verticalAlign: 'top !important',
                      whiteSpace: 'nowrap',
                    }"
                    headerClass="header-right"
                    :footerStyle="{
                      backgroundColor: 'white',
                    }"
                  >
                    <template #body="slotProps">
                      <Skeleton v-if="isLoading"></Skeleton>
                      <div
                        class="flex flex-column"
                        v-else-if="
                          slotProps.data.item && slotProps.data.item.options
                        "
                      >
                        <div
                          class="col text-end pr-0 ce-co"
                          v-for="o in slotProps.data.item.options"
                          :key="'u' + o.uid"
                        >
                          {{
                            formatCurrency(
                              o.price ? o.price : o.resell_amount,
                              setCurrencySymbol
                            )
                          }}
                        </div>
                      </div>
                      <div
                        v-else-if="slotProps.data.item"
                        class="col text-end pr-0"
                      >
                        {{
                          formatCurrency(
                            slotProps.data.item.price,
                            setCurrencySymbol
                          )
                        }}
                      </div>
                    </template>
                  </Column>
                  <Column
                    headerClass="header-right"
                    :header="
                      minimized.indexOf(u.uid) < 0
                        ? 'Margin'
                        : formatCurrency(getSubTotal(u), setCurrencySymbol)
                    "
                    :footerStyle="{
                      backgroundColor: 'white',
                    }"
                    :styles="{
                      width: '10%',
                      flexFlow: 'row-reverse',
                      verticalAlign: 'top !important',
                    }"
                  >
                    <template #body="slotProps">
                      <Skeleton v-if="isLoading"></Skeleton>
                      <div
                        class="flex flex-column"
                        v-else-if="
                          slotProps.data.item && slotProps.data.item.options
                        "
                      >
                        <div
                          class="col text-end pr-0 ce-co"
                          v-for="(o, index) in slotProps.data.item.options"
                          :key="'m' + index"
                        >
                          {{ formatCurrency(getMargin(o), setCurrencySymbol) }}
                        </div>
                      </div>
                      <div v-else class="col text-end pr-3">-</div>
                    </template>
                    <template #footer v-if="u.items && u.items.length > 0">
                      <div
                        class="flex justify-content-end p-0 footer-sub-total"
                      >
                        <span style="font-weight: normal; margin-right: -1rem"
                          >SUB TOTAL</span
                        >
                      </div>
                    </template>
                  </Column>

                  <Column
                    headerClass="header-right"
                    :header="minimized.indexOf(u.uid) < 0 ? 'Price' : ''"
                    field="active"
                    :styles="{ width: '10%', verticalAlign: 'top !important' }"
                    :footerStyle="{
                      backgroundColor: 'white',
                    }"
                  >
                    <template #body="slotProps">
                      <Skeleton v-if="isLoading"></Skeleton>
                      <div
                        class="flex flex-column"
                        v-else-if="
                          slotProps.data.item && slotProps.data.item.options
                        "
                      >
                        <div
                          class="col text-end pr-0 ce-co"
                          v-for="(o, index) in slotProps.data.item.options"
                          :key="'p' + index"
                        >
                          {{
                            formatCurrency(
                              o.quantity * o.price,
                              setCurrencySymbol
                            )
                          }}
                        </div>
                      </div>
                      <div
                        v-else-if="slotProps.data.item"
                        class="col text-end pr-0"
                      >
                        {{
                          formatCurrency(
                            slotProps.data.quantity *
                              (slotProps.data.type == "cost_type"
                                ? slotProps.data.item.resell_amount
                                : slotProps.data.item.price),
                            setCurrencySymbol
                          )
                        }}
                      </div>
                    </template>

                    <template #footer v-if="u.items && u.items.length > 0">
                      <div class="flex justify-content-end">
                        {{ formatCurrency(getSubTotal(u), setCurrencySymbol) }}
                      </div>
                    </template>
                  </Column>

                  <Column
                    v-if="jobEstimate != null"
                    headerClass="header-center"
                    field="approval_status_uid"
                    :header="minimized.indexOf(u.uid) < 0 ? 'Status' : ''"
                    :styles="{ width: '12%', verticalAlign: 'top !important' }"
                    :footerStyle="{
                      backgroundColor: 'white',
                    }"
                  >
                    <template #body="slotProps">
                      <Skeleton v-if="isLoading"></Skeleton>
                      <div
                        class="flex flex-column"
                        v-else-if="slotProps.data.item"
                      >
                        <div
                          class="col text-center"
                          :style="
                            getApprovalStatusStyle(
                              setApprovalStatus(
                                slotProps.data.approval_status_uid
                              )
                            )
                          "
                        >
                          {{
                            setApprovalStatus(
                              slotProps.data.approval_status_uid
                            )
                          }}
                        </div>
                      </div>
                    </template>
                  </Column>

                  <Column
                    bodyStyle="text-align:right"
                    :styles="{ width: '4%' }"
                    :footerStyle="{
                      backgroundColor: 'white',
                    }"
                  >
                    <template #header>
                      <div
                        v-if="
                          !editGroup && !isLoading && form.status == 'Draft'
                        "
                        class="flex justify-content-end align-items-center cursor-pointer"
                      >
                        <i
                          @click.stop="
                            editGroup = u.uid;
                            editGroupTitle = u.description;
                          "
                          class="las hover-show la-pen i-20 grey mr-1"
                        />
                        <i
                          @click.stop="
                            deletePrompt(
                              u.description,
                              '/v1/estimate/group/delete/' + u.uid
                            )
                          "
                          class="las la-trash i-20 grey hover-show"
                        />
                      </div>
                    </template>
                    <template #body="slotProps">
                      <Skeleton v-if="isLoading"></Skeleton>
                      <div
                        v-else-if="
                          slotProps.data.item && form.status == 'Draft'
                        "
                        class="flex invisible justify-content-end align-items-center cursor-pointer"
                      >
                        <i
                          @click.stop="navEditMode(slotProps.data, u)"
                          class="las la-pen i-20 grey mr-1"
                        />
                        <i
                          @click.stop="
                            deletePrompt(
                              slotProps.data.item.type_name,
                              '/v1/estimate/item/delete/' + slotProps.data.uid
                            )
                          "
                          class="las la-trash grey i-20"
                        />
                      </div>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </draggable>
          <TotalTableFooter
            :subTotal="subTotal"
            v-if="
              form.groups &&
              form.groups.length > 0 &&
              form.groups[0].items.length > 0
            "
            :status="form.status"
            :currencySymbol="setCurrencySymbol"
            :profitMargin="profitMargin"
            :taxRate="parseFloat(taxRate)"
          ></TotalTableFooter>
        </template>
      </div>
    </div>
    <Dialog
      header="Select Cost Options"
      contentStyle="border-bottom-left-radius: 6px; border-bottom-right-radius: 6px"
      :visible.sync="display"
      :modal="true"
      :closable="true"
      ><ConvertCostOptions @dismiss="display = false"></ConvertCostOptions
    ></Dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {
  state,
  fetchEstimateCategories,
  fetchEstimateTerms,
  fetchEstimateClients,
  fetchCostEstimate,
  fetchJobEstimate,
  fetchApprovalStatuses,
} from "../../../services/data_service.js";
import SplitButton from "primevue/splitbutton";
import Vue from "vue";
import { FilterMatchMode } from "primevue/api";
import ConvertCostOptions from "@/application/Finance/components/ConvertCostOptions.vue";
import TotalTableFooter from "@/application/Finance/components/TotalTableFooter.vue";
export default {
  props: {
    isActive: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      display: false,
      editGroups: [],
      estimate: null,
      addGroup: null,
      newGroup: null,
      editGroup: null,
      minimized: [],
      contacts: [],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      originalRows: [],
      isLoading: true,
      isEdit: null,
      ceSeed: null,
      groupUID: null,
      groupDescription: null,
      displayAccessories: null,
      cost_estimate: {},
      templateName: null,
      displayDialog: false,
      taxRate: null,
      estimateStatus: null,
      estimateTemplateObject: {
        name: null,
      },
      menuItems: [
        {
          label: "Save as template",
          command: () => {
            this.displayDialog = true;
          },
        },
      ],
      form: {
        groups: [],
        ce_number: null,
      },
      options: [
        {
          icon: "pi pi-pencil",
          command: () => {
            this.editColumn(this.groupUID);
          },
        },
        {
          icon: "pi pi-trash",
          command: () => {
            this.deletePrompt(
              this.groupDescription + " group",
              "/v1/estimate/group/delete/" + this.groupUID
            );
          },
        },
      ],
      path: [
        {
          route: "/finance/estimates/",
          header: "GENERAL",
        },
        {
          route: "",
          header: "DOCUMENTS",
        },
        {
          route: "",
          header: "REVISIONS",
        },
      ],
    };
  },

  beforeMount() {
    state.estimate = null;
    state.jobEstimate = null;
  },
  created() {
    this.subscribeChannel();
    this.$root.$on("saveCostEstimate", this.save);
    this.$bus.on("refreshData", this.loadData);
  },

  beforeDestroy() {
    this.$pusher.unsubscribe("my-channel");
    this.$root.$off("saveCostEstimate", this.save);
    this.$bus.off("refreshData", this.loadData);
  },
  async mounted() {
    await this.loadData();
  },
  computed: {
    profitMargin() {
      var s = 0;
      this.form.groups.forEach((g) => {
        g.items.forEach((i) => {
          if (i.type === "task_type") {
            s += parseFloat(i.item.price);
          } else {
            if (i.item && i.item.options && i.item.options[0]) {
              s += this.getMargin(i.item.options[0]);
            }
          }
        });
      });
      return s;
    },
    setCurrencySymbol() {
      let currency = null;
      if (this.stateEstimate) {
        for (let i = 0; i < this.estimate_clients.length; i++) {
          if (this.stateEstimate.client.uid === this.estimate_clients[i].uid) {
            currency = this.estimate_clients[i].currency.description;
          }
        }
        return currency;
      }
      return null;
    },
    templateToEstimate() {
      return this.$store.getters.templateToEstimate;
    },
    estimate_categories() {
      return state.estimateCategories;
    },
    subTotal() {
      var s = 0;
      this.form.groups.forEach((g) => {
        s += this.getSubTotal(g);
      });
      return s;
    },
    stateEstimate() {
      return state.estimate;
    },
    jobEstimate() {
      return state.jobEstimate;
    },
    estimate_terms() {
      return state.estimateTerms;
    },
    estimate_clients() {
      return state.estimateClients;
    },
    estimates_contacts() {
      if (this.form.client) {
        var client = this.estimate_clients.find(
          (a) => a.uid === this.form.client.uid
        );
        if (client) {
          return client.contacts;
        }
        return [];
      }
      return [];
    },

    user() {
      return this.$store.getters.user;
    },
    approval_statuses() {
      return state.approvalStatuses;
    },
  },
  methods: {
    setAcceptLabel() {
      if (this.jobEstimate != null) {
        return "Accept and update Job";
      }
      return "Accept and create Job";
    },
    convertCostOptions() {
      this.display = true;
    },
    draftEstimate(uid) {
      this.$axios
        .post(process.env.VUE_APP_ROOT_API + "/v1/estimate/draft/" + uid, {
          headers: { "Content-type": "application/json" },
        })
        .then((response) => {
          this.form.status = "Draft";
          this.isLoading = false;
          this.$toast.add({
            severity: "success",
            detail: "Estimate saved and drafted",
            life: 3000,
          });
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },
    issueEstimate(uid) {
      this.$axios
        .post(process.env.VUE_APP_ROOT_API + "/v1/estimate/issue/" + uid, {
          headers: { "Content-type": "application/json" },
        })
        .then(async (response) => {
          this.displayDialog = false;
          this.$toast.add({
            severity: "success",
            summary: "Estimate saved and issued",
            life: 3000,
          });
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
        });
    },
    acceptEstimate(uid) {
      this.$axios
        .post(process.env.VUE_APP_ROOT_API + "/v1/estimate/accept/" + uid, {
          headers: { "Content-type": "application/json" },
        })
        .then((response) => {
          this.displayDialog = false;
          this.$toast.add({
            severity: "success",
            summary: "Estimate saved and accepted",
            life: 3000,
          });
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
        });
    },
    setApprovalStatus(uid) {
      var statusTitle = null;
      this.approval_statuses.map((status) => {
        if (status.uid == uid) {
          statusTitle = status.name;
        }
      });
      return statusTitle;
    },
    getApprovalStatusStyle(title) {
      if (title == "Draft") {
        return "background: #F3F5F6 !important;color: #687A8D !important; border-radius: 6px";
      }
      if (title == "Completed") {
        return "background: #FFF8EE !important;color: #FFB441 !important; border-radius: 6px";
      }
      if (title == "Active") {
        return "background: #F4FBEF !important;color: #6dd230 !important; border-radius: 6px";
      }
      if (title == "Invoiced") {
        return "background: #F1F5FF !important;color: #4D7CFE !important; border-radius: 6px";
      }
    },
    changeStatusPrompt() {
      this.$confirm.require({
        message: "Editing this Cost Estimate will change the status to Draft.",
        header: "Confirm status update",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.draftEstimate(this.form.uid);
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    setEstimate() {
      if (this.stateEstimate || this.jobEstimate) {
        var form =
          this.stateEstimate != null ? this.stateEstimate : this.jobEstimate;
        if (form.groups) {
          form.groups = form.groups.sort((a, b) =>
            a.sort_index > b.sort_index ? 1 : -1
          );
          form.groups.forEach((g) => {
            g.id = g.uid;
            if (g.items) {
              g.items = g.items.sort((a, b) =>
                a.sort_index > b.sort_index ? 1 : -1
              );
              g.items.forEach((i) => {
                i.id = i.uid;
                i.exists = true;
                if (i.cost_type) {
                  i.item = i.cost_type[0];
                  delete i.cost_type;
                  i.type = "cost_type";
                }
                if (i.task_type) {
                  i.item = i.task_type[0];
                  delete i.task_type;
                  i.type = "task_type";
                }
              });
            }
          });
        }
        this.form = form;
      }
    },
    handleContacts(id) {
      this.estimate_clients.forEach((client) => {
        if (client.uid === id.uid) {
          this.contacts = client.contacts;

          this.contacts.forEach((c) => {
            c.contact_name =
              c.contactDetails.name + " " + c.contactDetails.surname;
          });
        }
      });
    },
    goBack() {
      if (this.stateEstimate && this.stateEstimate.is_template === 1) {
        this.$router.push("/settings/templates/estimate_templates");
        this.$store.dispatch("setEstimateToTemplate", null);
      } else {
        this.$router.push("/finance/estimates");
        this.$store.dispatch("setEstimateToTemplate", null);
      }
    },
    saveAsTemplate() {
      if (this.templateName) {
        this.estimateTemplateObject.name = this.templateName;
        this.$axios
          .post(
            process.env.VUE_APP_ROOT_API +
              "/v1/estimate/template/" +
              this.stateEstimate.uid,
            JSON.stringify(this.estimateTemplateObject),
            {
              headers: { "Content-type": "application/json" },
            }
          )
          .then((response) => {
            this.displayDialog = false;
            this.$toast.add({
              severity: "success",
              summary: "Template Saved",
              life: 3000,
            });
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              console.error("NOOOOOOOOO!");
            } else {
              // Handle error however you want
            }
          });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Template name not entered",
          life: 3000,
        });
      }
    },
    closeDialog() {
      this.displayDialog = false;
    },
    toggleTemplate(event) {
      this.$refs.op.toggle(event);
    },
    toggleMenu(event) {
      this.$refs.menu.toggle(event);
    },
    getTagStyle() {
      if (this.form) {
        if (this.form.status === "Draft") {
          return "background: #B9C5D0 !important;color: #252631 !important;";
        }
        if (this.form.status === "Issued") {
          return "background: #FFF8EE !important;color: #FFAB2B !important;";
        }
        if (this.form.status === "Converted") {
          return "background: #F4FBEF !important;color: #98D34F !important;";
        }
        return "background: #f4fbef !important;color: #6dd230 !important;";
      }
    },
    toggle(event, group) {
      this.$refs.menu[0].toggle(event);
      if (group) {
        this.groupDescription = group.description;
        this.groupUID = group.id;
      }
    },
    async loadData() {
      var arr = [
        fetchEstimateCategories(),
        fetchEstimateTerms(),
        fetchEstimateClients(),
      ];
      if (this.$route.name == "finance--estimate" && this.$route.params.uid) {
        arr.push(fetchCostEstimate(this.$route.params.uid));
      }

      if (this.$route.name == "job--tasks") {
        arr.push(fetchJobEstimate(this.$route.params.uid));
        fetchApprovalStatuses();
      }

      await Promise.all(arr);
      this.setEstimate();
      if (this.stateEstimate) {
        this.taxRate = this.stateEstimate.client.tax_rate;
      }
      if (this.jobEstimate) {
        this.taxRate = this.jobEstimate.client.tax_rate;
      }
      this.isLoading = false;
    },
    getCESeed() {
      this.$axios
        .get(process.env.VUE_APP_ROOT_API + "/v1/estimate/get/seed", {})
        .then((response) => {
          this.form.ce_number = response.data.data[0].counter;
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO! You have no power here.");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },
    getSubTotal(group) {
      let amount = 0;
      if (group) {
        group.items.map((i) => {
          if (i.quantity) {
            if (i.type == "cost_type" && i.item && i.item.options[0]) {
              amount += i.item.options[0].quantity * i.item.options[0].price;
            }
            if (i.type == "task_type" && i.item) {
              amount += i.item.price * i.quantity;
            }
          }
        });
      }
      return amount;
    },
    getMargin(obj) {
      if (obj.type !== "task_type") {
        return (
          parseFloat(obj.price) * obj.quantity -
          parseFloat(obj.unit_price) * obj.quantity
        );
      }
      return "-";
    },
    getHeaderStyle(route) {
      if (this.$route.path == route) {
        return "font-weight: bold; border-bottom: 2px solid #4D7CFE; color: #4D7CFE";
      }
    },
    subscribeChannel() {
      var channel = this.$pusher.subscribe("my-channel");
      channel.bind("my-event", (data) => {
        this.loadData();
      });
    },
    rowClass(data) {
      return data.is_complete == 0
        ? "row-accessories row-accessories2"
        : "row-accessories row-accessories2";
    },
    deleteItem(id) {
      this.form.groups.forEach((g) => {
        g.items.forEach((i) => {
          if (i.id == id) {
            g.items.splice(g.items.indexOf(i), 1);
          }
        });
      });
    },
    save(redirect = false, message = false) {
      var form = JSON.parse(JSON.stringify(this.form));
      var uid = form ? form.uid : null;
      form.status = form.status ? form.status : "Draft";

      var i = 0;
      form.groups.forEach((a) => {
        a.sort_index = i;
        i++;
      });

      var objectAmount = 0;
      if (form.groups && form.groups.length > 0) {
        form.groups.forEach((g) => {
          var index = 0;
          var totalAmount = 0;
          if (g.items && g.items.length > 0) {
            var amount = 0;
            g.items.forEach((i) => {
              i[i.type] = [i.item];
              amount = amount + i.item.price * i.quantity;
              delete i.item;
              i.sort_index = index;
              if (i.type === "cost_type") {
                i.cost_type[0].sort_index = index;
                index++;
                if (i.cost_type && i.cost_type.length > 0) {
                  i.cost_type.map((c) => {
                    if (c.options) {
                      c.options.map((m) => {
                        if (!m.units) {
                          m.units = parseInt(m.quantity);
                        }
                        if (!m.unit_cost) {
                          m.unit_cost = parseFloat(m.unit_price);
                        }

                        if (!m.markup) {
                          m.markup = parseFloat(m.margin);
                        }
                        if (!m.resell_amount) {
                          m.resell_amount = parseFloat(m.price);
                        }
                      });
                    }
                  });
                }
              } else {
                i.task_type[0].sort_index = index;
                index++;
              }
            });
            g.items.amount = amount;
            totalAmount = totalAmount + amount;
          }
          g.totalAmount = totalAmount;
          objectAmount = objectAmount + totalAmount;
        });
      }
      form.amount = objectAmount;
      //form.amount = this.subTotal;
      if (!form.revision) {
        form.revision = 1;
      } else {
        form.revision++;
      }

      if (!form.issued_date) {
        form.issued_date = "";
      }

      if (!form.approved_date) {
        form.approved_date = "";
      }

      if (!form.internal_message) {
        form.internal_message = "";
      }

      if (!form.client_message) {
        form.client_message = "";
      }
      if (form.created_date) {
        form.created_date = this.$moment(form.created_date).format(
          "YYYY-MM-DD"
        );
      }
      if (form.valid_date) {
        form.valid_date = this.$moment(form.valid_date).format("YYYY-MM-DD");
      }
      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API +
            "/v1/estimate/" +
            (uid ? "update/" + uid : "create"),
          JSON.stringify(form),
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          if (message) {
            this.$toast.add({
              severity: "success",
              summary: uid ? "Estimate Updated" : "Estimate Created",
              life: 3000,
            });
          }
          if (redirect) {
            this.$store.dispatch("setEstimateToTemplate", null);
            this.goBack();
          }

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },
    removeUids() {
      this.form.groups.forEach((g) => {
        g.items.forEach((i) => {
          if (!i.exists) {
            delete i[i.type].uid;
          }
        });
      });
    },
    onRowEditInit(event) {
      this.originalRows[event.index] = {
        ...this.selectedDepartments[event.index],
      };
    },
    onRowEditCancel(event) {
      Vue.set(
        this.selectedDepartments,
        event.index,
        this.originalRows[event.index]
      );
    },
    editColumn(id) {
      this.editGroups.push(id);
    },
    addItem(g, t) {
      if (this.isEdit !== null) {
        if (g.items && g.items.length > 0) {
          if (g.items.find((a) => !a.item) !== undefined) {
            this.$toast.add({
              severity: "error",
              summary: "Please select an item",
              life: 3000,
            });
            return;
          }
        }
      }
      var id = this.uuidv4();
      var r = {
        id: id,
        active: 1,
        type: t,
        quantity: 1.0,
      };
      this.isEdit = id;
      g.items.push(r);
    },
    handleEditGroup(g) {
      if (g.description && g.description.length > 0) {
        // if (g.uid.indexOf("NEWGROUP") > -1) {
        //   var newGroup = await this.postGroup(g);
        //   g.uid = newGroup.data.data;
        // } else {
        //   await this.postGroup(g, g.uid, true);
        // }
        this.editGroups = [];
      }
    },
    postGroup(g) {
      var id = this.uuidv4();
      this.form.groups.unshift({
        description: g,
        items: [],
        id: id,
      });

      this.save();
      this.newGroup = null;
      this.addGroup = !this.addGroup;
    },
    isEditColumn(g) {
      return this.editGroups.indexOf(g.id) > -1;
    },
    // getItems(array) {
    //   var ret = [];
    //   Object.keys(array).forEach((a) => {
    //     if (array[a].cost_type && array[a].cost_type.length > 0) {
    //       array[a].cost_type.forEach((c) => {
    //         ret.push(c);
    //       });
    //     }
    //     if (array[a].task_type && array[a].task_type.length > 0) {
    //       array[a].task_type.forEach((t) => {
    //         ret.push(t);
    //       });
    //     }
    //   });
    //   return ret;
    // },
    // blurItem(r) {
    //   if (r.cost_type && r.cost_type.length > 0) {
    //     r.cost_type[0] = r.item;
    //   }
    //   if (r.task_type && r.task_type.length > 0) {
    //     r.task_type[0] = r.item;
    //   }
    // },
    navEditMode(ev, group) {
      if (this.form.status == "Draft") {
        this.$store.dispatch("setSelectedObject", {
          estimate: this.form,
          group: group,
          item: ev,
        });
        if (ev.type === "task_type") {
          this.$store.dispatch("setSliderView", "CostEstimateTaskSlider");
        } else {
          this.$store.dispatch("setSliderView", "CostEstimateCostSlider");
        }
      }
    },
    handleHeaderClick(ev) {
      var isHeader = false;
      var uid = null;
      if (ev && ev.composedPath) {
        const path = ev.composedPath();
        for (let element of path) {
          if (isHeader && uid) {
            break;
          }
          if (element.id) {
            uid = element.id;
          }
          if (element.tagName === "TH") {
            isHeader = true;
          }
          if (
            element.tagName === "TBODY" ||
            element.tagName === "INPUT" ||
            element.tagName === "TFOOT"
          ) {
            break;
          }
        }
      }
      if (uid) {
        this.expand(uid);
      }
    },
    onRowReorder(e, ev) {
      e.items = ev.value;

      var data = {};
      var c = 0;
      e.items.forEach((e) => {
        data[e.uid] = c;
        c++;
      });

      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API + "/v1/estimate/items/move",
          JSON.stringify(data),
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .catch((error) => {
          console.error(error);
        });
    },
    expand(uid) {
      const index = this.minimized.indexOf(uid);
      if (index > -1) {
        this.minimized.splice(index, 1); // 2nd parameter means remove one item only
      } else {
        this.minimized.push(uid);
      }
    },
    onEndGroup(e) {
      this.save();
    },
    insertGroup() {
      this.addGroup = !this.addGroup;
      // this.$nextTick((r) => {
      //   this.$refs.groupInput.$el.focus();
      // });
    },
  },
  watch: {
    async isActive(n, o) {
      if (n === true) {
        await this.loadData();
      }
    },
  },

  metaInfo() {
    return {
      title: this.stateEstimate
        ? "Estimate | " + this.stateEstimate.title
        : "New Cost Estimate",
      meta: [{ vmid: "description", name: "description", content: "" }],
    };
  },
  name: "CostEstimate",
  components: { draggable, ConvertCostOptions, TotalTableFooter },
};
</script>

<style lang="scss">
.headerHover:hover {
  color: #4d7cfe !important;
  display: flex !important;
}
.p-menu {
  width: max-content !important;
}

.border-bottom-only {
  border-bottom: 1px rgb(222, 226, 230);
  border-bottom-style: solid;
}

.border-top-only {
  border-top: 1px rgb(222, 226, 230);
  border-top-style: solid;
}

.footer-color > .p-datatable-table .p-datatable-tfoot > tr > td {
  background-color: white !important;
}

.text-end {
  text-align: end !important;
}

.table-row:hover .hover-show {
  visibility: unset !important;
}
.hover-show:hover {
  visibility: unset !important;
}

.table-row .hover-show {
  visibility: hidden !important;
}

.header-right > .p-column-header-content {
  justify-content: end !important;
}

.header-center > .p-column-header-content {
  justify-content: center !important;
}
.ce-co {
  font-style: italic !important;
  color: #778ca2 !important;
  font-weight: 400 !important;
}

.ce-co:first-child {
  font-style: unset !important;
  color: unset !important;
  font-weight: unset !important;
}

.hide-empty-dt
  > .p-datatable-wrapper
  > .p-datatable-table
  > .p-datatable-tbody
  > .p-datatable-emptymessage
  > td {
  display: none !important;
}

.subTotalHeader > .p-column-header-content {
  margin-right: -1rem;
  justify-content: end;
  font-weight: normal;
  color: rgb(73, 80, 87);
}

.subTotalSecondHeader > .p-column-header-content {
  justify-content: end !important;
  font-weight: normal !important;
  color: rgb(73, 80, 87) !important;
  text-align: left !important;
}

.invisible-header-table > .p-datatable-thead {
  background: transparent !important;
}

.item-description {
  line-height: 1.5;
}

.p-datatable .p-datatable-tfoot > tr > td {
  border: none !important;
}
.button-outline {
  border: 1px solid #0036cb !important;
  color: #0036cb !important;
}
</style>
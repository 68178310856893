<template>
  <div>
    <div class="task-group">
      <div
        class="job-entry"
        style="background: #F8FAFB; border-radius: 0px; padding: 15px 10px;"
      >
        <div class="this-title">
          <div class="loading-slot is-30" /><div class="loading-slot is-10" />
        </div>
        <div class="this-status">
          <div class="loading-slot is-30" />
        </div>
        <div class="this-duedate">
          <div class="loading-slot is-30" />
        </div>
        <div class="this-budget">
          <div class="loading-slot is-30" />
        </div>
        <div class="this-count">
          <div class="loading-slot is-30" />
        </div>
      </div>
    </div>

    <div
      class="job-entry"
      style="background: #fff; border-bottom: 1px solid #E8ECEF;"
    >
      <div class="this-title">
        <div class="loading-slot is-40" /><div class="loading-slot is-10" />
      </div>
      <div class="this-status">
        <div>
          <div class="profile-flex">
            <span class="profile-picture"><div class="this-profile loading-slot" /></span>
          </div>
        </div>
      </div>
      <div class="this-duedate">
        <div class="loading-slot is-10" /><div class="loading-slot is-30" /><div class="loading-slot is-10" />
        <div class="this-caption">
          <span class="status-circle" /> <div class="loading-slot is-10 is-thin" /> <div class="loading-slot is-30 is-thin" />
        </div>
      </div>
      <div class="this-budget">
        <div class="this-caption">
          <div class="loading-slot is-20 is-thin" /><div class="loading-slot is-20 is-thin" />
          <progress
            class="progress is-success is-small"
            style="height: 7px;"
            max="100"
          >
            1%
          </progress>
        </div>
      </div>
    </div>

    <div
      class="job-entry"
      style="background: #fff; border-bottom: 1px solid #E8ECEF;"
    >
      <div class="this-title">
        <div class="loading-slot is-30" /><div class="loading-slot is-10" /><div class="loading-slot is-20" />
      </div>
      <div class="this-status">
        <div>
          <div class="profile-flex">
            <span class="profile-picture"><div class="this-profile loading-slot" /></span>
          </div>
        </div>
      </div>
      <div class="this-duedate">
        <div class="loading-slot is-10" /><div class="loading-slot is-30" /><div class="loading-slot is-10" />
        <div class="this-caption">
          <span class="status-circle" /> <div class="loading-slot is-10 is-thin" /> <div class="loading-slot is-30 is-thin" />
        </div>
      </div>
      <div class="this-budget">
        <div class="this-caption">
          <div class="loading-slot is-20 is-thin" /><div class="loading-slot is-20 is-thin" />
          <progress
            class="progress is-success is-small"
            style="height: 7px;"
            max="100"
          >
            1%
          </progress>
        </div>
      </div>
    </div>

    <div
      class="job-entry"
      style="background: #fff; border-bottom: 1px solid #E8ECEF;"
    >
      <div class="this-title">
        <div class="loading-slot is-20" /><div class="loading-slot is-10" />
      </div>
      <div class="this-status">
        <div>
          <div class="profile-flex">
            <span class="profile-picture"><div class="this-profile loading-slot" /></span>
          </div>
        </div>
      </div>
      <div class="this-duedate">
        <div class="loading-slot is-10" /><div class="loading-slot is-30" /><div class="loading-slot is-10" />
        <div class="this-caption">
          <span class="status-circle" /> <div class="loading-slot is-10 is-thin" /> <div class="loading-slot is-30 is-thin" />
        </div>
      </div>
      <div class="this-budget">
        <div class="this-caption">
          <div class="loading-slot is-20 is-thin" /><div class="loading-slot is-20 is-thin" />
          <progress
            class="progress is-success is-small"
            style="height: 7px;"
            max="100"
          >
            1%
          </progress>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TaskLoading',
  props: {
    task: Object,
  }
}
</script>
<template>
  <div
    class="flex align-items-center justify-content-center bg-black"
    style="height: 100vh"
  >
    <div class="flex justify-content-center text-white">
      <div class="w-30rem">
        <div
          class="text-24 mb-6 flex align-items-center"
          style="margin-top: 40%"
        >
          <img
            class="mr-3"
            alt="Logo"
            src="../../../assets/icons/kanban.svg"
            style="width: 50px"
          />

          <div>
            Simulate your business structure with
            <span class="text-primary"> Departments.</span>
          </div>
        </div>
        <div class="text-24 mb-6 flex align-items-center">
          <img
            class="mr-3"
            alt="Logo"
            src="../../../assets/icons/circle_check.svg"
            style="width: 50px"
          />
          <div>
            Quickly build estimates from pre-defined
            <span class="text-primary"> Task types.</span>
          </div>
        </div>
        <div class="text-24 mb-6 flex align-items-center">
          <img
            class="mr-3"
            alt="Logo"
            src="../../../assets/icons/document_process.svg"
            style="width: 50px"
          />
          <div>
            Set up multi-currency <span class="text-primary"> Cost types</span>

            for outsourced work.
          </div>
        </div>
        <div class="text-24 mb-6 flex align-items-center">
          <img
            class="mr-3"
            alt="Logo"
            src="../../../assets/icons/history_setting.svg"
            style="width: 50px"
          />
          <div>
            Manage resources intuitively
            <span class="text-primary"> with the Task Scheduler.</span>
          </div>
        </div>
        <div class="text-center" style="margin-top: 40%">
          <img
            alt="Logo"
            class="mb-3"
            src="../../../assets/logo_new.svg"
            style="width: 250px"
          />

          <div>Plan • Schedule • Work • Profit</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style>
</style>
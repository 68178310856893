<template>
  <div>
    <div
      class="flex flex-wrap w-full estimates justify-content-between align-items-center"
    >
      <div
        class="flex flex-wrap sm:justify-content-end justify-content-center align-items-center"
      >
        <h1 class="p-3 mr-1">Cost Estimates</h1>
        <div
          :class="
            'flex align-items-center p-3 mr-3 font-light cursor-pointer  ' +
            (filters.indexOf('estimates') >= 0
              ? 'bottom-border-blue'
              : 'bottom-border-invisible')
          "
          @click="
            filter('estimates');
            toggleFilter();
          "
        >
          All
          <Badge
            v-if="isLoading === false"
            :style="
              'background-color: ' +
              (estimates.length > 0 ? '#6187FF' : '#E8ECEF')
            "
            class="ml-2"
            :value="setTotalAmount(this.allTotalAmount)"
          />
          <Badge v-else style="background-color: #e8ecef" class="ml-2" />
        </div>
        <div
          :class="
            'flex align-items-center  p-3 mr-3  font-light cursor-pointer  ' +
            (filters.indexOf('draft') >= 0
              ? 'bottom-border-warning'
              : 'bottom-border-invisible')
          "
          @click="
            filter('draft');
            toggleFilter();
          "
        >
          Draft
          <Badge
            v-if="isLoading === false"
            :style="
              'background-color: ' + (draft.length > 0 ? '#ffab2b' : '#E8ECEF')
            "
            class="ml-2"
            :value="setTotalAmount(this.draftTotalAmount)"
          />
          <Badge v-else style="background-color: #e8ecef" class="ml-2" />
        </div>
        <div
          :class="
            'flex align-items-center  p-3 mr-3  font-light cursor-pointer  ' +
            (filters.indexOf('issued') >= 0
              ? 'bottom-border-positive'
              : 'bottom-border-invisible')
          "
          @click="
            filter('issued');
            toggleFilter();
          "
        >
          Issued
          <Badge
            v-if="isLoading === false"
            :style="
              'background-color: ' + (issued.length > 0 ? '#82D819' : '#E8ECEF')
            "
            class="ml-2"
            :value="setTotalAmount(this.issuedTotalAmount)"
          />
          <Badge v-else style="background-color: #e8ecef" class="ml-2" />
        </div>
        <div
          :class="
            'flex align-items-center  p-3 mr-3  font-light cursor-pointer  ' +
            (filters.indexOf('expired') >= 0
              ? 'bottom-border-pink'
              : 'bottom-border-invisible')
          "
          @click="
            filter('expired');
            toggleFilter();
          "
        >
          Expired
          <Badge
            v-if="isLoading === false"
            :style="
              'background-color: ' +
              (expired.length > 0 ? '#fe4d97' : '#E8ECEF')
            "
            class="ml-2"
            :value="setTotalAmount(this.expiredTotalAmount)"
          />
          <Badge v-else style="background-color: #e8ecef" class="ml-2" />
        </div>
      </div>
    </div>

    <div class="card pt-2">
      <div
        class="flex flex-wrap justify-content-between align-items-center py-3"
      >
        <div class="flex">
          <div>
            <FilterSearchButton v-model="tableFilters['global'].value" />
          </div>
          <div class="px-3">
            <Dropdown
              :options="estimates"
              placeholder="Filter by"
              :filter="true"
              dataKey="uid"
              optionLabel="title"
              optionValue="uid"
              :showClear="true"
              v-model="filteredEstimate"
            >
              <template #indicator>
                <i class="la la-filter" />
              </template>
            </Dropdown>
          </div>
          <div>
            <Button
              label="New Cost Estimate"
              class="p-button-text p-button-secondary"
              @click="
                $store.dispatch('setSelectedObject', null);
                $store.dispatch('setSliderView', 'CostEstimateSlider');
              "
              icon="las la-plus"
            />
          </div>
        </div>
        <div
          v-if="this.showFilterTotal === true"
          class="flex align-items-center"
        ></div>
      </div>
      <div>
        <DataTable
          responsiveLayout="scroll"
          :rowClass="rowClass"
          :value="filterEstimates()"
          editMode="row"
          :filters.sync="tableFilters"
          dataKey="uid"
          :paginator="true"
          :rows="30"
          :rowsPerPageOptions="[30, 50, 100]"
        >
          <template #empty>
            <Button
              label="New Cost Estimate"
              class="p-button-text p-button-secondary"
              @click="
                $store.dispatch('setSelectedObject', null);
                $store.dispatch('setSliderView', 'CostEstimateSlider');
              "
              icon="las la-plus"
          /></template>
          <Column sortable field="ce_number" header="CE">
            <template #body="slotProps">
              <Skeleton width="35%" v-if="isLoading"></Skeleton>
              <span
                v-else
                @click="$router.push('/finance/estimate/' + slotProps.data.uid)"
                class="cursor-pointer"
              >
                {{ slotProps.data[slotProps.column.field] }}
              </span>
            </template>
          </Column>
          <Column field="title" header="Title">
            <template #body="slotProps">
              <Skeleton width="35%" v-if="isLoading"></Skeleton>
              <div
                v-else
                @click="$router.push('/finance/estimate/' + slotProps.data.uid)"
              >
                {{ slotProps.data[slotProps.column.field] }}
              </div>
            </template>
          </Column>
          <Column field="client" header="Client">
            <template #body="slotProps">
              <Skeleton width="35%" v-if="isLoading"></Skeleton>
              <div v-else>
                {{ slotProps.data[slotProps.column.field].name }}
              </div>
            </template>
          </Column>
          <Column field="created_date" header="Date" sortable>
            <template #body="slotProps">
              <Skeleton width="35%" v-if="isLoading"></Skeleton>
              <div v-else>
                {{
                  slotProps.data[slotProps.column.field] | moment("DD MMM YY")
                }}
              </div>
            </template>
          </Column>
          <Column sortable field="status" header="Status">
            <template #body="slotProps"
              ><Skeleton width="35%" v-if="isLoading"></Skeleton>
              <div v-else>
                <span class="cursor-pointer">
                  {{ slotProps.data[slotProps.column.field] }}
                </span>
              </div>
            </template>
          </Column>
          <Column field="amount" header="Amount">
            <template #body="slotProps">
              <Skeleton width="35%" v-if="isLoading"></Skeleton>
              <div v-else>
                {{ getTotalCurrency(slotProps.data) }}
              </div>
            </template>
          </Column>
          <Column bodyStyle="text-align:right" :styles="{ width: '1rem' }">
            <template #body="slotProps">
              <div
                class="flex justify-content-end align-items-center invisible cursor-pointer"
              >
                <i
                  @click.stop="
                    $store.dispatch('setSelectedObject', slotProps.data);
                    $store.dispatch('setSliderView', 'CostEstimateSlider');
                  "
                  class="las la-pen i-20 grey pr-1"
                />
                <i
                  @click.stop="
                    deletePrompt(
                      slotProps.data.title,
                      '/v1/estimate/delete/' + slotProps.data.uid
                    )
                  "
                  class="las la-trash i-20 grey"
                />
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import {
  state,
  fetchEstimatesSummary,
  fetchAccounts,
  fetchCurrency,
} from "../../../services/data_service.js";
import { FilterMatchMode } from "primevue/api";
import Vue from "vue";
export default {
  data() {
    return {
      drag: false,
      isLoading: true,
      filterTitle: null,
      showFilterTotal: false,
      originalRows: [],
      editingRows: [],
      filteredEstimate: null,
      editingRow: null,
      tableFilters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filters: [],
    };
  },
  computed: {
    currencies() {
      return state.currencies;
    },
    estimates() {
      if (state.estimatesSummary) {
        return state.estimatesSummary.filter((e) => e.is_template == 0);
      }
      return null;
    },

    draft() {
      if (state.estimatesSummary) {
        return state.estimatesSummary.filter(
          (e) => e.status === "Draft" && e.is_template === 0
        );
      }
      return null;
    },
    issued() {
      if (state.estimatesSummary) {
        return state.estimatesSummary.filter(
          (e) => e.status === "Issued" && e.is_template === 0
        );
      }
      return null;
    },
    expired() {
      if (state.estimatesSummary) {
        return state.estimatesSummary.filter(
          (e) => e.status === "Expired" && e.is_template === 0
        );
      }
      return null;
    },
    allTotalAmount() {
      var total = 0;
      this.estimates.forEach((a) => {
        total += a.amount * (a.currency_conversion ? a.currency_conversion : 1);
      });
      return total;
    },
    draftTotalAmount() {
      var total = 0;
      this.draft.forEach((d) => {
        total += d.amount * (d.currency_conversion ? d.currency_conversion : 1);
      });
      return total;
    },
    issuedTotalAmount() {
      var total = 0;
      this.issued.forEach((i) => {
        total += i.amount * (i.currency_conversion ? i.currency_conversion : 1);
      });
      return total;
    },
    expiredTotalAmount() {
      var total = 0;
      this.expired.forEach((e) => {
        total += e.amount * (e.currency_conversion ? e.currency_conversion : 1);
      });
      return total;
    },
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
  },
  async mounted() {
    this.isLoading = true;
    if (!this.estimatesSummary) {
      state.estimatesSummary = [{}, {}, {}, {}, {}, {}];
    }

    if (this.filterTitle === null) {
      this.filterTitle = "estimates";
      this.toggleFilter();
      this.filter(this.filterTitle);
    }
    await this.loadData();
    this.isLoading = false;
  },
  methods: {
    setTotalAmount(amount) {
      return this.formatCurrency(amount);
    },
    toggleFilter() {
      if (this.showFilterTotal === false) {
        this.showFilterTotal = true;
      } else {
        this.showFilterTotal = false;
        this.totalAmount = 0;
      }
    },
    filterEstimates() {
      if (this.estimates && this.isLoading == false) {
        var arr = this.estimates.filter((e) => e.is_template === 0);
        if (this.filters.indexOf("estimates") >= 0) {
          arr = arr.filter((e) => e.is_template === 0);
        }
        if (this.filters.indexOf("draft") >= 0) {
          arr = arr.filter((e) => e.status === "Draft" && e.is_template === 0);
        }
        if (this.filters.indexOf("issued") >= 0) {
          arr = arr.filter((e) => e.status === "Issued" && e.is_template === 0);
        }
        if (this.filters.indexOf("expired") >= 0) {
          arr = arr.filter(
            (e) => e.status === "Expired" && e.is_template === 0
          );
        }
        arr.reverse();
        return arr;
      }
      return [{}, {}, {}, {}];
    },
    filter(f) {
      this.filterTitle = f;
      var i = this.filters.indexOf(f);
      if (i >= 0) {
        this.filters.splice(i, 1);
      } else if (this.filters.length > 0) {
        this.filters.push(f);
        this.filterTitle = this.filters[0];
        this.toggleFilter();
        this.filter(this.filterTitle);
      } else {
        this.filters.push(f);
      }
    },
    getTotalCurrency(estimate) {
      let currency = null;
      for (let i = 0; i < this.currencies.length; i++) {
        if (estimate.client.currency_uid === this.currencies[i].uid) {
          currency = this.currencies[i].description;
        }
      }
      return this.formatCurrency(estimate.amount, currency);
    },
    getTitleTotalAmount(estimate) {
      let amount = 0;
      estimate.groups.map((group) =>
        group.items.map((item) => {
          if (item.cost_type || item.task_type) {
            if ("cost_type" in item && item.cost_type.length > 0) {
              item.cost_type.map(
                (cost) => (amount += cost.resell_amount * item.quantity)
              );
            }
            if ("task_type" in item && item.task_type.length > 0) {
              item.task_type.map(
                (task) => (amount += task.price * item.quantity)
              );
            }
          }
        })
      );
      return amount;
    },
    rowClass() {
      return "row-accessories row-accessories2";
    },
    async loadData() {
      await Promise.all([
        fetchEstimatesSummary(),
        fetchAccounts(),
        fetchCurrency(),
      ]);
    },

    editRow(r, arr) {
      this.editingRow = r.uid;
      this.editingRows.push(r);
      var index = arr.indexOf(r);
      this.originalRows[index] = {
        ...arr[index],
      };
    },
    saveRow(r) {
      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API + "/v1/departments/" + r.uid,
          JSON.stringify(r),
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          this.editingRow = null;
          var i2 = this.editingRows.indexOf(r);
          this.editingRows.splice(i2, 1);
          this.$toast.add({
            severity: "success",
            summary: "Department Updated",
            life: 3000,
          });

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },

    onRowEditCancel(r, arr) {
      var index = arr.indexOf(r);
      Vue.set(arr, index, this.originalRows[index]);
      this.editingRow = null;
      var i2 = this.editingRows.indexOf(r);
      this.editingRows.splice(i2, 1);
    },
  },
  metaInfo: {
    title: "Cost Estimates",

    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: "" },
      { name: "keywords", content: "" },
    ],
  },
};
</script>


var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout-topbar justify-content-start"},[_c('button',{staticClass:"p-link layout-menu-button layout-topbar-button justify-content-center text-primary",on:{"click":_vm.onMenuToggle}},[_c('i',{staticClass:"pi pi-bars"})]),(_vm.account)?_c('div',{class:['flex', { 'menu-inactive': _vm.staticMenuInactive }]},[_c('div',{staticClass:"col static-menu",staticStyle:{"font-size":"16px"},style:({
        'margin-left': _vm.staticMenuInactive ? '30px' : '150px',
        transition: 'margin-left 0.3s ease',
      })},[_c('ProfileSwitch')],1)]):_vm._e(),_c('ul',{staticClass:"layout-topbar-menu hide-layout-topbar-menu-items flex origin-top"},[(_vm.timer_on)?_c('li',{staticClass:"flex align-items-center pr-2"},[_c('div',{staticClass:"las la-stopwatch pr-1 time-icon"}),_c('div',{staticClass:"p-text-green",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.navigateMyDesk()}}},[_vm._v(" "+_vm._s(_vm.secToHMS(_vm.currentTimer))+" ")])]):_vm._e(),_c('li',{staticClass:"flex align-items-center pl-3 pr-2",on:{"click":_vm.toggle}},[_c('i',{staticClass:"las la-bell i-20 font-light cursor-pointer notification-bell pt-2",on:{"click":function($event){_vm.$store.dispatch('setSliderView', 'NotificationSlider');
          _vm.$store.dispatch('setSelectedObject', null);}}},[(_vm.unreadNotifications > 0)?_c('span',{staticClass:"p-badge p-component p-badge-warning p-badge-no-gutter"},[_vm._v(_vm._s(_vm.unreadNotifications))]):_c('span',{staticClass:"p-badge p-component p-badge-no-gutter p-badge-hidden"})]),_c('Avatar',{staticClass:"cursor-pointer profile-border",staticStyle:{"width":"44px","height":"44px"},style:(!_vm.user?.profile_picture
            ? {
                'background-color': _vm.getColorFromName(_vm.user),
                color: '#ffffff',
              }
            : ''),attrs:{"image":_vm.user?.profile_picture,"label":!_vm.user?.profile_picture ? _vm.getIntials(_vm.user) : null,"shape":"circle"}})],1)]),_c('Menu',{ref:"menu",staticClass:"mt-2 profile-menu",attrs:{"model":_vm.items,"popup":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
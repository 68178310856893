<template>
  <th class="flex header task-header">
    <div class="col-1 header-background-left"></div>
    <div class="col text-start header-background-right">
      <div class="font-normal">
        <span>Workload - next 7 days: </span>
        <span
          :class="getTextClassWorkload(next7daysWorkload) + ' '"
          style="font-weight: 500"
        >
          {{ formatNum(next7daysWorkload) }}
        </span>
        <br />
        <span>Productivity - last 30 days: </span>
        <span
          :class="getTextClassProductivity(last30daysProductivity) + ' '"
          style="font-weight: 500"
        >
          {{ formatNum(last30daysProductivity) }}
        </span>
      </div>
    </div>
  </th>
</template>

<script>
export default {
  name: "TaskScheduleUserTimesheetHeader",
  props: {
    userTasks: {
      type: Array,
      default: () => [],
    },
    workingHours: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    getTextClassWorkload(num) {
      if (num < 50) {
        return "text-active";
      }
      if (num < 90) {
        return "text-warning";
      }
      return "text-pink";
    },
    getTextClassProductivity(num) {
      if (num < 50 || num > 100) {
        return "text-pink";
      }
      if (num < 70) {
        return "text-warning";
      }
      return "text-active";
    },
    formatNum(num) {
      return num.toFixed(2) + "%";
    },
  },
  computed: {
    next7daysPlanned() {
      var newDate = new Date();
      const today = this.$moment(newDate).format("YYYY-MM-DD");
      const next7days = this.$moment(newDate)
        .add(7, "days")
        .format("YYYY-MM-DD");

      return this.userTasks.reduce((total, task) => {
        return (
          total +
          task.resource_plan.reduce((planTotal, plan) => {
            const planDate = this.$moment(new Date(plan.date)).format(
              "YYYY-MM-DD"
            );

            return planDate >= today && planDate < next7days
              ? planTotal + Number(plan.minutes)
              : planTotal;
          }, 0)
        );
      }, 0);
    },
    activeWorkingHours() {
      var activeHours = {};
      Object.keys(this.workingHours).forEach((key) => {
        if (this.workingHours[key]?.on === true) {
          activeHours[key] = this.workingHours[key];
        }
      });
      return activeHours;
    },
    next7daysWorkingHours() {
      return Object.values(this.activeWorkingHours).reduce((total, hours) => {
        return total + this.convertH2M(hours?.hours);
      }, 0);
    },
    next7daysWorkload() {
      const planned = this.next7daysPlanned;
      const workingHours = this.next7daysWorkingHours;
      return workingHours ? (planned / workingHours) * 100 : 0;
    },
    last30daysProductivity() {
      const newDate = new Date();
      const today = this.$moment(newDate).format("YYYY-MM-DD");
      const last30days = this.$moment(newDate)
        .subtract(30, "days")
        .format("YYYY-MM-DD");

      let totalMinutes = 0;
      let totalMinutesWorked = 0;

      this.userTasks.forEach((task) => {
        task.resource_plan.forEach((plan) => {
          const planDate = this.$moment(new Date(plan.date)).format(
            "YYYY-MM-DD"
          );
          if (planDate >= last30days && planDate <= today) {
            totalMinutes += Number(plan.minutes);
            totalMinutesWorked += Number(plan.minutes_worked);
          }
        });
      });

      return totalMinutes ? (totalMinutesWorked / totalMinutes) * 100 : 0;
    },
  },
};
</script>

<style scoped>
.header {
  max-width: 300px;
}
.header-background-left {
  background-color: #f8fafb;
  margin: 4px -10px 4px 12px;
  border-radius: 6px 0 0 6px;
  pointer-events: none;
}
.header-background-right {
  background-color: #f8fafb;
  margin: 4px 12px 4px 0;
  border-radius: 0 6px 6px 0;
  pointer-events: none;
  padding: 4px;
}
</style>

<template>
  <th
    @click="isProgress = !isProgress"
    :class="
      this.index === this.today
        ? 'cursor-pointer border-left-cell'
        : '  cursor-pointer border-left-cell2'
    "
  >
    <div
      class="flex align-items-end col"
      v-if="!isWorkDay"
    >
      <div class="col text-center">
        <img
          class="w-12"
          src="sleep.svg"
        />
      </div>
    </div>
    <template v-else-if="!isTimesheetView">
      <div class="flex align-items-end cell-half border-bottom-cell">
        <div
          v-if="isProgress"
          :style="getProgressStyle(user, index, true)"
          class="col font-light cell-over"
        />
        <div
          v-else
          class="col text-center pb-unset"
        >
          <p style="color: #778ca2">
            {{ formatHourMinute(getOverHours(user, index)) }}
          </p>
        </div>
      </div>
      <div class="flex align-items-end cell-half">
        <div
          v-if="isProgress"
          :style="getProgressStyle(user, index)"
          class="col font-light cell-total"
        />
        <div
          v-else
          class="col text-center pt-unset"
        >
          <p style="color: #778ca2; font-size: small">
            {{
              formatHourMinute(
                getTotalTime(user, index) - getOverHours(user, index)
              )
            }}
          </p>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="flex align-items-end cell-half border-bottom-cell">
        <div
          v-if="!isProgress"
          class="col text-center"
          style="padding-bottom: 4px"
        >
          <span style="color: #4d7cfe; font-size: small">
            {{ formatHourMinute(getCapturedTime(user, index)) }}
          </span>
        </div>
      </div>
      <div class="flex align-items-end cell-half">
        <template v-if="isProgress">
          <div
            :style="getProgressStyleTimesheetPlanned(user, index)"
            class="col font-light cell-total"
          />
          <div
            v-if="index < this.today"
            :style="getProgressStyleTimesheetCaptured(user, index)"
            class="col font-light cell-total"
          />
        </template>
        <div
          v-else
          class="col text-center"
          style="padding-bottom: 3px"
        >
          <span style="color: #b9c5d0; font-size: small">
            {{ formatHourMinute(getTotalTime(user, index)) }}
          </span>
        </div>
      </div>
    </template>
  </th>
</template>

<script>
export default {
  props: {
    today: {
      type: String,
    },
    user: {
      type: Object,
    },
    index: {
      type: String,
    },
    dayAbbr: {
      type: String,
    },
    isTimesheetView: {
      type: Boolean,
    },
  },
  data() {
    return {
      blockHeight: 0,
      blockStyle: null,
      isProgress: true,
    };
  },
  computed: {
    isWorkDay() {
      return this.user.workingDays?.[this.dayAbbr];
    },
    userHours() {
      return this.user.workingHoursByDay[this.dayAbbr].minutes;
    },
  },
  mounted() {
    this.blockHeight = document.getElementById("userRow").offsetHeight;
    this.isReady = true;
  },
  methods: {
    getBlockStyle() {},
    getTotalTime(user, index) {
      return user.taskData[index]?.total || 0;
    },
    getCapturedTime(user, index) {
      return user.taskData[index]?.captured || 0;
    },
    getOverHours(user, index) {
      return user.overHours[index] || 0;
    },
    getProgressStyle(user, index, isOver) {
      var style = "";
      if (index < this.today) {
        style += !isOver ? "background: #98A9BC;" : "background: #778CA2;";
      } else {
        if (this.isTimesheetView) {
          return;
        }
      }

      var t = isOver
        ? this.getOverHours(user, index)
        : this.getTotalTime(user, index);

      if (t <= 0) {
        return;
      }

      if (t >= this.userHours) {
        if (t === this.userHours) {
          style +=
            "border-top-left-radius: 0.2rem; border-top-right-radius: 0.2rem;";
        }
        style += "height: 100%;";
        return style;
      }

      var s = (t / this.userHours) * 100;
      var px = ((this.blockHeight / 2 / 100) * s).toFixed(2);

      style += "height: " + px + "px;";
      if (!isOver) {
        style +=
          "border-top-left-radius: 0.2rem; border-top-right-radius: 0.2rem;";
      }

      return style;
    },
    formatHourMinute(t) {
      if (t == 0) {
        return "";
      }
      if (t < 60) {
        return parseInt(t).toFixed(0) + "m";
      } else {
        var hours = parseInt(t / 60).toFixed(0);
        var minutes = t % 60;
        if (minutes == 0) {
          minutes = "00";
        }
        return hours + ":" + minutes;
      }
    },
    getProgressStyleTimesheetPlanned(user, index) {
      var style =
        index < this.today
          ? "background: #B9C5D0;border-radius: 5px;margin:0px;margin-left:9px;margin-right:-4px;"
          : "background: #B9C5D0;border-radius: 5px;margin:0px;margin-left:13px;margin-right:13px;";

      var t = this.getTotalTime(user, index);

      if (t <= 0) {
        return;
      }

      var s = (t / this.userHours) * 100;
      var px = ((this.blockHeight / 2 / 100) * s - 2).toFixed(2);
      if (px >= this.blockHeight - 3) {
        px = this.blockHeight - 3;
      }
      style += "height: " + px + "px;";
      return style;
    },
    getProgressStyleTimesheetCaptured(user, index) {
      var style =
        "background: #4D7CFE;border-radius: 5px;margin:0px;margin-right:9px;margin-left:-4px;";

      var t = this.getCapturedTime(user, index);

      if (t <= 0) {
        return;
      }

      var s = (t / this.userHours) * 100;
      var px = ((this.blockHeight / 2 / 100) * s - 2).toFixed(2);
      if (px >= this.blockHeight - 3) {
        px = this.blockHeight - 3;
      }
      style += "height: " + px + "px;";
      return style;
    },
  },
};
</script>

<style>
.cell-total {
  background: #4d7cfe;
  margin-right: 0.3rem;
  margin-left: 0.3rem;
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  padding: unset !important;
}

.cell-over {
  background: #fe4d97;
  margin-right: 0.3rem;
  margin-left: 0.3rem;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  padding: unset !important;
}

.cell-half {
  height: 25.5px;
}

.border-bottom-cell {
  border-bottom-color: rgb(232, 236, 239);
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.border-left-cell {
  border-left-style: solid;
  border-left-color: #4d7cfe;
  border-left-width: 0.15rem;
}

.border-left-cell2 {
  border-left-color: rgb(232, 236, 239);
  border-left-style: solid;
  border-left-width: 1px;
}
</style>
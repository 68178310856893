<template>
  <div
    class="flex flex-column align-items-center justify-content-center"
    style="height: 100vh"
  >
    <div class="flex justify-content-center">
      <div class="w-30rem">
        <h1 class="mb-2" style="font-size: 32px">Welcome to AgencyDesk</h1>
        <div style="margin-bottom: 2rem">
          Get started. No credit card needed.
        </div>

        <form @submit="submit" @keyup.enter="submit">
          <div class="field">
            <label for="name" class="label">Full name</label>
            <InputText
              id="name"
              name="name"
              v-model="name"
              class="w-full"
              placeholder="Full name"
              style="padding: 1rem"
            />
          </div>

          <div class="field">
            <label for="password" class="label">Password</label>
            <InputText
              id="password1"
              type="password"
              v-model="password"
              placeholder="Enter at least 8 characters"
              class="w-full mb-2"
              style="padding: 1rem"
            ></InputText>
          </div>
          <div class="field">
            <label for="account" class="label">Account Name</label>
            <InputText
              id="account"
              name="account"
              v-model="account"
              class="w-full"
              placeholder="Account Name"
              style="padding: 1rem"
            />
          </div>
          <div style="margin-top: 2rem">
            <Button
              type="submit"
              class="button is-primary"
              label="Next"
              style="width: 125px"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: "",
      password: "",
      account: "",
    };
  },
  metaInfo: {
    title: "Welcome",
  },
  methods: {
    submit() {
      this.$router.push("/onboard/industry");
    },
  },
};
</script>

<style>
</style>
<template>
  <Dialog
    :visible.sync="isVisible"
    :modal="true"
    :closable="true"
    dismissableMask
    :contentStyle="{
      borderBottomLeftRadius: '6px',
      borderBottomRightRadius: '6px',
    }"
    @hide="clear()"
    :style="{
      maxWidth: '650px',
      maxHeight: '50%',
    }"
  >
    <template #header>
      <div
        class="flex col-12 justify-content-between pl-3 pt-2 pr-3 border-round"
        style="background: #f8f9fa"
      >
        <div class="p-2 flex align-items-center">Manage team member/s</div>
        <div class="flex">
          <div class="p-input-icon-right">
            <i class="pi pi-times cursor-pointer" @click="searchQuery = null" />

            <InputText
              ref="search"
              clearable
              v-model="searchQuery"
              placeholder="Search..."
              class="w-full"
            />
          </div>

          <i
            class="flex align-items-center la la-times cursor-pointer pl-3"
            @click="clearPopupDialog()"
          ></i>
        </div>
      </div>
    </template>
    <component
      :active="active"
      :searchQuery="searchQuery"
      v-if="componentName !== ''"
      :is="componentFile"
    />
  </Dialog>
</template>

<script>
export default {
  name: "Popup",
  props: {
    componentName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isVisible: false,
      active: true,
      searchQuery: null,
    };
  },
  computed: {
    componentFile() {
      if (this.componentName == "") {
        return "";
      }
      return () => import("../components/" + this.componentName);
    },
  },
  mounted() {
    if (this.componentFile) {
      this.isVisible = true;
    }
  },

  watch: {
    componentName(n) {
      this.isVisible = n != "";
    },
    isVisible(n) {
      if (!n) {
        this.searchQuery = null;
      }
    },
  },
  methods: {
    logStepComponentData() {
      this.componentFile().then((a) => {});
    },
    clear() {
      this.clearPopupDialog();
    },
  },
};
</script>


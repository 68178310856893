<template>
  <div class="flex">
    <div class="col-6 p-0">
      <Information></Information>
    </div>
    <div class="col-6 p-0">
      <InformationLanding></InformationLanding>
    </div>
  </div>
</template>

<script>
import Information from "../components/InformationForm.vue";
import InformationLanding from "../components/InformationLanding.vue";
export default {
  components: { Information, InformationLanding },
};
</script>
<template>
  <div class="flex">
    <div class="col-6 p-0">
      <Team></Team>
    </div>
    <div class="col-6 p-0">
      <TeamLanding></TeamLanding>
    </div>
  </div>
</template>

<script>
import Team from "../components/TeamForm.vue";
import TeamLanding from "../components/TeamLanding.vue";
export default {
  components: { Team, TeamLanding },
};
</script>
<template>
  <div class="flex" style="overflow-x: scroll">
    <div
      v-for="t in pausedTasks.filter((a) => !!a)"
      :key="t.uid"
      class="flex align-items-center card p-0 card-inverse mr-5 mb-3"
    >
      <div class="flex align-items-center p-3">
        <span class="ml-1 mr-3" style="white-space: nowrap">{{ t.title }}</span>
      </div>
      <div class="flex align-items-center p-3">
        <span class="text-center">{{ secToHMS(t.time_spent) }}</span>
      </div>
      <div class="flex align-items-center p-2">
        <Button
          @click="addToDesk(t.uid)"
          :icon="'las i-20 la-play'"
          class="p-button-rounded p-button-text grey-button-light"
        />
      </div>
      <div id="ellipsis flex align-items-center">
        <i
          class="las flex la-ellipsis-v text-20 pr-3 cursor-pointer"
          @click="toggle($event, t.uid)"
        />
      </div>
    </div>
    <Menu style="width: 150px" ref="menu" :popup="true" :model="items" />
    <SaveTime
      v-if="touchedUid"
      :prop_uid="touchedUid"
      :prop_name="getTaskName(touchedUid)"
      :prop_time="getSpentTaskTime(touchedUid)"
      :display="display"
      @dismiss="display = false"
    />
  </div>
</template>

<script>
import SaveTime from "../components/SaveTime.vue";
import { state, cancelTime } from "../../../services/data_service.js";
export default {
  props: {
    isLoading: {
      type: Boolean,
    },
  },
  components: {
    SaveTime,
  },
  data() {
    return {
      display: false,
      touchedUid: null,
      items: [
        {
          label: "SAVE TIME",
          command: () => {
            this.display = true;
          },
        },
        {
          label: "CANCEL",
          command: () => {
            this.cancelPrompt(this.touchedUid);
          },
        },
      ],
    };
  },
  computed: {
    pausedTasks() {
      return state.pausedTasks;
    },
    myTasks() {
      return state.myTasks;
    },
    isPaused() {
      return state.isPaused;
    },
  },
  methods: {
    toggle(event, uid) {
      this.touchedUid = uid;
      this.$refs.menu.toggle(event);
    },
    cancelPrompt(uid) {
      this.$confirm.require({
        message: "Are you sure you want to cancel this time?",
        header: "Confirm Cancel",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          state.isPaused = !state.isPaused;
          this.cancelTime(uid);
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    async cancelTime(uid) {
      var respsonse = await cancelTime(uid);
      this.$store.dispatch("setTimer", respsonse);
    },
    getTaskName(t) {
      var task = this.pausedTasks.find((a) => a && a.uid === t);

      if (task) {
        return task.title;
      }
      return null;
    },
    getTaskTime(t) {
      var task = this.getTask(t);
      return this.calcPausedTime(task);
    },
    getSpentTaskTime(t) {
      var task = this.getTask(t);
      return this.calcPausedTime(task);
    },
    getTask(t) {
      for (var arr in this.myTasks) {
        var i = this.myTasks[arr].find((a) => a.uid === t);

        if (i) {
          return i;
        }
      }
    },
    calcPausedTime(task) {
      if (!task) return;
      var t = 0;
      this.pausedTasks.forEach((a) => {
        if (a && a.uid === task.uid) {
          t = t + parseInt(a.time_spent);
        }
      });
      return this.secToHMS(t);
    },
    addToDesk(uid) {
      this.$axios
        .get(
          process.env.VUE_APP_ROOT_API + "/v1/tasks/" + uid + "/timer/toggle",
          {}
        )
        .then((response) => {
          this.$bus.emit("refreshData");
          this.$store.dispatch("setTimer", response.data.data);
          this.isLoading = false;
          this.users = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style>
</style>
<template>
  <div
    class="flex align-items-center text-center justify-content-center bg-black"
    style="height: 100vh"
  >
    <div class="flex justify-content-center text-center text-white">
      <div class="w-30rem">
        <div class="mb-3" style="margin-top: 4rem">
          <img
            alt="Logo"
            src="../../../assets/logo_new.svg"
            style="width: 250px"
          />
        </div>
        <div>Plan • Schedule • Work • Profit</div>
        <div
          style="margin-top: 10rem"
          class="flex flex-wrap justify-content-center"
        >
          <div class="col-2">
            <img alt="Logo" src="../../../assets/icons/video_play.svg" />
          </div>
          <div class="col-2">
            <img alt="Logo" src="../../../assets/icons/document_history.svg" />
          </div>
          <div class="col-2">
            <img alt="Logo" src="../../../assets/icons/structure.svg" />
          </div>
          <div class="col-2">
            <img alt="Logo" src="../../../assets/icons/document_process.svg" />
          </div>
          <div class="col-2">
            <img alt="Logo" src="../../../assets/icons/circle_check.svg" />
          </div>
          <div class="col-2">
            <img alt="Logo" src="../../../assets/icons/workspace.svg" />
          </div>
          <div class="col-2">
            <img alt="Logo" src="../../../assets/icons/kanban.svg" />
          </div>
          <div class="col-2">
            <img alt="Logo" src="../../../assets/icons/workspace.svg" />
          </div>
          <div class="col-2">
            <img alt="Logo" src="../../../assets/icons/use_case.svg" />
          </div>
          <div class="col-2">
            <img alt="Logo" src="../../../assets/icons/mail_send.svg" />
          </div>
          <div class="col-2">
            <img alt="Logo" src="../../../assets/icons/history_setting.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style>
</style>
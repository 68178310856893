<template>
  <form
    style="max-width: 90vw; min-width: 90vw"
    @submit.prevent="submitHandler"
    v-form-enter
  >
    <div>
      <DataTable
        responsiveLayout="scroll"
        :rowClass="rowClass"
        :value="filteredCosts"
        filterDisplay="menu"
        editMode="row"
        dataKey="uid"
      >
        <template #empty>Nothing yet...</template>
        <Column
          field="description"
          :styles="{
            width: '60%',
            verticalAlign: 'top !important',
          }"
          :footerStyle="{
            backgroundColor: 'white',
          }"
          ><template #body="slotProps">
            <Skeleton v-if="isLoading"></Skeleton>
            <div v-else>
              <div class="p-1 text-link">
                <span class="cursor-pointer"
                  >{{ slotProps.data.type_name }}
                </span>
              </div>

              <div v-if="slotProps.data" class="p-1 flex flex-wrap font-light">
                <div v-html="slotProps.data.description"></div>
              </div>
            </div>
          </template>
        </Column>
        <Column
          field="option"
          :styles="{
            width: '1%',
            whiteSpace: 'nowrap',
            verticalAlign: 'top !important',
          }"
          :footerStyle="{
            backgroundColor: 'white',
          }"
        >
          <template #body="slotProps">
            <div
              class="flex flex-column"
              v-if="slotProps.data && slotProps.data.options && !isLoading"
            >
              <div
                class="col pr-0 ce-co"
                v-for="(o, index) in slotProps.data.options"
                :key="'q' + o.uid"
              >
                <span v-if="index > 0">Option</span>
                <span v-else style="width: 100%; display: inline-block"> </span>
              </div>
            </div>
            <div v-else-if="slotProps.data" class="col pr-0"></div>
          </template>
        </Column>
        <Column
          header="Qty"
          field="quantity"
          :styles="{
            width: '6%',
            verticalAlign: 'top !important',
            whiteSpace: 'nowrap',
          }"
          :footerStyle="{
            backgroundColor: 'white',
          }"
        >
          <template #body="slotProps">
            <Skeleton v-if="isLoading"></Skeleton>
            <div
              class="flex flex-column"
              v-else-if="slotProps.data && slotProps.data.options"
            >
              <div
                class="col pr-0 ce-co"
                v-for="o in slotProps.data.options"
                :key="'q' + o.uid"
              >
                {{ formatAmount(o.quantity ? o.quantity : o.units) }}
              </div>
            </div>
            <div v-else-if="slotProps.data.item" class="col pr-0">
              {{ formatAmount(slotProps.data.quantity) }}
            </div>
          </template>
        </Column>
        <Column
          header="Price/Unit"
          field="price"
          :styles="{
            width: '10%',
            verticalAlign: 'top !important',
            whiteSpace: 'nowrap',
          }"
          headerClass="header-right"
          :footerStyle="{
            backgroundColor: 'white',
          }"
        >
          <template #body="slotProps">
            <Skeleton v-if="isLoading"></Skeleton>
            <div
              class="flex flex-column"
              v-else-if="slotProps.data && slotProps.data.options"
            >
              <div
                class="col text-end pr-0 ce-co"
                v-for="o in slotProps.data.options"
                :key="'u' + o.uid"
              >
                {{
                  formatCurrency(
                    o.price ? o.price : o.resell_amount,
                    setCurrencySymbol,
                    8
                  )
                }}
              </div>
            </div>
            <div v-else-if="slotProps.data" class="col text-end pr-0">
              {{ formatCurrency(slotProps.data.price, setCurrencySymbol, 8) }}
            </div>
          </template>
        </Column>
        <Column
          headerClass="header-right"
          header="Margin"
          :footerStyle="{
            backgroundColor: 'white',
          }"
          :styles="{
            width: '10%',
            flexFlow: 'row-reverse',
            verticalAlign: 'top !important',
          }"
        >
          <template #body="slotProps">
            <Skeleton v-if="isLoading"></Skeleton>
            <div
              class="flex flex-column"
              v-else-if="slotProps.data && slotProps.data.options"
            >
              <div
                class="col text-end pr-0 ce-co"
                v-for="(o, index) in slotProps.data.options"
                :key="'m' + index"
              >
                {{ formatCurrency(getMargin(o), setCurrencySymbol, 8) }}
              </div>
            </div>
            <div v-else class="col text-end pr-3">-</div>
          </template>
        </Column>
        <Column
          headerClass="header-right"
          header="Price"
          field="active"
          :styles="{
            width: '10%',
            verticalAlign: 'top !important',
          }"
          :footerStyle="{
            backgroundColor: 'white',
          }"
        >
          <template #body="slotProps">
            <Skeleton v-if="isLoading"></Skeleton>
            <div
              class="flex flex-column"
              v-else-if="slotProps.data && slotProps.data.options"
            >
              <div
                class="col text-end pr-0 ce-co"
                v-for="(o, index) in slotProps.data.options"
                :key="'p' + index"
              >
                {{ formatCurrency(o.quantity * o.price, setCurrencySymbol, 8) }}
              </div>
            </div>
          </template>
        </Column>
        <Column
          headerClass="header-right"
          :footerStyle="{
            backgroundColor: 'white',
          }"
          :styles="{
            width: '1%',
            flexFlow: 'row-reverse',
            verticalAlign: 'top !important',
          }"
        >
          <template #body="slotProps">
            <Skeleton v-if="isLoading"></Skeleton>
            <div
              class="flex flex-column"
              v-else-if="slotProps.data && slotProps.data.options"
            >
              <div
                class="col text-end pr-0 pt-1"
                v-for="o in slotProps.data.options"
                :key="o.uid"
              >
                <RadioButton
                  :class="'square-radio'"
                  v-model="selectedOptions[slotProps.data.uid]"
                  :value="o"
                  @input="selectOptions()"
                ></RadioButton>
              </div>
            </div>
            <div v-else class="col text-end pr-3">-</div>
          </template>
        </Column>
      </DataTable>
      <div class="formgrid grid col-12 pt-3 justify-content-between">
        <div>
          <Button
            :disabled="
              filteredCosts.length != selectedCostTypes.cost_types.length
            "
            type="submit"
            :label="submitLabel"
          >
          </Button>
        </div>
        <Button class="p-button-text p-button-secondary" @click="dismiss">
          CANCEL
        </Button>
      </div>
    </div>
  </form>
</template>

<script>
import {
  state,
  fetchCostTypeAndOptions,
  fetchJobEstimate,
  fetchJobCosts,
} from "../../../services/data_service.js";
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      selectedOptions: [],
      costTypes: null,
      isLoading: true,
      selectedCostTypes: {
        cost_types: [],
      },
    };
  },
  validations: {},
  computed: {
    submitLabel() {
      if (this.job_estimate) {
        return "Update Job";
      }
      return "Convert to Job";
    },
    jobCosts() {
      return state.jobCosts;
    },
    job_estimate() {
      return state.jobEstimate;
    },
    estimate_clients() {
      return state.estimateClients;
    },
    costTypesAndOptions() {
      return state.costTypesAndOptions.flat();
    },
    filteredCosts() {
      if (this.costTypesAndOptions && this.jobCosts) {
        const filteredCosts = this.costTypesAndOptions.filter((costType) => {
          return !this.jobCosts.some((jobCost) => jobCost.uid === costType.uid);
        });
        return filteredCosts;
      }
      return this.costTypesAndOptions;
    },
    stateEstimate() {
      return state.estimate;
    },
    setCurrencySymbol() {
      let currency = null;
      if (this.stateEstimate) {
        for (let i = 0; i < this.estimate_clients.length; i++) {
          if (this.stateEstimate.client.uid === this.estimate_clients[i].uid) {
            currency = this.estimate_clients[i].currency.description;
          }
        }
        return currency;
      }
      return null;
    },
  },
  // beforeMount() {
  //   state.jobEstimate = null;
  // },
  beforeDestroy() {
    state.jobEstimate = null;
    this.$bus.off("refreshData", this.loadData);
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  async mounted() {
    this.isLoading = true;
    await this.loadData();
    if (this.job_estimate) {
      fetchCostTypeAndOptions(this.job_estimate.uid);
    } else {
      fetchCostTypeAndOptions(this.stateEstimate.uid);
    }
    this.isLoading = false;
  },
  methods: {
    submitHandler() {
      var uid = null;
      if (this.job_estimate && this.job_estimate.uid !== null) {
        uid = this.job_estimate.uid;
      } else {
        uid = this.stateEstimate.uid;
      }
      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API + "/v1/estimate/convert/" + uid,
          JSON.stringify(this.selectedCostTypes),
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          this.displayDialog = false;
          this.dismiss();
          this.$router.push("/job/" + response.data.data);
          this.$toast.add({
            severity: "success",
            summary: "Estimate converted to job",
            life: 3000,
          });
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
        });
    },
    selectOptions() {
      for (const key in this.selectedOptions) {
        const obj = this.selectedOptions[key];
        this.filteredCosts.forEach((costType) => {
          costType.options.forEach((option) => {
            if (obj.uid === option.uid) {
              var options = [option];
              var selectedCostType = Object.assign({}, costType);
              selectedCostType.options = options;
              if (this.selectedCostTypes.cost_types.length) {
                const index = this.selectedCostTypes.cost_types.findIndex(
                  (item) => item.uid === selectedCostType.uid
                );
                if (index !== -1) {
                  this.selectedCostTypes.cost_types.splice(index, 1);
                }
                this.selectedCostTypes.cost_types.push(selectedCostType);
              } else {
                this.selectedCostTypes.cost_types.push(selectedCostType);
              }
            }
          });
        });
      }
    },
    async loadData() {
      if (this.$route.name == "job--tasks") {
        fetchJobEstimate(this.$route.params.uid);
        fetchJobCosts(this.$route.params.uid);
      }
    },
    dismiss() {
      this.$emit("dismiss");
    },
    rowClass() {
      return "row-accessories";
    },
    getMargin(obj) {
      if (obj.type !== "task_type") {
        return (
          parseFloat(obj.price) * obj.quantity -
          parseFloat(obj.unit_price) * obj.quantity
        );
      }
      return "-";
    },
  },
};
</script>

<style>
.square-radio .p-radiobutton-box {
  border: 1px solid #b9c4d0;
}

.square-radio .p-radiobutton-box:after {
  width: 10px;
  height: 10px;
  background-color: #4d7cfe !important;
}
</style>
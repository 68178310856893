var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Dialog',{style:({
    maxWidth: '650px',
    maxHeight: '50%',
  }),attrs:{"visible":_vm.isVisible,"modal":true,"closable":true,"dismissableMask":"","contentStyle":{
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
  }},on:{"update:visible":function($event){_vm.isVisible=$event},"hide":function($event){return _vm.clear()}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"flex col-12 justify-content-between pl-3 pt-2 pr-3 border-round",staticStyle:{"background":"#f8f9fa"}},[_c('div',{staticClass:"p-2 flex align-items-center"},[_vm._v("Manage team member/s")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"p-input-icon-right"},[_c('i',{staticClass:"pi pi-times cursor-pointer",on:{"click":function($event){_vm.searchQuery = null}}}),_c('InputText',{ref:"search",staticClass:"w-full",attrs:{"clearable":"","placeholder":"Search..."},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('i',{staticClass:"flex align-items-center la la-times cursor-pointer pl-3",on:{"click":function($event){return _vm.clearPopupDialog()}}})])])]},proxy:true}])},[(_vm.componentName !== '')?_c(_vm.componentFile,{tag:"component",attrs:{"active":_vm.active,"searchQuery":_vm.searchQuery}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }